import * as React from "react";
import { List, Datagrid, TextField, Link, EditButton, useListContext, useRecordContext } from 'react-admin';
import { Grid, Box, Typography, Card, CardContent, CardMedia, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { LoadingComponent } from '../LoadingComponent';
import { HelpOutline, ExpandCircleDown } from '@mui/icons-material';
import { titoloPagina } from "../generale";

export const FaqDetails = () => {
    const record = useRecordContext();
    return (
        <div>
            <p>{record.risposta}</p>
        </div>
    );
}

export const FaqListAdmin = () => {
    // React.useEffect(() => {
    //     document.title = "F.A.Q. - Domande frequenti";
    // }, []);

    return (
        <List sort={{ field: 'id', order: 'ASC' }} perPage={50} exporter={false} pagination={false}>
            <Datagrid bulkActionButtons={false} rowClick="expand" sortable={false} expand={<FaqDetails />}>
                <TextField source="domanda" sortable={false} />
                <EditButton label="Modifica"></EditButton>
            </Datagrid>
        </List>
    )
};

export const FaqListUser = () => {
    const ListaFaq = () => {
        const { data, isLoading } = useListContext();
        if (isLoading) return <LoadingComponent />;

        return (
            data.map((faq, i) => (
                <Accordion key={i} sx={{ mb: 2 }} variant="faq">
                    <AccordionSummary
                        aria-controls={`panel${i}-content`}
                        expandIcon={<ExpandCircleDown color="secondary" fontSize="large" />}
                        id={`panel${i}-header`}
                    >
                        <HelpOutline color="secondary" fontSize="large" />
                        <Typography component="p" variant="h6" color="secondary" mb={0} px={2} sx={{ lineHeight: 1.3, fontSize: 15, fontWeight: '700' }}>{faq.domanda}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="p" variant="p" px={2}>{faq.risposta}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))
        );
    }

    React.useEffect(() => {
        document.title = titoloPagina;
    }, []);

    return (
        <Grid container>
            <Grid item xs md={4} xl={3}
                sx={{ 
                    display:'flex', 
                    alignItems:'center', 
                    flexDirection: 'column' 
                }}
            >
                <Box sx={{ position: 'sticky', top: 100 }} p={2} display='flex' flexDirection='column' alignItems='center'>
                    <CardMedia
                        component="img"
                        image={require(`../../includes/img/logo.png`)}
                        sx={{ maxWidth: { xs: "130px", md: "200px"} }}
                    />
                    <CardMedia
                        component="img"
                        image={require(`../../includes/img/faq-user.jpg`)}
                        sx={{ maxWidth: { xs: "200px", md: "100%"} }}
                    />
                    <Typography component="h3" variant="titoloSezione" color="secondary" textAlign='center' mt={2}>F.A.Q.<br />Domande frequenti</Typography>
                    <Typography component="p" variant="p" textAlign='center' mt={2}>Le risposte alle domande più comuni riguardanti i nostri prodotti, servizi e politiche.<br />
                    Siamo qui per fornire chiarezza e assistenza, rendendo la vostra esperienza con noi il più semplice e soddisfacente possibile.</Typography>
                </Box>
            </Grid>
            <Grid item md={8} xl={9}>
                <List sort={{ field: 'id', order: 'ASC' }} perPage={50} exporter={false} pagination={false} sx={{ mt: { sm: "-50px" }, mb: 3 }} component="div">
                    <ListaFaq />
                </List>
            </Grid>
        </Grid>
    )
};