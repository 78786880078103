import { useEffect, useState, memo, useMemo } from 'react';
import { List, Form, Datagrid, TextField, ReferenceField, Confirm, EditButton, FunctionField, usePermissions, useRecordContext, Link, useGetList, useGetOne, useGetMany, useDeleteMany, TextInput, ReferenceInput, AutocompleteInput, BooleanInput, NullableBooleanInput, useUpdateMany, SelectInput, Button, useListContext, useRefresh, useNotify, useUnselectAll, useCreate, useDataProvider, useReference, WithRecord, ShowButton, RecordContext, useStore, dataProvider } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Tooltip, Dialog, DialogContent, Grid, Popover, AlertTitle, useMediaQuery, FormControlLabel, Checkbox, TextField as TextFieldMui, Button as ButtonMui } from '@mui/material';
import { NotificationsActive, TextSnippet } from '@mui/icons-material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import { logger, valutaFormat, StatiRichiesta, admB64, HeaderSezione, listaFermate, myTheme } from '../generale';
import { calcoloPreventivo } from './Preventivo';
import { AlertModificheRichiesta } from './AlertModificheRichiesta';
import { MessaggioFinale, IconaMessaggioFinale } from './MessaggioFinale';
import { TastoGeneraPdf } from './RichiestaPdf';
import { ViaggioPreview, RiepilogoPartecipanti } from '../Viaggi/ViaggioPreview';
import IconaStatoViaggio from "../IconaStatoViaggio";
import { IconaStatoRichiesta } from "../IconaStatoRichiesta";
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { encode as base64_encode } from 'base-64';

export const RichiestaDetails = ({preventivo: p}) => {
    const record = useRecordContext();
    // console.log(record);
    const { permissions } = usePermissions();
    const { referenceRecord: viaggio, isLoadingViaggio, errorViaggio } = useReference({
        reference: 'viaggio',
        id: record.id_viaggio,
        // trovare il modo per filtrare solo la colonna 'suppl_singola' perché le altre in questo caso non servono. al momento il codice ritorna tutti i valori di 'viaggio'
        meta:{
            include:'suppl_singola'
        }
    });

    if (isLoadingViaggio || errorViaggio || viaggio === undefined) return null;
    // console.log("viaggio", viaggio);
    // console.log(viaggio.suppl_singola ?? 0);
    const obj = {
        prezzo: record.prezzo,
        suppl_singola: viaggio.suppl_singola ?? 0,
        n_singole: record.cam_singola ?? 0,
        acconto: 20,
        sconto: {
            perc:record.sconto_perc ?? 0,
            amm:record.sconto_amm ?? 0
        },
        partecipanti: {
            adulti: record.n_adulti ?? 0,
            bambini_1: record.n_bambini_1 ?? 0,
            bambini_2: record.n_bambini_2 ?? 0,
            bambini_3: record.n_bambini_3 ?? 0,
            bambini_4: record.n_bambini_4 ?? 0
        },
        totPartecipanti: (record.n_adulti + record.n_bambini_3 + record.n_bambini_4)
    };
    const preventivo = calcoloPreventivo(obj, null, p.configSconti);

    // console.log(record);
    // console.log(obj);
    // console.log(preventivo);
    if (!record || !viaggio) return <LoadingComponent />;
    return (
        <Grid container spacing={2} sx={{ py: 4 }}>
            {record.note_pub ?
            <Grid item xs={6} md={12} order={{ xs: 12, sm: 1 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h3" variant="p">Note</Typography>
                        {record.note_pub}
                    </CardContent>
                </Card>
            </Grid>
            : null}
            <Grid item xs={6} sm={6} md={4} xl order={{ xs: 1, sm: 2 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h3" variant="p">Riepilogo</Typography>
                        {/* <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                            <ReferenceField source="id_stato_viaggio" reference="stato_viaggio">
                                <FunctionField render={record => `Viaggio: ${record.nome}`} />
                            </ReferenceField>
                        </ReferenceField>
                        <br />
                        <ReferenceField source="id_stato_richiesta" reference="stato_richiesta" link={false}>
                            <FunctionField render={record => `Richiesta: ${record.nome}`} />
                        </ReferenceField>
                        <br /> */}
                        <ReferenceField source="id_stato_dati" reference="stato_dati" link={false}>
                            <FunctionField render={record => `Dati: ${(record.nome || 'N.D.')}`} />
                        </ReferenceField>
                        <br />
                        <ReferenceField source="id_stato_pagamenti" reference="stato_pagamenti" link={false}>
                            <FunctionField render={record => `Pagamenti: ${record.nome}`} />
                        </ReferenceField>
                        <hr />
                        Prezzo:
                        <span style={{ marginLeft: '5px' }}>{valutaFormat.format(preventivo.totFinale)}{(preventivo.totSconto > 0 ? <><s style={{ marginLeft: '10px' }}>{valutaFormat.format(preventivo.totParziale)}</s> (- {(preventivo.percSconto > 0 ? `${preventivo.percSconto}%` : valutaFormat.format(preventivo.totSconto))})</> : null)}</span>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} sm={6} md={4} xl order={{ xs: 2, sm: 3 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h3" variant="p">Partecipanti</Typography>
                        {record.n_adulti ? <div>{`Adulti: ${record.n_adulti}`}</div> : null}
                        {record.n_bambini_1 ? <div>{`Bambini 0-2: ${record.n_bambini_1}`}</div> : null}
                        {record.n_bambini_3 ? <div>{`Bambini 3-13: ${record.n_bambini_3}`}</div> : null}
                        {record.n_bambini_4 ? <div>{`Bambini 14-17: ${record.n_bambini_4}`}</div> : null}
                        {record.n_bambini_2 ? <div>{`Di cui sotto 1 mt: ${record.n_bambini_2}`}</div> : null}
                    </CardContent>
                </Card>
            </Grid>
            {record.cam_singola || record.cam_doppia || record.cam_matrimoniale || record.cam_tripla || record.cam_quadrupla ?
            <Grid item xs={6} sm={6} md={4} xl order={{ xs: 3, sm: 4 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h3" variant="p">Camere</Typography>
                        {record.cam_singola ? <div>{`${parseInt(record.cam_singola) === 1 ? 'Singola' : 'Singole'}: ${record.cam_singola}`}</div> : null}
                        {record.cam_doppia ? <div>{`${parseInt(record.cam_doppia) === 1 ? 'Doppia' : 'Doppie'}: ${record.cam_doppia}`}</div> : null}
                        {record.cam_matrimoniale ? <div>{`${parseInt(record.cam_matrimoniale) === 1 ? 'Matrimoniale' : 'Matrimoniali'}: ${record.cam_matrimoniale}`}</div> : null}
                        {record.cam_tripla ? <div>{`${parseInt(record.cam_tripla) === 1 ? 'Tripla' : 'Triple'}: ${record.cam_tripla}`}</div> : null}
                        {record.cam_quadrupla ? <div>{`${parseInt(record.cam_quadrupla) === 1 ? 'Quadrupla' : 'Quadruple'}: ${record.cam_quadrupla}`}</div> : null}
                    </CardContent>
                </Card>
            </Grid>
            : null}
            {record.pagante_nominativo || record.pagante_cf || record.pagante_comune ?
            <Grid item xs={6} sm={6} order={{ xs: 4, sm: 5 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h3" variant="p">Pagante</Typography>
                        <div>Nominativo: {record.pagante_nominativo ? record.pagante_nominativo : 'N.D.'}</div>
                        <div>Cod. Fisc: {record.pagante_cf ? record.pagante_cf : 'N.D.'}</div>
                        <div>Comune residenza: {record.pagante_comune ? record.pagante_comune : 'N.D.'}</div>
                    </CardContent>
                </Card>
            </Grid>
            : null}
        </Grid>
    );
}

const AlertNotePvt = () => {
    const record = useRecordContext();
    const [popOpen, setPopOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    function handleOpen(event) {
        setAnchorEl(event.currentTarget);
        setPopOpen(true);
    }
    const stilePopover = {
        "& .MuiPaper-root": {
            maxWidth: "500px !important",
            width: "80vw !important"
        },
        "& .MuiBackdrop-root": {
            opacity: "0.5 !important"
        }
    };
    return (
        <>
            <TextSnippet color='primary' fontSize='10px' sx={{ ml: 1, cursor: 'pointer' }} onClick={handleOpen} />
            <Popover
                id={`popoverNotePvt_${record.id}`}
                open={popOpen}
                onClose={() => setPopOpen(false)}
                anchorEl={anchorEl}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={stilePopover}
            >
                <Box p={2}>
                    <Typography variant='h3' pb={2}>Note private</Typography>
                    <Typography>{record.note_pvt}</Typography>
                </Box>
            </Popover>
        </>
    )
}

const RichiesteBulkActionButtons = ({ idViaggio }) => {
    const idUtente = localStorage.getItem('auth_uid');
    const { selectedIds, data: dettagli, isLoadingSelected } = useListContext();
    // const [verificaInCorso, setVerificaInCorso] = useState(false);
    const dataProvider = useDataProvider();
    const [nuovoStato, setNuovoStato] = useState(null);
    const [apriConfermaCreazione, setApriConfermaCreazione] = useState(false);
    const [confermaCreazione, setConfermaCreazione] = useState(false);
    const [mostraModalMessaggioBase, setMostraModalMessaggioBase] = useState(false);
    const [rispostaAggiornamento, setRispostaAggiornamento] = useState({ daFare: 0, ok: 0, ko: 0 });
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('richiesta');
    const [create, { isLoading: isLoadingCreate, error }] = useCreate();
    const [updateMany, { isLoadingUpdateMany, errorUpdateMany }] = useUpdateMany();
    const [deleteMany, { isLoadingDelete, errorDelete }] = useDeleteMany();
    // console.log(dettagli);

    const disabilitaPartecipanti = (v) => {
        return new Promise(function (resolve, reject) {
            const idDaDisabilitare = v.map((i) => { return i.id });
            const payload = idDaDisabilitare.map(() => { return { alloggio: null, stato: 0 } });
            updateMany('richiesta_dettagli', { ids: idDaDisabilitare, data: payload })
            .then(() => {
                resolve();
            })
            .catch(error => {
                reject(error);
            });
        })
    }

    const disabilitaCamere = (v) => {
        return new Promise(function (resolve, reject) {
            const idDaDisabilitare = v.map((i) => { return i.id });
            const payload = idDaDisabilitare.map(() => { return { stato: 0 } });
            updateMany('richiesta_alloggi', { ids: idDaDisabilitare, data: payload })
            .then(() => {
                resolve();
            })
            .catch(error => {
                reject(error);
            });
        })
    }

    const creaPartecipanti = (v) => {
        // console.log(v);
        return new Promise(function (resolve, reject) {
            Object.keys(v).map((key) => {
                // console.log(key, v[key]);
                if (key !== 'id' && key !== 'id_utente') {
                    for (let i = 0; i < v[key]; i++) {
                        create(
                            'richiesta_dettagli', {
                                data: {
                                    id_richiesta: v.id, 
                                    tipo: key.replace("n_", ""), 
                                    stato: 1,
                                    id_utente: v.id_utente,
                                }
                            }
                        )
                    }
                }
                return notify('Operazione completata', { type: 'success' });
            });
        })
    }

    const creaCamere = (v) => {
        return new Promise(function (resolve, reject) {
            Object.keys(v).map((key) => {
                // console.log(v, key, v[key]);
                if (key !== 'id') {
                    for (let i = 1; i <= v[key]; i++) {
                        let codCamera = (key.replace("cam_", "")).substring(0,3);
                        let prefissoCamera, moltiplicatore;
                        switch (codCamera) {
                            case 'sin':
                                prefissoCamera = '01_';
                                moltiplicatore = 1;
                                break;
                            case 'dop':
                                prefissoCamera = '02_';
                                moltiplicatore = 2;
                                break;
                            case 'mat':
                                prefissoCamera = '03_';
                                moltiplicatore = 2;
                                break;
                            case 'tri':
                                prefissoCamera = '04_';
                                moltiplicatore = 3;
                                break;
                            case 'qua':
                                prefissoCamera = '05_';
                                moltiplicatore = 4;
                                break;
                            default:
                                break;
                        }
                        codCamera = prefissoCamera + codCamera;
                        for (let k = 1; k <= moltiplicatore; k++) {
                            // console.log(codCamera + "_" + i);
                            create(
                                'richiesta_alloggi', {
                                    data: {
                                        id_richiesta: v.id, 
                                        alloggio: codCamera + "_" + i, 
                                        stato: 1,
                                        id_utente_richiesta: dettagli.filter(d => d.id === v.id)[0].id_utente
                                    }
                                }
                            )
                        }
                    }
                }
                return notify('Operazione completata', { type: 'success' });
            });
        })
    }

    useEffect(() => {
        // console.log(rispostaAggiornamento);
        if (rispostaAggiornamento.ko > 0) {
            notify('Errore nell\'aggiornamento delle richieste', { type: 'error' });
        } else if (rispostaAggiornamento.ok > 0) {
            notify('Richieste modificate', { type: 'success' });
            // refresh();
            // unselectAll();
        }
    }, [rispostaAggiornamento]);

    function verificaPartecipanti (dati) {
        // console.log('VERIFICA', dati);
        return new Promise(function (resolve, reject) {
            dati.map((v) => {
                // console.log(v);
                dataProvider.getList(
                    'richiesta_dettagli',
                    {
                        filter: { id_richiesta: v.id, stato: 1 },
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'id', order: 'ASC' }
                    }
                )
                .then(({ total: totPartecipantiCreati, data: partecipantiCreati }) => {
                    // console.log(`ID richiesta: ${v.id}, daCreare: ${(v.n_adulti + v.n_bambini_1 + v.n_bambini_3 + v.n_bambini_4)}, totPartecipantiCreati: ${totPartecipantiCreati}`);
                    // console.log(v.id, totPartecipantiCreati);
                    if (totPartecipantiCreati === null || totPartecipantiCreati === 0) {
                        rispostaAggiornamento.daFare++;
                        creaPartecipanti(v).then(resolve());
                    } else {
                        const obj = {
                            id: partecipantiCreati[0].id_richiesta,
                            n_adulti: 0,
                            n_bambini_1: 0,
                            n_bambini_3: 0,
                            n_bambini_4: 0,
                        }
                        partecipantiCreati.map((r) => {
                            switch (r.tipo) {
                                case 'adulti':
                                    obj.n_adulti += 1;
                                    break;
                                case 'bambini_1':
                                    obj.n_bambini_1 += 1;
                                    break;
                                case 'bambini_3':
                                    obj.n_bambini_3 += 1;
                                    break;
                                case 'bambini_4':
                                    obj.n_bambini_4 += 1;
                                    break;
                                default:
                                    break;
                            }
                            return null;
                        })
                        // console.log(obj);
                        if (JSON.stringify(v) !== JSON.stringify(obj)) {
                            // La composizione dei partecipanti non rispecchia quanto salvato in "richiesta_dettagli"
                            disabilitaPartecipanti(partecipantiCreati).then(
                                creaPartecipanti(v).then(
                                    resolve()
                                ).catch(error => {
                                    reject(error);
                                })
                            ).catch(error => {
                                reject(error);
                            });
                            // console.log(`${v.id} NON è uguale`);
                        } else {
                            // La composizione dei partecipanti è identica a quanto presente in "richiesta_dettagli"
                            // console.log(`${v.id} è uguale`);
                        }
                    }
                    // unselectAll();
                })
                .catch(error => {
                    reject(error);
                });
                return null;
            });
        })
    }

    function verificaCamere (dati) {
        // console.log('VERIFICA', dati);
        return new Promise(function (resolve, reject) {
            dati.map((v) => {
                // console.log(v);
                dataProvider.getList(
                    'richiesta_alloggi',
                    {
                        filter: { id_richiesta: v.id, stato_neq: 0 },
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'id', order: 'ASC' }
                    }
                )
                .then(({ total: totAlloggiCreati, data: alloggiCreati }) => {
                    // console.log(`ID richiesta: ${v.id}, daCreare: ${(v.n_adulti + v.n_bambini_1 + v.n_bambini_3 + v.n_bambini_4)}, totAlloggiCreati: ${totAlloggiCreati}`);
                    // console.log(v.id, totAlloggiCreati);
                    if (totAlloggiCreati === null || totAlloggiCreati === 0) {
                        rispostaAggiornamento.daFare++;
                        creaCamere(v).then(resolve());
                    } else {
                        const alloggiUniciCreati = [...new Set(alloggiCreati.map((v) => {return v.alloggio}))];
                        // console.log(alloggiUniciCreati);
                        const obj = {
                            id: v.id,
                            cam_singola: 0,
                            cam_doppia: 0,
                            cam_matrimoniale: 0,
                            cam_tripla: 0,
                            cam_quadrupla: 0,
                        }
                        alloggiUniciCreati.map((r) => {
                            switch (r.substring(3,6)) {
                                case 'sin':
                                    obj.cam_singola++;
                                    break;
                                case 'dop':
                                    obj.cam_doppia++;
                                    break;
                                case 'mat':
                                    obj.cam_matrimoniale++;
                                    break;
                                case 'tri':
                                    obj.cam_tripla++;
                                    break;
                                case 'qua':
                                    obj.cam_quadrupla++;
                                    break;
                                default:
                                    break;
                            }
                            return null;
                        })
                        // console.log(obj);
                        if (JSON.stringify(v) !== JSON.stringify(obj)) {
                            // La composizione delle camere non rispecchia quanto salvato in "richiesta_dettagli"
                            disabilitaCamere(alloggiCreati).then(
                                creaCamere(v).then(
                                    resolve()
                                ).catch(error => {
                                    reject(error);
                                })
                            ).catch(error => {
                                reject(error);
                            });
                            // console.log(`${v.id} NON è uguale`);
                        } else {
                            // La composizione delle camere è identica a quanto presente in "richiesta_dettagli"
                            // console.log(`${v.id} è uguale`);
                        }
                    }
                    // unselectAll();
                })
                .catch(error => {
                    reject(error);
                });
                return null;
            });
        })
    }

    const [aggiornamentoStato, { isLoading }] = useUpdateMany(
        'richiesta', {
        ids: selectedIds,
        data: selectedIds.map(() => {
            const tmp = {};
            tmp.id_stato_richiesta = nuovoStato;
            return tmp;
        })
        }, {
        onSuccess: () => {
            dettagli.map((v) => {
                if (selectedIds.some(e => { return e === v.id })) {
                    logger(11, `Nuovo stato: ${nuovoStato}`, null, v.id_viaggio, v.id, idUtente);
                }
                return null;
            });
            if (nuovoStato === 2) {
                const arrP = [], arrC = [];
                dettagli.map((v) => {
                    if (selectedIds.some(e => { return e === v.id })) {
                        // console.log(v);
                        // logger(11, `Nuovo stato: ${nuovoStato}`, null, v.id_viaggio, v.id, idUtente);
                        arrP.push({
                            id: parseInt(v.id),
                            id_utente: parseInt(v.id_utente),
                            n_adulti: v.n_adulti ?? 0,
                            n_bambini_1: v.n_bambini_1 ?? 0,
                            n_bambini_3: v.n_bambini_3 ?? 0,
                            n_bambini_4: v.n_bambini_4 ?? 0,
                        });
                        arrC.push({
                            id: parseInt(v.id),
                            cam_singola: v.cam_singola ?? 0,
                            cam_doppia: v.cam_doppia ?? 0,
                            cam_matrimoniale: v.cam_matrimoniale ?? 0,
                            cam_tripla: v.cam_tripla ?? 0,
                            cam_quadrupla: v.cam_quadrupla ?? 0,
                        });
                    }
                    return null;
                });
                verificaPartecipanti(arrP);
                verificaCamere(arrC);
            } else if (nuovoStato === 4 || nuovoStato === 5 || nuovoStato === 6) {
                dataProvider.getManyReference(
                    'richiesta_dettagli',
                    {
                        target: 'id_richiesta_in',
                        id: selectedIds.map((id) => { return id }),
                        pagination: { page: 1, perPage: 1000 },
                        sort: { field: 'id', order: 'ASC' }
                    }
                ).then(({ data, total }) => {
                    if (total > 0) {
                        deleteMany(
                            'richiesta_dettagli',
                            { ids: data.map(({id}) => { return id }) },
                        ).then(() => {
                            dataProvider.getManyReference(
                                'richiesta_alloggi',
                                {
                                    target: 'id_richiesta_in',
                                    id: selectedIds.map((id) => { return id }),
                                    pagination: { page: 1, perPage: 1000 },
                                    sort: { field: 'id', order: 'ASC' }
                                }
                            ).then(({ data, total }) => {
                                if (total > 0) {
                                    dataProvider.deleteMany(
                                        'richiesta_alloggi',
                                        { ids: data.map(({id}) => { return id }) },
                                    ).then(() => {
                                        notify('Operazione completata', { type: 'success' })
                                    })
                                    .catch(error => {
                                        logger(8, `deleteMany 'richiesta_alloggi': ${error} ${selectedIds}`, null, null, null, idUtente);
                                    });
                                }
                            })
                            .catch(error => {
                                logger(8, `getManyReference 'richiesta_alloggi': ${error} ${selectedIds}`, null, null, null, idUtente);
                            });
                        })
                        .catch(error => {
                            logger(8, `deleteMany 'richiesta_dettagli': ${error} ${selectedIds}`, null, null, null, idUtente);
                        });
                    }
                })
                .catch(error => {
                    logger(8, `getManyReference 'richiesta_dettagli': ${error} ${selectedIds}`, null, null, null, idUtente);
                });
            }
            refresh();
            unselectAll();
        },
        onError: error => {
            rispostaAggiornamento.ko++;
            // setRispostaAggiornamento({...rispostaAggiornamento, ko: (rispostaAggiornamento.ko+1)});
            notify('Errore nella modifica delle richieste selezionate', { type: 'error' })
        }
    }
    );
    
    const FormMessaggioBase = ({ chiudi }) => {
        const [testoMessaggio, setTestoMessaggio] = useState(null);
        const [conteggioInvii, setConteggioInvii] = useState(0);
        // const [verifica, setVerifica] = useState(false);
        const [create, { isLoading, error }] = useCreate();
        const inviaMessaggio = () => {
            selectedIds.map((idRichiesta, index) => {
                logger(16, testoMessaggio, null, null, idRichiesta, idUtente).then((res) => {
                    create('richiesta_messaggio', {
                        data: {
                            id_richiesta: idRichiesta,
                            id_utente: idUtente,
                            messaggio: testoMessaggio
                        }
                    });
                    if (res.data.id)
                        setConteggioInvii(conteggioInvii+1);
                })
                // console.log(index, (selectedIds.length-1));
                // if (index === (selectedIds.length-1)) {
                //     console.log('verifica a true');
                //     setVerifica(true);
                // }
                return null;
            })
            setMostraModalMessaggioBase(false);
            unselectAll();
            notify('Operazione completata', { type: 'success' });
            setTestoMessaggio(null);
        }
        
        // useEffect(() => {
        //     console.log(verifica);
        //     if (verifica === true) {
        //         console.log(conteggioInvii, selectedIds);
        //         if (conteggioInvii === selectedIds.length) {
        //             notify('Operazione completata', { type: 'success' });
        //             setTestoMessaggio(null);
        //             setMessaggioFinale(false);
        //         } else if (conteggioInvii < selectedIds.length) {
        //             notify('Operazione completata parzialmente', { type: 'warning' });
        //         } else {
        //             notify('Operazione non completata', { type: 'error' });
        //         }
        //     }
        // }, [verifica, conteggioInvii]);

        return (
            <Grid container>
                <Grid item xs={12} md={12}>
                    <HeaderSezione titolo='Invia un messaggio per le richieste selezionate' />
                    <TextFieldMui label='Messaggio' fullWidth multiline rows={5} onChange={e => setTestoMessaggio(e.target.value !== '' ? e.target.value : null)}></TextFieldMui>
                    <Box display='flex' justifyContent='end' gap={5}>
                        <ButtonMui size='medium' variant='outlined' onClick={chiudi}>Annulla</ButtonMui>
                        <ButtonMui size='medium' variant='contained' disabled={!testoMessaggio} onClick={inviaMessaggio}>Invia messaggio</ButtonMui>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if (!dettagli) return null;

    return (
        <>
            <Form>
                <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} spacing={1}>
                    {idViaggio ?
                    <Grid item>
                        <MessaggioFinaleMemo idRichieste={selectedIds} idViaggio={idViaggio} dettagli={dettagli} />
                    </Grid>
                    : null}
                    <Grid item>
                        <ButtonMui onClick={() => setMostraModalMessaggioBase(!mostraModalMessaggioBase)} sx={{ mr: 3}}>Invia messaggio</ButtonMui>
                        {/* <Button label='Invia messaggio' onClick={() => setMostraModalMessaggioBase(!mostraModalMessaggioBase)} sx={{ mr: 3}}></Button> */}
                    </Grid>
                    <Grid item variant='selectToolbar'>
                        <StatiRichiesta stato={setNuovoStato} />
                    </Grid>
                    <Grid item>
                        <ButtonMui variant='contained' disabled={isLoading || !nuovoStato} onClick={() => aggiornamentoStato()}>Salva</ButtonMui>
                        {/* <Button label="Salva" disabled={isLoading || !nuovoStato} onClick={() => aggiornamentoStato()} /> */}
                    </Grid>
                </Grid>
            </Form>
            <Confirm
                isOpen={apriConfermaCreazione}
                // loading={isLoading}
                title={(apriConfermaCreazione ? `Modificare lo stato delle richieste?` : "")}
                content={(apriConfermaCreazione ? `Per la richiesta sembrano essere già state create le camere ma il numero delle persone presenti non coincide con quelle da inserire ora. Confermando la modifica si procederà con la rigenerazione.` : "")}
                onConfirm={() => (setConfermaCreazione(true), setApriConfermaCreazione(false))}
                onClose={() => setApriConfermaCreazione(false)}
            />
            {/* <Modal open={mostraModalMessaggioBase} onClose={() => setMostraModalMessaggioBase(!mostraModalMessaggioBase)} keepMounted style={{ display: 'flex', 
            justifyContent: 'center', alignItems: 'center'}}>
                <FormMessaggioBase />
            </Modal> */}
            <Dialog
                open={mostraModalMessaggioBase}
                onClose={() => setMostraModalMessaggioBase(false)}
                fullWidth={true}
                maxWidth='lg'
            >
                <DialogContent>
                    <FormMessaggioBase chiudi={() => setMostraModalMessaggioBase(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
};

const ViaggioPreviewMemo = memo(ViaggioPreview);
const MessaggioFinaleMemo = memo(MessaggioFinale);
const RichiesteBulkActionButtonsMemo = memo(RichiesteBulkActionButtons);

export const RichiestaList = () => {
    // const [partecipanti, setPartecipanti] = useState({ adulti: 0, bambini_1: 0, bambini_3: 0, bambini_4: 0 });
    // const [postiLetto, setPostiLetto] = useState({ singole: 0, doppie: 0, matrimoniali: 0, triple: 0, quadruple: 0 });
    // const [viaggio, setViaggio] = useState(null);
    const { idViaggio } = useParams();
    const { permissions } = usePermissions();
    // const { data: viaggio } = useGetOne('viaggio', { id: idViaggio });
    const idUtente = localStorage.getItem('auth_uid');
    // const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: idUtente } : (viaggioAttivo ? {id_viaggio: viaggioAttivo} : null));
    const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: idUtente, archiviata: 0 } : (idViaggio ? {id_viaggio: idViaggio} : {archiviata: 0}));
    const { data: configSconti, isLoading, error } = useGetList('configurazioni',{ 
        pagination: { perPage: 99 },
        sort: { field: 'id', order: 'ASC' },
        filter: { nome_in: ['sconto_bambini_1','sconto_bambini_3'] }
    });
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const filtersAdmin = [
        <TextInput source="id_sw" label="Cerca per ID" alwaysOn resettable />,
        <TextInput source="nominativo_cs" label="Cerca per nome" alwaysOn resettable />,
        <ReferenceInput source="id_stato_richiesta" reference="stato_richiesta" alwaysOn resettable sort={{ field: 'ordine', order: 'ASC' }}>
            <SelectInput label="Stato richiesta" optionText="nome" optionValue="id" />
        </ReferenceInput>,
        (!idViaggio ?
        <ReferenceInput source="id_viaggio" reference="viaggio" alwaysOn resettable>
            <AutocompleteInput
                label="Viaggio"
                optionText={d => `${d.titolo} ${(moment(d.data_inizio).isValid() ? (d.data_inizio !== d.data_fine && moment(d.data_fine).isValid() ? ` (Dal ` : ` (`) + `${moment(d.data_inizio).format("DD/MM/YYYY")}` : ``) + (moment(d.data_fine).isValid() ? (d.data_inizio !== d.data_fine && moment(d.data_fine).isValid() ? ` al ` : ``) + `${moment(d.data_fine).format("DD/MM/YYYY")}` : ``) + `)`}`}
                optionValue="id"
                filterToQuery={txt => ({ titolo_cs: txt })}
            />
        </ReferenceInput>
        : <EditButton label="Vedi tutti" />),
        <NullableBooleanInput alwaysOn label="Modificate" source="modifiche_bool" nullLabel="Tutte" trueLabel="Si" falseLabel="No" />
    ];
    
    /*
    const RichiesteBulkActionButtons = ({ idViaggio }) => {
        const { selectedIds, data: dettagli, isLoadingSelected } = useListContext();
        // const [verificaInCorso, setVerificaInCorso] = useState(false);
        const dataProvider = useDataProvider();
        const [nuovoStato, setNuovoStato] = useState(null);
        const [apriConfermaCreazione, setApriConfermaCreazione] = useState(false);
        const [confermaCreazione, setConfermaCreazione] = useState(false);
        const [mostraModalMessaggioBase, setMostraModalMessaggioBase] = useState(false);
        const [rispostaAggiornamento, setRispostaAggiornamento] = useState({ daFare: 0, ok: 0, ko: 0 });
        const refresh = useRefresh();
        const notify = useNotify();
        const unselectAll = useUnselectAll('richiesta');
        const [create, { isLoading: isLoadingCreate, error }] = useCreate();
        const [updateMany, { isLoadingUpdateMany, errorUpdateMany }] = useUpdateMany();
        const [deleteMany, { isLoadingDelete, errorDelete }] = useDeleteMany();
        // console.log(dettagli);

        const disabilitaPartecipanti = (v) => {
            return new Promise(function (resolve, reject) {
                const idDaDisabilitare = v.map((i) => { return i.id });
                const payload = idDaDisabilitare.map(() => { return { alloggio: null, stato: 0 } });
                updateMany('richiesta_dettagli', { ids: idDaDisabilitare, data: payload })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
            })
        }

        const disabilitaCamere = (v) => {
            return new Promise(function (resolve, reject) {
                const idDaDisabilitare = v.map((i) => { return i.id });
                const payload = idDaDisabilitare.map(() => { return { stato: 0 } });
                updateMany('richiesta_alloggi', { ids: idDaDisabilitare, data: payload })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
            })
        }

        const creaPartecipanti = (v) => {
            // console.log(v);
            return new Promise(function (resolve, reject) {
                Object.keys(v).map((key) => {
                    // console.log(key, v[key]);
                    if (key !== 'id' && key !== 'id_utente') {
                        for (let i = 0; i < v[key]; i++) {
                            create(
                                'richiesta_dettagli', {
                                    data: {
                                        id_richiesta: v.id, 
                                        tipo: key.replace("n_", ""), 
                                        stato: 1,
                                        id_utente: v.id_utente,
                                    }
                                }
                            )
                        }
                    }
                    return notify('Operazione completata', { type: 'success' });
                });
            })
        }

        const creaCamere = (v) => {
            return new Promise(function (resolve, reject) {
                Object.keys(v).map((key) => {
                    // console.log(v, key, v[key]);
                    if (key !== 'id') {
                        for (let i = 1; i <= v[key]; i++) {
                            let codCamera = (key.replace("cam_", "")).substring(0,3);
                            let prefissoCamera, moltiplicatore;
                            switch (codCamera) {
                                case 'sin':
                                    prefissoCamera = '01_';
                                    moltiplicatore = 1;
                                    break;
                                case 'dop':
                                    prefissoCamera = '02_';
                                    moltiplicatore = 2;
                                    break;
                                case 'mat':
                                    prefissoCamera = '03_';
                                    moltiplicatore = 2;
                                    break;
                                case 'tri':
                                    prefissoCamera = '04_';
                                    moltiplicatore = 3;
                                    break;
                                case 'qua':
                                    prefissoCamera = '05_';
                                    moltiplicatore = 4;
                                    break;
                                default:
                                    break;
                            }
                            codCamera = prefissoCamera + codCamera;
                            for (let k = 1; k <= moltiplicatore; k++) {
                                // console.log(codCamera + "_" + i);
                                create(
                                    'richiesta_alloggi', {
                                        data: {
                                            id_richiesta: v.id, 
                                            alloggio: codCamera + "_" + i, 
                                            stato: 1,
                                            id_utente_richiesta: dettagli.filter(d => d.id === v.id)[0].id_utente
                                        }
                                    }
                                )
                            }
                        }
                    }
                    return notify('Operazione completata', { type: 'success' });
                });
            })
        }

        useEffect(() => {
            // console.log(rispostaAggiornamento);
            if (rispostaAggiornamento.ko > 0) {
                notify('Errore nell\'aggiornamento delle richieste', { type: 'error' });
            } else if (rispostaAggiornamento.ok > 0) {
                notify('Richieste modificate', { type: 'success' });
                // refresh();
                // unselectAll();
            }
        }, [rispostaAggiornamento]);

        function verificaPartecipanti (dati) {
            // console.log('VERIFICA', dati);
            return new Promise(function (resolve, reject) {
                dati.map((v) => {
                    // console.log(v);
                    dataProvider.getList(
                        'richiesta_dettagli',
                        {
                            filter: { id_richiesta: v.id, stato: 1 },
                            pagination: { page: 1, perPage: 100 },
                            sort: { field: 'id', order: 'ASC' }
                        }
                    )
                    .then(({ total: totPartecipantiCreati, data: partecipantiCreati }) => {
                        // console.log(`ID richiesta: ${v.id}, daCreare: ${(v.n_adulti + v.n_bambini_1 + v.n_bambini_3 + v.n_bambini_4)}, totPartecipantiCreati: ${totPartecipantiCreati}`);
                        // console.log(v.id, totPartecipantiCreati);
                        if (totPartecipantiCreati === null || totPartecipantiCreati === 0) {
                            rispostaAggiornamento.daFare++;
                            creaPartecipanti(v).then(resolve());
                        } else {
                            const obj = {
                                id: partecipantiCreati[0].id_richiesta,
                                n_adulti: 0,
                                n_bambini_1: 0,
                                n_bambini_3: 0,
                                n_bambini_4: 0,
                            }
                            partecipantiCreati.map((r) => {
                                switch (r.tipo) {
                                    case 'adulti':
                                        obj.n_adulti += 1;
                                        break;
                                    case 'bambini_1':
                                        obj.n_bambini_1 += 1;
                                        break;
                                    case 'bambini_3':
                                        obj.n_bambini_3 += 1;
                                        break;
                                    case 'bambini_4':
                                        obj.n_bambini_4 += 1;
                                        break;
                                    default:
                                        break;
                                }
                                return null;
                            })
                            // console.log(obj);
                            if (JSON.stringify(v) !== JSON.stringify(obj)) {
                                // La composizione dei partecipanti non rispecchia quanto salvato in "richiesta_dettagli"
                                disabilitaPartecipanti(partecipantiCreati).then(
                                    creaPartecipanti(v).then(
                                        resolve()
                                    ).catch(error => {
                                        reject(error);
                                    })
                                ).catch(error => {
                                    reject(error);
                                });
                                // console.log(`${v.id} NON è uguale`);
                            } else {
                                // La composizione dei partecipanti è identica a quanto presente in "richiesta_dettagli"
                                // console.log(`${v.id} è uguale`);
                            }
                        }
                        // unselectAll();
                    })
                    .catch(error => {
                        reject(error);
                    });
                    return null;
                });
            })
        }

        function verificaCamere (dati) {
            // console.log('VERIFICA', dati);
            return new Promise(function (resolve, reject) {
                dati.map((v) => {
                    // console.log(v);
                    dataProvider.getList(
                        'richiesta_alloggi',
                        {
                            filter: { id_richiesta: v.id, stato_neq: 0 },
                            pagination: { page: 1, perPage: 100 },
                            sort: { field: 'id', order: 'ASC' }
                        }
                    )
                    .then(({ total: totAlloggiCreati, data: alloggiCreati }) => {
                        // console.log(`ID richiesta: ${v.id}, daCreare: ${(v.n_adulti + v.n_bambini_1 + v.n_bambini_3 + v.n_bambini_4)}, totAlloggiCreati: ${totAlloggiCreati}`);
                        // console.log(v.id, totAlloggiCreati);
                        if (totAlloggiCreati === null || totAlloggiCreati === 0) {
                            rispostaAggiornamento.daFare++;
                            creaCamere(v).then(resolve());
                        } else {
                            const alloggiUniciCreati = [...new Set(alloggiCreati.map((v) => {return v.alloggio}))];
                            // console.log(alloggiUniciCreati);
                            const obj = {
                                id: v.id,
                                cam_singola: 0,
                                cam_doppia: 0,
                                cam_matrimoniale: 0,
                                cam_tripla: 0,
                                cam_quadrupla: 0,
                            }
                            alloggiUniciCreati.map((r) => {
                                switch (r.substring(3,6)) {
                                    case 'sin':
                                        obj.cam_singola++;
                                        break;
                                    case 'dop':
                                        obj.cam_doppia++;
                                        break;
                                    case 'mat':
                                        obj.cam_matrimoniale++;
                                        break;
                                    case 'tri':
                                        obj.cam_tripla++;
                                        break;
                                    case 'qua':
                                        obj.cam_quadrupla++;
                                        break;
                                    default:
                                        break;
                                }
                                return null;
                            })
                            // console.log(obj);
                            if (JSON.stringify(v) !== JSON.stringify(obj)) {
                                // La composizione delle camere non rispecchia quanto salvato in "richiesta_dettagli"
                                disabilitaCamere(alloggiCreati).then(
                                    creaCamere(v).then(
                                        resolve()
                                    ).catch(error => {
                                        reject(error);
                                    })
                                ).catch(error => {
                                    reject(error);
                                });
                                // console.log(`${v.id} NON è uguale`);
                            } else {
                                // La composizione delle camere è identica a quanto presente in "richiesta_dettagli"
                                // console.log(`${v.id} è uguale`);
                            }
                        }
                        // unselectAll();
                    })
                    .catch(error => {
                        reject(error);
                    });
                    return null;
                });
            })
        }

        const [aggiornamentoStato, { isLoading }] = useUpdateMany(
            'richiesta', {
            ids: selectedIds,
            data: selectedIds.map(() => {
                const tmp = {};
                tmp.id_stato_richiesta = nuovoStato;
                return tmp;
            })
            }, {
            onSuccess: () => {
                dettagli.map((v) => {
                    if (selectedIds.some(e => { return e === v.id })) {
                        logger(11, `Nuovo stato: ${nuovoStato}`, null, v.id_viaggio, v.id, idUtente);
                    }
                    return null;
                });
                if (nuovoStato === 2) {
                    const arrP = [], arrC = [];
                    dettagli.map((v) => {
                        if (selectedIds.some(e => { return e === v.id })) {
                            // console.log(v);
                            // logger(11, `Nuovo stato: ${nuovoStato}`, null, v.id_viaggio, v.id, idUtente);
                            arrP.push({
                                id: parseInt(v.id),
                                id_utente: parseInt(v.id_utente),
                                n_adulti: v.n_adulti ?? 0,
                                n_bambini_1: v.n_bambini_1 ?? 0,
                                n_bambini_3: v.n_bambini_3 ?? 0,
                                n_bambini_4: v.n_bambini_4 ?? 0,
                            });
                            arrC.push({
                                id: parseInt(v.id),
                                cam_singola: v.cam_singola ?? 0,
                                cam_doppia: v.cam_doppia ?? 0,
                                cam_matrimoniale: v.cam_matrimoniale ?? 0,
                                cam_tripla: v.cam_tripla ?? 0,
                                cam_quadrupla: v.cam_quadrupla ?? 0,
                            });
                        }
                        return null;
                    });
                    verificaPartecipanti(arrP);
                    verificaCamere(arrC);
                } else if (nuovoStato === 4 || nuovoStato === 5 || nuovoStato === 6) {
                    dataProvider.getManyReference(
                        'richiesta_dettagli',
                        {
                            target: 'id_richiesta_in',
                            id: selectedIds.map((id) => { return id }),
                            pagination: { page: 1, perPage: 1000 },
                            sort: { field: 'id', order: 'ASC' }
                        }
                    ).then(({ data, total }) => {
                        if (total > 0) {
                            deleteMany(
                                'richiesta_dettagli',
                                { ids: data.map(({id}) => { return id }) },
                            ).then(() => {
                                dataProvider.getManyReference(
                                    'richiesta_alloggi',
                                    {
                                        target: 'id_richiesta_in',
                                        id: selectedIds.map((id) => { return id }),
                                        pagination: { page: 1, perPage: 1000 },
                                        sort: { field: 'id', order: 'ASC' }
                                    }
                                ).then(({ data, total }) => {
                                    if (total > 0) {
                                        dataProvider.deleteMany(
                                            'richiesta_alloggi',
                                            { ids: data.map(({id}) => { return id }) },
                                        ).then(() => {
                                            notify('Operazione completata', { type: 'success' })
                                        })
                                        .catch(error => {
                                            logger(8, `deleteMany 'richiesta_alloggi': ${error} ${selectedIds}`, null, null, null, idUtente);
                                        });
                                    }
                                })
                                .catch(error => {
                                    logger(8, `getManyReference 'richiesta_alloggi': ${error} ${selectedIds}`, null, null, null, idUtente);
                                });
                            })
                            .catch(error => {
                                logger(8, `deleteMany 'richiesta_dettagli': ${error} ${selectedIds}`, null, null, null, idUtente);
                            });
                        }
                    })
                    .catch(error => {
                        logger(8, `getManyReference 'richiesta_dettagli': ${error} ${selectedIds}`, null, null, null, idUtente);
                    });
                }
                refresh();
                unselectAll();
            },
            onError: error => {
                rispostaAggiornamento.ko++;
                // setRispostaAggiornamento({...rispostaAggiornamento, ko: (rispostaAggiornamento.ko+1)});
                notify('Errore nella modifica delle richieste selezionate', { type: 'error' })
            }
        }
        );
        
        const FormMessaggioBase = () => {
            const [testoMessaggio, setTestoMessaggio] = useState(null);
            const [conteggioInvii, setConteggioInvii] = useState(0);
            // const [verifica, setVerifica] = useState(false);
            const [create, { isLoading, error }] = useCreate();
            const inviaMessaggio = () => {
                selectedIds.map((idRichiesta, index) => {
                    logger(16, testoMessaggio, null, null, idRichiesta, idUtente).then((res) => {
                        create('richiesta_messaggio', {
                            data: {
                                id_richiesta: idRichiesta,
                                id_utente: idUtente,
                                messaggio: testoMessaggio
                            }
                        });
                        if (res.data.id)
                            setConteggioInvii(conteggioInvii+1);
                    })
                    // console.log(index, (selectedIds.length-1));
                    // if (index === (selectedIds.length-1)) {
                    //     console.log('verifica a true');
                    //     setVerifica(true);
                    // }
                    return null;
                })
                setMostraModalMessaggioBase(false);
                unselectAll();
                notify('Operazione completata', { type: 'success' });
                setTestoMessaggio(null);
            }
            
            // useEffect(() => {
            //     console.log(verifica);
            //     if (verifica === true) {
            //         console.log(conteggioInvii, selectedIds);
            //         if (conteggioInvii === selectedIds.length) {
            //             notify('Operazione completata', { type: 'success' });
            //             setTestoMessaggio(null);
            //             setMessaggioFinale(false);
            //         } else if (conteggioInvii < selectedIds.length) {
            //             notify('Operazione completata parzialmente', { type: 'warning' });
            //         } else {
            //             notify('Operazione non completata', { type: 'error' });
            //         }
            //     }
            // }, [verifica, conteggioInvii]);

            return (
                <Grid container margin={2} style={{backgroundColor:'#fff'}}>
                    <Grid item xs={12} md={12}>
                        <Box mx={5} mt={5}>
                            <HeaderSezione titolo='Invia un messaggio per le richieste selezionate' />
                            <TextFieldMui label='Messaggio' fullWidth multiline rows={5} onChange={e => setTestoMessaggio(e.target.value !== '' ? e.target.value : null)}></TextFieldMui>
                        </Box>
                        <Box mx={5} mb={5}>
                            <ButtonMui size='large' variant='contained' disabled={!testoMessaggio} onClick={inviaMessaggio}>Invia messaggio</ButtonMui>
                        </Box>
                    </Grid>
                </Grid>
            )
        }

        if (!dettagli) return null;

        return (
            <>
                <Form>
                    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} spacing={1}>
                        {idViaggio ?
                        <Grid item>
                            <MessaggioFinaleMemo idRichieste={selectedIds} idViaggio={idViaggio} dettagli={dettagli} />
                        </Grid>
                        : null}
                        <Grid item>
                            <ButtonMui onClick={() => setMostraModalMessaggioBase(!mostraModalMessaggioBase)} sx={{ mr: 3}}>Invia messaggio</ButtonMui>
                        </Grid>
                        <Grid item variant='selectToolbar'>
                            <StatiRichiesta stato={setNuovoStato} />
                        </Grid>
                        <Grid item>
                            <ButtonMui variant='contained' disabled={isLoading || !nuovoStato} onClick={() => aggiornamentoStato()}>Salva</ButtonMui>
                        </Grid>
                    </Grid>
                </Form>
                <Confirm
                    isOpen={apriConfermaCreazione}
                    // loading={isLoading}
                    title={(apriConfermaCreazione ? `Modificare lo stato delle richieste?` : "")}
                    content={(apriConfermaCreazione ? `Per la richiesta sembrano essere già state create le camere ma il numero delle persone presenti non coincide con quelle da inserire ora. Confermando la modifica si procederà con la rigenerazione.` : "")}
                    onConfirm={() => (setConfermaCreazione(true), setApriConfermaCreazione(false))}
                    onClose={() => setApriConfermaCreazione(false)}
                />
                <Modal open={mostraModalMessaggioBase} onClose={() => setMostraModalMessaggioBase(!mostraModalMessaggioBase)} keepMounted style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FormMessaggioBase />
                </Modal>
            </>
        )
    };
    */

    const stileNuovaRiga = (record, index) => ({
        backgroundColor: record.ultimo_accesso_1 ? null : myTheme.palette.success['50']
    });

    return (
        <Card sx={{ mt: 1 }}>
            <CardContent py={{ xs: 2, md: 3, xl: 4 }}>
                <Grid container columnSpacing={2}>
                    {idViaggio && permissions === base64_encode(admB64) ?
                        <Grid item xs={12} sm={12}>
                            <ViaggioPreviewMemo id={idViaggio} variante='orizzontale' />
                        </Grid>
                    : null }
                    {/* <Grid item xs={12} sm={12} md={idViaggio && permissions === base64_encode(admB64) ? 8 : 12} xl={idViaggio && permissions === base64_encode(admB64) ? 9 : 12}> */}
                    <Grid item xs={12} sm={12}>
                        <List resource="richiesta_e_modifiche" filter={permFilter} filters={filtersAdmin} sort={{ field: 'id', order: 'DESC' }} disableSyncWithLocation perPage={100} exporter={false} empty={<EmptyPage testo='richieste' id_viaggio={idViaggio} />} actions={false} title="Richieste">
                            
                            <Datagrid bulkActionButtons={<RichiesteBulkActionButtonsMemo idViaggio={idViaggio ?? null} />} expandSingle expand={<RichiestaDetails preventivo={{configSconti: configSconti}} />} empty={<Box sx={{mx:2}}><EmptyPage testo='richieste' /></Box>} rowStyle={stileNuovaRiga}>
                                {!isSmall ? <TextField source="id" label="ID" sortable={false} /> : null}
                                <FunctionField
                                    sortable={false}
                                    label="Nominativo"
                                    render={record => {
                                        return (<>
                                            {(record.modifiche_bool === 'true' ? 
                                                <Tooltip
                                                    title={
                                                        <Box>{(record.modifiche).split(", ").map((v,i) => <Typography key={i} variant='p' component='p'>{v.toUpperCase()}</Typography>)}</Box>
                                                    }
                                                    sx={{mr: 0.5}}
                                                >
                                                    <NotificationsActive color='warning' fontSize='small' />
                                                </Tooltip>
                                            : null)}
                                            <Typography variant='p' component='p' sx={{ pb: 0 }}>{record.nominativo} (x {((record.n_adulti || null) + (record.n_bambini_1 || null) + (record.n_bambini_3 || null) + (record.n_bambini_4 || null))})</Typography>
                                            {(permissions === base64_encode(admB64) && record.note_pvt ?
                                                <AlertNotePvt />
                                            :null)}
                                        </>)
                                    }}
                                    sx={{ display: 'inline-flex', alignItems:'center' }}
                                />

                                <WithRecord label='Fermata' render={record => {
                                    if (record.fermata) return listaFermate.filter(f => f.id === record.fermata)[0].name
                                    else return 'N.D.'
                                }} />
                                
                                {!idViaggio ?
                                <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false} sortable={false}>
                                    <FunctionField sortable={false} render={record => {
                                        return <Link color='secondary.dark' sx={{ fontWeight: '500' }} to={`viaggio/${record.id}`}>{record.titolo + (moment(record.data_inizio).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (moment(record.data_fine).isValid() && moment(record.data_fine).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`}</Link>
                                    }} />
                                </ReferenceField>
                                : null }
                                {!idViaggio ?
                                <ReferenceField source="id_viaggio" reference="viaggio" label="Stato viaggio" link={false} textAlign='center' sortable={false}>
                                    <ReferenceField source="id_stato_viaggio" reference="stato_viaggio">
                                        <FunctionField label="Richiesta" sortable={false} textAlign="center" render={record => {
                                            return <IconaStatoViaggio vista='admin' stato={record.id} />
                                        }} />
                                    </ReferenceField>
                                </ReferenceField>
                                : null }
                                <IconaMessaggioFinale />
                                <FunctionField label="Richiesta" sortable={false} textAlign="center" render={record => {
                                    return <IconaStatoRichiesta stato={record.id_stato_richiesta} />
                                }} />
                                <EditButton color='primary' variant='contained' resource="richiesta" label={permissions === base64_encode(admB64) ? 'Modifica' : 'Vedi' } startIcon={null} />
                                <FunctionField label="PDF" sortable={false} textAlign="center" render={record => {
                                    return record.id_stato_richiesta === 3 ? <TastoGeneraPdf richiesta={record} /> : null
                                }} />
                            </Datagrid>
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};