import { useGetList } from "react-admin";

export const useGetFermate = () => {
    const { data: listaFermate, isLoading: isLoadingListaFermate, error: errorListaFermate } = useGetList(
        'fermata',
        {
            pagination: { page: 1, perPage: 99 },
            sort: { field: 'id', order: 'ASC' }
        }
    );

    function getData() {
    }

    return [getData, { listaFermate, isLoadingListaFermate, errorListaFermate }]
}