import { useEffect, useState, memo } from "react";
import { List, Datagrid, TextField, ReferenceField, TopToolbar, CreateButton, CheckboxGroupInput, useUpdate, FunctionField, usePermissions, useListContext, useRecordContext, TextInput, ReferenceInput, SelectInput, useStore, useRedirect, useDataProvider, useRefresh, useNotify, useTheme } from 'react-admin';
import { Box, IconButton, Modal, Grid, Tooltip, FormControlLabel, Checkbox, Typography, TextField as TextFieldMui, useMediaQuery } from '@mui/material';
import { AddCircle, ModeEdit, DoNotDisturbAlt, CheckCircleOutline, Assignment, Email, MarkEmailRead, PauseCircleOutline, TaskAlt, Verified, Share } from '@mui/icons-material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import moment from 'moment';
import { TastoGeneraPdf } from './ViaggiListaPdf';
import { logger, admB64, copyToClipboard, apiBaseUrl } from '../generale';
import { encode as base64_encode } from 'base-64';
import { IconaRiepilogoMessaggiViaggio } from "../Richieste/MessaggioFinale.js";

export const ViaggiList = () => {
    const notify = useNotify();
    const [theme, setTheme] = useTheme();
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    // const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: localStorage.getItem('auth_uid') } : {});
    const permFilter = {
        // stato: 1,
        data_inizio_ge: moment().format("YYYY-MM-DD")
    };
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    // const [viaggioAttivo, setViaggioAttivo] = useStore('viaggio_attivo', null);
    // const record = useRecordContext();
    const redirect = useRedirect();

    const filtersAdmin = [
        <TextInput source="id_sw" label="Cerca per ID" alwaysOn resettable />,
        <TextInput source="titolo_cs" label="Cerca per titolo" alwaysOn resettable />,
        <ReferenceInput source="id_stato_viaggio" reference="stato_viaggio" alwaysOn resettable>
            <SelectInput label="Stato" optionText="nome" optionValue="id" />
        </ReferenceInput>
    ];

    const handleNuovaRichiesta = ({ id }) => {
        // console.log(id);
        redirect(`/richiesta/create/${id}`);
    }

    const handleVistaRichiesteViaggio = ({ id }) => {
        // console.log(id);
        redirect(`/richiesta/viaggio/${id}`);
    }

    return (
        <>
            <List
                resource="viaggio_dettagli"
                title='Prossimi Viaggi'
                filter={permFilter}
                filters={(permissions === base64_encode(admB64) ? filtersAdmin : null)}
                sort={{ field: 'data_inizio', order: 'ASC' }}
                perPage={50}
                exporter={false}
                empty={<EmptyPage testo='viaggi' />}
                actions={<TopToolbar><CreateButton resource="viaggio" variant="contained" /></TopToolbar>}
                disableSyncWithLocation
            >
                <Datagrid bulkActionButtons={false}>
                    {permissions === base64_encode(admB64) && !isTablet ?
                        <TextField source="id" label="ID" sortable={false} />
                        : null}
                    <FunctionField sortable={false} render={record => (
                        (record.titolo !== null && record.titolo !== '' ? `${record.titolo}` : ``) +
                        (moment(record.data_inizio).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM/YYYY")}` : ``) +
                        (moment(record.data_fine).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YYYY")}` : ``) +
                        `)`
                    )} label="Viaggio" />
                    <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Stato" link={false} sortable={false}>
                        <TextField source="nome" />
                    </ReferenceField>
                    <FunctionField textAlign="center" sortable={false} render={record => (
                        record.n_da_validare > 0 ? record.n_da_validare : '-'
                    )}
                        color={theme.palette.warning[900]}
                        label={<Tooltip title="In attesa"><PauseCircleOutline color="warning" /></Tooltip>}
                        sx={{ fontWeight: 'bold' }}
                    />
                    <FunctionField textAlign="center" sortable={false} render={record => (
                        record.n_validi > 0 ? record.n_validi : '-'
                    )}
                        color="primary"
                        label={<Tooltip title="Validi"><TaskAlt color="primary" /></Tooltip>}
                        sx={{ fontWeight: 'bold' }}
                    />
                    <FunctionField textAlign="center" sortable={false} render={record => (
                        record.n_confermati > 0 ? record.n_confermati : '-'
                    )}
                        color={theme.palette.success[700]}
                        label={<Tooltip title="Confermati"><Verified color="success" /></Tooltip>}
                        sx={{ fontWeight: 'bold' }}
                    />
                    {/* {permissions === base64_encode(admB64) ?
                        <FunctionField sortable={false} render={record => (
                            (record.stato === 0 ? <DoNotDisturbAlt color="disabled" /> : <CheckCircleOutline color="success" sx={{m:0, p:0}} />)
                        )} sx={{alignItems:'center', justifyContent:'center'}} />
                    : null} */}
                    <FunctionField sortable={false} render={record => (
                        <Box display='flex' flexDirection='row'>
                            <Tooltip title={`Modifica viaggio`}>
                                <IconButton variant="iconaLista" color="secondary" onClick={() => redirect('edit', 'viaggio', record.id )}><ModeEdit /></IconButton>
                            </Tooltip>

                            {(record.stato === 0 ?
                            <Tooltip title={`Viaggio NON ATTIVO`}>
                                <IconButton color="error" variant="iconaLista"><DoNotDisturbAlt /></IconButton>
                            </Tooltip>
                            :
                            <Tooltip title={`Viaggio ATTIVO`}>
                                <IconButton color="success" variant="iconaLista"><CheckCircleOutline /></IconButton>
                            </Tooltip>
                            )}

                            {(record.stato === 1 && record.id_stato_richiesta !== 6 ?
                                // <Button onClick={() => handleNuovaRichiesta({id: record.id})}><AddCircle /></Button>
                                <Tooltip title={`Aggiungi nuova richiesta`}>
                                    <IconButton variant="iconaLista" color="secondary" onClick={() => handleNuovaRichiesta({ id: record.id })}><AddCircle /></IconButton>
                                </Tooltip>
                                : null)}
                            <Tooltip title={`Lista richieste`}>
                                <IconButton variant="iconaLista" color="secondary" onClick={() => handleVistaRichiesteViaggio({ id: record.id })}><Assignment /></IconButton>
                            </Tooltip>
                            <Tooltip title={`Condividi link`}>
                                <IconButton variant="iconaLista" color="secondary" onClick={() => copyToClipboard(`${apiBaseUrl}/#/richiesta/create/${record.id}`).then(res => notify('Il link è stato copiato', { type: 'info' })).catch(err => notify(err, { type: 'error' }))}><Share /></IconButton>
                            </Tooltip>
                            {permissions === base64_encode(admB64) && ( (record.n_confermati + record.n_invalidati) > 0 ) ?
                            <>
                            <TastoGeneraPdf viaggio={record} key={`pdf_${record.id}_f`} versione="Finanze" />
                            <TastoGeneraPdf viaggio={record} key={`pdf_${record.id}_r`} versione="Riepilogo" />
                            </>
                            : null }
                            {(record.id_stato_viaggio === 3 || record.id_stato_viaggio === 4 || record.id_stato_viaggio === 5 ?
                                <IconaRiepilogoMessaggiViaggio />
                                : null)}
                        </Box>
                    )} />

                    {/* <FunctionField sortable={false} render={record => (
                        <Button onClick={() => handleVistaRichiesteViaggio({id: record.id})}><Assignment /></Button>
                    )} />

                    {permissions === base64_encode(admB64) ?
                        <FunctionField sortable={false} render={record => (
                            ((record.id_stato_viaggio === 3 || record.id_stato_viaggio === 4 || record.id_stato_viaggio === 5) && record.n_validi > 0 ? 
                                <ListaPDF viaggio={record} key={`pdf_${record.id}`} />
                            :null)
                        )} />
                    : null } */}

                </Datagrid>
            </List>
        </>
    )
};