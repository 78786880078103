import React from "react";

function IconaPubblicato({ ...props }) {
  return (
<svg
    viewBox="0 0 2250 1257"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <rect
      id="_1-pubblicato"
      x={0}
      y={0}
      width={2250}
      height={1256.25}
      style={{
        fill: "none",
      }}
    />
    <path
      d="M2083.36,909.693l-187.337,0c-16.64,-82.047 -88.786,-140.99 -172.483,-140.99c-83.725,-0 -155.843,58.943 -172.539,140.99l-851.993,0c-16.668,-82.047 -88.785,-140.99 -172.511,-140.99c-83.725,-0 -155.842,58.943 -172.483,140.99l-170.751,0c-24.286,-0.027 -44.008,-19.721 -44.035,-44.035l-0,-521.714c0.082,-76.436 61.969,-138.295 138.377,-138.405l1560.66,0c40.982,0.11 80.479,14.99 111.312,41.945l-228.235,28.495l-1370.85,0c-19.446,0 -35.233,15.733 -35.233,35.179l-0,211.266c-0,19.446 15.787,35.178 35.233,35.178l1371.53,0l388.782,32.429l0,292.212c-0.027,15.155 -12.322,27.45 -27.449,27.45Z"
      style={{
        fill: "#d1e7dd",
      }}
    />
    <g id="Pubblicato">
      <rect
        x={335.781}
        y={275.979}
        width={352.817}
        height={281.596}
        style={{
          fill: "#d1e7dd",
        }}
      />
    </g>
    <path
      d="M2165.37,515.355l-108.837,-239.898c-39.194,-85.266 -124.377,-140.028 -218.279,-140.303l-1560.66,-0c-115.245,0.138 -208.68,93.489 -208.79,208.79l0,521.714c0.083,63.178 51.297,114.365 114.448,114.42l170.751,0c16.641,82.102 88.758,141.018 172.483,141.018c83.726,0 155.843,-58.916 172.511,-141.018l851.993,0c16.696,82.102 88.814,141.018 172.539,141.018c83.697,0 155.843,-58.916 172.483,-141.018l187.337,0c53.992,-0.027 97.78,-43.788 97.835,-97.835l-0,-294c0.027,-25.14 -5.391,-50.004 -15.816,-72.888m-76.298,2.338l-330.362,-27.67l-0,-147.867l237.395,-29.678l92.967,205.215Zm-1562.58,532.798c-58.337,-0 -105.618,-47.281 -105.618,-105.619c-0,-58.311 47.281,-105.619 105.618,-105.619c58.338,-0 105.619,47.308 105.619,105.619c0,58.338 -47.281,105.619 -105.619,105.619m1197.04,-0c-58.366,-0 -105.619,-47.281 -105.619,-105.619c-0,-58.311 47.253,-105.619 105.619,-105.619c58.338,-0 105.591,47.308 105.591,105.619c0,58.338 -47.253,105.619 -105.591,105.619m359.82,-140.798l-187.337,0c-16.64,-82.047 -88.786,-140.99 -172.483,-140.99c-83.725,-0 -155.843,58.943 -172.539,140.99l-851.993,0c-16.668,-82.047 -88.785,-140.99 -172.511,-140.99c-83.725,-0 -155.842,58.943 -172.483,140.99l-170.751,0c-24.286,-0.027 -44.008,-19.721 -44.035,-44.035l-0,-521.714c0.082,-76.436 61.969,-138.295 138.377,-138.405l1560.66,0c40.982,0.11 80.479,14.99 111.312,41.945l-228.235,28.495l-1370.85,0c-19.446,0 -35.233,15.733 -35.233,35.179l-0,211.266c-0,19.446 15.787,35.178 35.233,35.178l1371.53,0l388.782,32.429l0,292.212c-0.027,15.155 -12.322,27.45 -27.449,27.45m-1697.69,-563.329l253.046,0l-0,140.826l-253.046,-0l0,-140.826Zm323.458,0l275.05,0l-0,140.826l-275.05,-0l0,-140.826Zm345.462,0l266.248,0l0,140.826l-266.248,-0l0,-140.826Zm336.661,0l297.081,0l-0,140.826l-297.081,-0l-0,-140.826Z"
      style={{
        fillRule: "nonzero",
      }}
    />
  </svg>
  );
}

export default IconaPubblicato;