import { useEffect, useState } from "react";
import { List, Pagination, useGetList, TextField, ReferenceField, WithRecord, Button, ShowButton, FunctionField, usePermissions, useListContext, useRecordContext, TextInput, ReferenceInput, SelectInput, useStore, useRedirect, useListController, TopToolbar } from 'react-admin';
import { Typography, Box, Grid, Card, CardActionArea, CardMedia, CardHeader, CardContent, useMediaQuery, TextField as TextFieldMui, Paper } from '@mui/material';
import { CalendarMonth, ArrowForward, CalendarToday, Circle, Euro, Info, Ballot } from '@mui/icons-material';
import { apiBaseUrl, valutaFormatInt, localeITmoment, myTheme, divideString, ResponsiveBreakpoint } from '../generale';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import moment from 'moment';
import "./GoogleFonts.css";
import { useForm, FormProvider } from 'react-hook-form';
import { CategorieViaggioFiltro } from "./CategorieViaggioFiltro";

const calcoloDataViaggio = ({ inizio, fine }) => {
    const checkInizio = moment(inizio).isValid() ? moment(inizio) : false
    if (!checkInizio) return false
    const checkFine = moment(fine).isValid() ? moment(fine) : false
    const durata = inizio !== fine && checkFine ? checkFine.diff(checkInizio, 'days')+1 : 1
    const testuale = durata === 1 ? `${moment(inizio).format("DD MMMM")} (1 giorno)` : `${moment(inizio).format("DD MMM")} - ${moment(fine).format("DD MMM YYYY")} (${durata} giorni)`
    return {
        durata: durata,
        testuale: testuale
    }
}

/*
export const BloccoViaggioCompatto = ({ record, i, nuovaRichiesta }) => {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('xl'));
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <Grid item xs={12} lg={6} xl={6} xxl={4}>
            <Card sx={{ maxWidth: "unset" }} onClick={() => nuovaRichiesta({ id: record.id })}>
                <CardActionArea>
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            <CardMedia
                                component="img"
                                style={{ height: '100%', aspectRatio: '991 / 661' }}
                                src={`${apiBaseUrl}${process.env.REACT_APP_VIAGGI_IMG_ENDPOINT}/${record.img_intro}`}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <CardHeader
                                title={record.titolo}
                                titleTypographyProps={{
                                    fontFamily: 'Patrick Hand',
                                    textTransform: 'uppercase',
                                    fontSize: 18,
                                    paddingBottom: 0,
                                    color: 'primary',
                                    fontWeight: 'bold'
                                }}
                                subheader={
                                    <Box display='flex' alignItems='_center' justifyContent='_center' mt={1}>
                                        {divideString(record.descr_breve, 35)} {(record.descr_breve).length > 40 ? '...' : null}
                                    </Box>
                                }
                                subheaderTypographyProps={{
                                    fontSize: 14,
                                    padding: 0
                                }}
                            />

                            <CardContent sx={{ pt: 0 }} variant="boxViaggioUtente">

                                <Box display="flex" flexDirection='column' fontSize={16} fontWeight='bold' textAlign='right' justifyContent='space-between'>
                                    <Box border='0px solid #000' mt={0.5} fontSize={15} fontWeight='bold' textAlign='left' flex={1}>
                                        <Box display='flex' alignItems='center' justifyContent='start'>
                                            <CalendarMonth color="primary" sx={{ fontSize: { xs: 20, xl: 25 } }} />
                                            <Typography component='p' variant="p" ml={1}>{(moment(record.data_inizio).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` Dal ` : ``) + `${moment(record.data_inizio).format("DD MMM")}` : ``) +
                                                (moment(record.data_fine).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` al ` : ``) + `${moment(record.data_fine).format("DD MMM")}` : ``)}</Typography>
                                        </Box>
                                    </Box>

                                    <Box border='0px solid #000' mt={0.5} fontSize={15} fontWeight='bold' textAlign='left' flex={1}>
                                        <Box display='flex' alignItems='center' justifyContent='start'>
                                            <Euro color="primary" sx={{ fontSize: { xs: 20, xl: 25 } }} />
                                            <Typography component='p' variant="p" ml={1}>{valutaFormat.format(record.prezzo)}</Typography>
                                        </Box>
                                    </Box>

                                    <Box border='0px solid #000' mt={0.5} fontSize={15} fontWeight='bold' textAlign='left' flex={1}>
                                        <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Stato" link={false} record={record}>
                                            <Box display='flex' alignItems='center' justifyContent='start'>
                                                <Info color="primary" sx={{ fontSize: { xs: 20, xl: 25 } }} />
                                                <WithRecord label="nome" render={record => <Typography component='p' variant="p" ml={1}>{record.nome}</Typography>} />
                                            </Box>
                                        </ReferenceField>
                                    </Box>
                                </Box>

                            </CardContent>
                        </Grid>
                    </Grid>

                </CardActionArea>
            </Card>
        </Grid>
    )
}
*/

export const BloccoViaggioEsteso = ({ record, nuovaRichiesta }) => {
    const dataViaggio = calcoloDataViaggio({ inizio: record.data_inizio, fine: record.data_fine})
    const webpImg = record.img.replace(/(\.gif|\.png|.jpg|.jpeg)/, '.webp');
    return (
        <Grid item xs={12} sm={6} lg={4} xl={3} xxl={3}>
            <Card variant="boxViaggioUtente" sx={{ maxWidth: "unset", height: 'auto', backgroundColor: '#fff', height: '100%' }} onClick={() => nuovaRichiesta({ id: record.id })}>
                <CardActionArea sx={{ height: '100%', display: 'flex', flexDirection:'column', justifyContent:'space-between' }}>
                    <Box sx={{ width: '100%', aspectRatio: '1.9 / 1', overflow: 'hidden', display: 'flex', flex:1, justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <CardMedia
                            component="img"
                            loading="lazy"
                            style={{ width: '100%', aspectRatio: '1.9 / 1', position: 'absolute', top: 0 }}
                            src={`${apiBaseUrl}${process.env.REACT_APP_VIAGGI_IMG_ENDPOINT}${webpImg}`}
                        />
                    </Box>
                    <Box px={0} mt="-10px" position='relative' width='100%'>

                        <CardContent sx={{ p: "0 !important" }}>

                            <Paper variant="outlined" sx={{ display: 'flex', flexDirection:'column', borderRadius: 2, overflow:'hidden', mx: 1 }}>
                                <Box display='flex' alignItems='center' flexDirection='column' backgroundColor={myTheme.palette.secondary.lighter} p={1}>
                                    <Typography component='p' variant="h3" textAlign='center' textTransform='uppercase' mb={0} color={myTheme.palette.secondary.darker}>{record.titolo}</Typography>
                                </Box>
                                <Box p={1} gap={1} display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                                    <Typography component='p' mb={0} fontWeight='600' fontSize={12} textAlign='center'>{record.descr_breve ?? ''}</Typography>
                                </Box>
                            </Paper>

                            <Box sx={{ display: 'flex', flexDirection:'column', overflow:'hidden', py: 3 }}>
                                <Box gap={1} display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                                    <CalendarToday sx={{ fontSize: "16px !important", color: myTheme.palette.grigio[600] }} />
                                    <Typography component='p' mb={0} fontWeight='600' fontSize={14} textAlign='center'>{dataViaggio.testuale ?? ''}</Typography>
                                </Box>
                                <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Stato" link={false} record={record}>
                                    <WithRecord label="nome" render={record =>  {
                                        const colore = JSON.parse(record.colore)
                                        return (
                                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, justifyContent: 'center', alignItems:'center' }}>
                                                <Typography
                                                    component='p'
                                                    fontSize={13}
                                                    mb={0}
                                                    // color={colore.contrastText}
                                                    fontWeight='500'
                                                    textAlign='center'
                                                >Stato viaggio:</Typography>
                                                <Circle sx={{ fontSize: "14px !important", color: colore.main }} />
                                                <Typography
                                                    component='p'
                                                    fontSize={13}
                                                    mb={0}
                                                    // color={colore.contrastText}
                                                    fontWeight='700'
                                                    textAlign='center'
                                                >{record.nome}</Typography>
                                            </Box>
                                    )}} />
                                </ReferenceField>

                            </Box>

                            <Box display="flex" flexDirection='row' justifyContent='space-between' backgroundColor="primary.lighter" py={1} px={2}>
                                <Box fontWeight='700' textAlign='left' display='flex' flexDirection='row' alignItems='center' gap={1}>
                                    <Typography component='p' variant="" mb={0} textAlign='center' fontSize={22}>{valutaFormatInt.format(record.prezzo)}</Typography>
                                    {record.prezzo_prec && parseFloat(record.prezzo_prec) !== 0 ?
                                    <Typography component='p' variant="small" sx={{ textDecoration: 'line-through', fontWeight: 700, fontSize:10, ml: 0.5, mb:0, color:"#6c757d", textAlign:'center' }}>{valutaFormatInt.format(record.prezzo_prec)}</Typography>
                                    :null}
                                </Box>
                                <Box display="flex" flexDirection='row' py={0} gap={0.5}>
                                    <Typography component='p' variant="p" sx={{ mb:0, fontWeight: 600, textAlign: 'right', fontSize: 14 }}>Prenota</Typography>
                                    <ArrowForward sx={{ fontSize: "22px !important" }} />
                                </Box>
                            </Box>

                        </CardContent>
                    </Box>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export const CardWrapper = ({ listaCategorie }) => {
    const { data, total } = useListContext();
    const [viaggioAttivo, setViaggioAttivo] = useStore('viaggio_attivo', null);
    const redirect = useRedirect();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    moment.updateLocale('it', localeITmoment);
    const handleNuovaRichiesta = ({ id }) => {
        // console.log(id);
        setViaggioAttivo(id);
        redirect(`/richiesta/create/${id}`);
    }

    useEffect(() => {
        setViaggioAttivo(null);
    }, []);

    if (!data) return <LoadingComponent />;
    if (total === 0) return <EmptyPage testo='viaggi' nospace />

    return (
        <Grid container spacing={2} sx={{ px: 2, my: 2 }}>
            {data.map((record, i) => {
                return <BloccoViaggioEsteso
                    key={i}
                    record={record}
                    nuovaRichiesta={handleNuovaRichiesta}
                />
            })}
        </Grid>
    )
}

const CustomFilters = () => {
    const { filterValues, setFilters } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });

    // useEffect(() => {
    //     console.log(filterValues)
    // }, [filterValues])

    return (
        <FormProvider {...form}>
            <form>
                <Box display="flex" alignItems="stretch" sx={{ flexDirection: { xs: 'column', sm: 'row' }, px: { xs: 2, lg: 0 } }} gap={{ xs: 0, sm: 2}}>
                    <Box component="span" sx={{ mr: { xs: 0, lg: 2 } }}>
                        <TextFieldMui
                            onChange={(e) => setFilters({ ...filterValues, titolo_cs: e.target.value }, undefined, false)}
                            placeholder="Cerca per titolo"
                            label="Cerca per titolo"
                            sx={{ width: { xs: '100%', lg: 'auto'} }}
                        />
                    </Box>
                    <Box className="MuiFormControl-root" component="span">
                        <CategorieViaggioFiltro update={setFilters} filtri={filterValues} />
                    </Box>
                </Box>
            </form>
        </FormProvider>
    )
}

const ListActions = () => (
    <Card variant='outlined' sx={{
        width:"100%",
        display: 'flex',
        flexDirection:'column',
        alignItems: 'center',
        mb: 2,
        borderColor: myTheme.palette.primary.main,
        backgroundColor: myTheme.palette.primary.lighter,
        p: 1,
        // position:'sticky',
        // top: 0
    }}>
        <Box sx={{ mb:0 }}>
            <Typography component='h3' variant='titoloSezione2' color={myTheme.palette.primary.dark}>Cerca tra i nostri viaggi</Typography>
        </Box>
        <Box sx={{ mb:0 }}>
            <CustomFilters />
        </Box>
    </Card>
);

export const ViaggiListUtente = () => {
    const listContext = useListController();
    const permFilter = {
        stato: 1,
        data_inizio_ge: moment().format("YYYY-MM-DD")
    };

    if (!listContext.data) return null;
    return (
        <List
            resource="viaggio"
            filter={permFilter}
            // l'ordinamento per data non funziona, da verificare!
            sort={{ field: 'data_inizio', order: 'ASC' }}
            title="Viaggi"
            perPage={40}
            pagination={<Pagination rowsPerPageOptions={[12, 24, 36, 48]} sx={{ my: 3 }} />}
            exporter={false}
            actions={<ListActions />}
            empty={<EmptyPage testo='viaggi' />}
            sx={{ pt: { xs: 2 } }}
            disableSyncWithLocation
            emptyWhileLoading
        >
            <CardWrapper />
        </List>
    )
};