import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Essentials, Paragraph, Bold, Italic, Font, Link, List } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

export const RichTextEditor = ({ campo, valore, valoreDaCopia, update }) => {
    // https://ckeditor.com/docs/ckeditor5/latest/getting-started/installation/self-hosted/react/react-default-npm.html
    const { register } = useFormContext()
    const editorRef = useRef();

    useEffect(() => {
        register(campo)
        if (valore) {
            editorRef.current?.setData(valore)
        }
    }, [])

    useEffect(() => {
        if (valoreDaCopia && valoreDaCopia !== '') {
            editorRef.current?.setData(valoreDaCopia)
        }
    }, [valoreDaCopia])

    function handleChange() {
        // console.log(editorRef.current?.getData())
        update(campo, editorRef.current?.getData())
    }

    return (
        <CKEditor
            editor={ClassicEditor}
            onReady={(editor) => {
                editorRef.current = editor;
                editor.editing.view.change((writer) => {
                    writer.setStyle("height", "200px", editor.editing.view.document.getRoot());
                });
            }}
            onInit={(editor) => {
            }}
            config={{
                licenseKey: 'GPL',
                plugins: [Essentials, Paragraph, Bold, Italic, Font, Link, List],
                toolbar: ['undo', 'redo', '|', 'bold', 'italic', '|', 'fontSize', '|', 'bulletedList', 'numberedList',],
                initialData: valore ?? ''
                // placeholder: campo,
            }}
            onChange={() => handleChange()}
        />
    )
}