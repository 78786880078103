import React, { useEffect, useState, useRef } from "react";
import { useDataProvider, useNotify } from 'react-admin';
import { IconButton, CircularProgress, Button, Typography, Tooltip } from '@mui/material';
import moment from 'moment';
import { Page, Text, Image, View, Document, StyleSheet, usePDF, Font, pdf } from '@react-pdf/renderer';
import fileDownload from 'js-file-download';
import { PictureAsPdf } from '@mui/icons-material';
import { valutaFormat, isJson } from "../generale.js";
import statusOk from "./status_ok.png";
import statusKo from "./status_ko.png";
import statusHalf from "./status_half.png";
import { useGetViaggioPdf } from "../hooks/useGetViaggioPdf";

export const DocPDF = ({ viaggio: v, richieste: r, partecipanti, partecipanti_fermate: pf, pagamenti: p, richieste_invalidate_pagate: rip, versione }) => {
    var n_pag = 1;
    Font.register({ family: 'Open Sans', src: process.env.PUBLIC_URL + "/fonts/OpenSans-Medium.ttf" });
    Font.registerEmojiSource({ format: 'png', url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/' });
    // console.log(v);
    // console.log("rip", rip);
    // console.log("r", r);
    // console.log("partecipanti", partecipanti);
    // console.log("pf", pf);
    // console.log("p", p);
    const style = StyleSheet.create({
        intestazione: {
            backgroundColor: "#ccc",
            fontSize: 13,
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '5px',
            fontFamily: 'Open Sans',
        },
        testo: {
            fontSize: 6.5,
            textTransform: 'uppercase',
        },
        rigaBg: {
            backgroundColor: '#e3e3e3',
        },
        iconaStato: {
            width: '10px',
            height: '10px',
        },
        tabellaRichiesta: {
            display: 'flex',
            // display: '-webkit-flex',
            flexDirection: 'row',
            flexFlow: 'row wrap',
            paddingVertical: 2
        },
        spazioFermateP1: {
            borderTop: '1px dashed #444',
            marginVertical: '3px',
        },
        separatoreRiga: {
            borderTop: '1px dashed #bbb',
            marginVertical: '3px',
        },
        colonna1: {
            flex: 2,
            // gap: "5px",
            // alignItems: 'start',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRight: '1px solid #bbb',
            textAlign: 'left',
        },
        colonna2: {
            flex: 0.7,
            justifyContent: 'center',
            borderRight: '1px solid #bbb',
        },
        colonna3: {
            flex: 1.3,
            justifyContent: 'center',
            // alignItems: 'center',
            // borderRight: '1px solid #bbb',
        },
        colonna4Semplice: {
            justifyContent: 'center',
            flex: 5,
        },
        colonna4Camere: {
            justifyContent: 'center',
            flex: 5.3,
        },
        colonna5: {
            width: 9,
            border: '1px solid #bbb',
        },
        rigaRichiesta: {
            display: 'block',
        },
        riga: {
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'stretch',
            fontSize: 7.5,
            textTransform: 'uppercase',
            padding: '0px 3px',
            fontFamily: 'Open Sans'
        },
        rigaPagamento: {
            fontSize: 7.5,
            textTransform: 'uppercase',
            padding: '1px 3px',
            fontFamily: 'Open Sans',
            borderTop: '1px solid #ccc',
            // marginBottom: '3px'
        },
        rigaChild: {
            flex: 1,
            fontSize: 6.5,
            lineHeight: 1,
            // padding: '1px 0px 2px 0px',
            paddingBottom: '2px',
            borderBottom: '1px solid #bbb',
        },
        note: {
            fontSize: 6.5,
            lineHeight: 1,
            paddingHorizontal: '5px'
        },
        P2: {
            riga: {
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'stretch',
            },
            spazioFermate: {
                backgroundColor: '#000',
                marginTop: '8px',
                textAlign: 'center',
            },
            titoloFermata: {
                fontSize: 7,
                textTransform: 'uppercase',
                padding: '3px',
                fontWeight: 'bold',
                color: '#fff',
            },
            colonna1: {
                flex: 0.45,
                gap: "5px",
                borderRight: '1px solid #bbb',
            },
            colonna2: {
                flex: 0.55,
                justifyContent: 'center',
            },
        },
        P3: {
            colonna1: {
                flex: 0.3,
                gap: "5px",
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid #bbb',
                textAlign: 'center',
            },
            colonna2: {
                flex: 3,
                justifyContent: 'center',
                borderRight: '1px solid #bbb',
            },
            colonna3: {
                flex: 0.7,
                justifyContent: 'center',
                // alignItems: 'center',
                borderRight: '1px solid #bbb',
            },
            colonna4Semplice: {
                justifyContent: 'center',
                flex: 5,
            },
            colonna4Camere: {
                justifyContent: 'center',
                flex: 5.3,
            },
            colonna5: {
                width: 9,
                border: '1px solid #bbb',
            },
        },
    });
    if (!v) return null;
    const fermate = isJson(v.partenza) ? JSON.parse(v.partenza) : false
    const titolo = v.titolo + 
        (v.data_inizio !== null && v.data_inizio !== '' ? (v.data_inizio !== v.data_fine && v.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(v.data_inizio).format("DD/MM/YYYY")}` : ``) +
        (v.data_fine !== null && v.data_fine !== '' && v.data_fine !== '0000-00-00' ? (v.data_inizio !== v.data_fine && v.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(v.data_fine).format("DD/MM/YYYY")}` : ``) + `)`;

    const totCamere = {
        sin: 0,
        dop: 0,
        mat: 0,
        tri: 0,
        qua: 0,
    };
    if (r) {
        r.map((e) => {
            totCamere.sin += e.cam_singola ?? 0;
            totCamere.dop += e.cam_doppia ?? 0;
            totCamere.mat += e.cam_matrimoniale ?? 0;
            totCamere.tri += e.cam_tripla ?? 0;
            totCamere.qua += e.cam_quadrupla ?? 0;
            return null;
        });
    }

    if (!totCamere) return null;
    if (r.length === 0 && rip.length === 0 && pf.length === 0) return null;
    // console.log(totCamere);
    const totCamereTesto = (() => {
        let risultato = "";
        for (const k in totCamere) {
            if (totCamere[k] !== 0) {
                if (risultato !== "") {
                    risultato += ", ";
                }
                risultato += totCamere[k] + " " + k.toUpperCase();
            }
        }
        return risultato;
    })()
    const numSezioniDaStampare = {
        finanze: (r.length > 0 && p.length > 0 ? 1 : 0) + (rip.length > 0 ? 1 : 0),
        riepilogo: (pf.length > 0 ? 1 : 0) + (r.length > 0 ? 1 : 0)
    }

    return (
        <Document title={`${titolo} - ${versione}`} author="Virgilio Viaggia">
            {r.length > 0 && p.length > 0  && versione === "Finanze" ?
                <Page size="A4" orientation='portrait' style={{ padding: 20 }} key={n_pag++}>
                    <View style={style.tabellastampa}>
                        <View style={style.intestazione}>
                            <Text>V{v.id} - {titolo}</Text>
                            <Text>Quota: {valutaFormat.format(v.prezzo)} - {r.reduce((sum, arr) => {
                                return sum + (arr.n_adulti ?? 0) + (arr.n_bambini_1 ?? 0) + (arr.n_bambini_3 ?? 0) + (arr.n_bambini_4 ?? 0)
                            }, 0)} PAX - Tot. {valutaFormat.format(p.reduce((tot, pag) => tot + parseFloat(pag.importo), 0))}</Text>
                        </View>
                        {r.map((e, k) => {
                            return (
                                <View>
                                    <View style={[style.tabellaRichiesta, k % 2 === 0 ? null : style.rigaBg]} key={k}>
                                        <View style={style.colonna1}>
                                            <View style={style.riga}><Text>Cod. riferimento: R{e.id}</Text></View>
                                            <View style={style.riga}>
                                                {/* <Text style={{ backgroundColor: 'green', color: '#fff', fontWeight: 'bold', paddingHorizontal: '1px', borderRadius: 10, aspectRatio: 1, fontSize: 5, lineHeight: '1rem', textAlign: 'center' }}>IN</Text> */}
                                                <Text> {e.cognome} {e.nome} - C.F. {e.cod_fisc}</Text>
                                            </View>
                                            <View style={style.riga}><Text>Nascita: {e.data_nascita ? moment(e.data_nascita).format('DD/MM/YYYY') : ''}{e.comune_nascita ? ` a ${e.comune_nascita}` : ''}</Text></View>
                                            <View style={style.riga}><Text>Residenza: {e.indirizzo} - {e.comune}</Text></View>
                                        </View>
                                        <View style={style.colonna2}>
                                            <View style={style.riga}><Text>Tot. partecipanti: {(e.n_adulti ?? 0) + (e.n_bambini_1 ?? 0) + (e.n_bambini_3 ?? 0) + (e.n_bambini_4 ?? 0)}</Text></View>
                                            {(e.n_adulti ?? 0) > 0 ?
                                                <View style={style.riga}><Text>Adulti: {e.n_adulti ?? 0}</Text></View>
                                                : null}
                                            {(e.n_bambini_1 ?? 0) > 0 ?
                                                <View style={style.riga}><Text>Bambini 0-2: {e.n_bambini_1 ?? 0}</Text></View>
                                                : null}
                                            {(e.n_bambini_2 ?? 0) > 0 ?
                                                <View style={style.riga}><Text>{`Di cui sotto 1mt`}: {e.n_bambini_2 ?? 0}</Text></View>
                                                : null}
                                            {(e.n_bambini_3 ?? 0) > 0 ?
                                                <View style={style.riga}><Text>Bambini 3-13: {e.n_bambini_3 ?? 0}</Text></View>
                                                : null}
                                            {(e.n_bambini_4 ?? 0) > 0 ?
                                                <View style={style.riga}><Text>Ragazzi 14-17: {e.n_bambini_4 ?? 0}</Text></View>
                                                : null}
                                        </View>
                                        <View style={style.colonna3}>
                                            <View style={style.riga}><Text>Tot. pagato: {valutaFormat.format(p.filter(v => v.id_richiesta === e.id).reduce((tot, pag) => tot + parseFloat(pag.importo), 0))}</Text></View>
                                            {p.filter(v => v.id_richiesta === e.id).map(v => {
                                                return (
                                                    <View style={style.rigaPagamento}>
                                                        <Text>{v.metodo} in data {moment(v.creazione).format('DD/MM/YYYY')}</Text>
                                                        <Text>Importo: {valutaFormat.format(v.importo)}</Text>
                                                        {v.metodo === 'Bonifico' ?
                                                            <Text>Causale: R{e.id} {e.nominativo}</Text>
                                                            : null}
                                                        <Text>{v.pp_payment_id ? `Rif. ${v.pp_payment_id}` : ''}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    </View>
                                    {/* In caso di Agente Esterno OUT */}
                                    {/* <View style={{ backgroundImage: "repeating-linear-gradient(-45deg, #fff, #fff 10px, #e3e3e3 10px, #e3e3e3 20px)", backgroundSize: "20px 20px", width: '100%', height: '30px' }}>
                                        <View style={style.riga}>
                                            <Text>↪️</Text>
                                            <Text>aa</Text>
                                        </View>
                                    </View> */}
                                </View>
                            )
                        })}
                    </View>
                </Page>
                : null}
            {rip.length > 0  && versione === "Finanze" ?
                <Page size="A4" orientation='portrait' style={{ padding: 20 }} key={n_pag++}>
                    <View style={style.tabellastampa}>
                        <View style={style.intestazione}>
                            <Text>Gestione richieste invalidate ma pagate</Text>
                            <Text>V{v.id} - {titolo}</Text>
                            <Text>Richieste: {rip.length}</Text>
                        </View>
                        {rip.map((v, i) => {
                            return (
                                <>
                                    <View style={[style.tabellaRichiesta, i % 2 === 0 ? null : style.rigaBg]} key={i}>
                                        <View style={style.colonna1}>
                                            <View style={style.riga}><Text>Cod. riferimento: R{v.id}</Text></View>
                                            <View style={style.riga}><Text>{v.nominativo} - C.F. {v.cod_fisc}</Text></View>
                                            <View style={style.riga}><Text>Nascita: {v.data_nascita ? moment(v.data_nascita).format('DD/MM/YYYY') : ''}{v.comune_nascita ? ` a ${v.comune_nascita}` : ''}</Text></View>
                                            <View style={style.riga}><Text>Residenza: {v.indirizzo} - {v.comune}</Text></View>
                                        </View>
                                        <View style={style.colonna2}>
                                            <View style={style.riga}><Text>Tot. partecipanti: {(v.n_adulti ?? 0) + (v.n_bambini_1 ?? 0) + (v.n_bambini_3 ?? 0) + (v.n_bambini_4 ?? 0)}</Text></View>
                                            {(v.n_adulti ?? 0) > 0 ?
                                                <View style={style.riga}><Text>Adulti: {v.n_adulti ?? 0}</Text></View>
                                                : null}
                                            {(v.n_bambini_1 ?? 0) > 0 ?
                                                <View style={style.riga}><Text>Bambini 0-2: {v.n_bambini_1 ?? 0}</Text></View>
                                                : null}
                                            {(v.n_bambini_2 ?? 0) > 0 ?
                                                <View style={style.riga}><Text>{`Di cui sotto 1mt`}: {v.n_bambini_2 ?? 0}</Text></View>
                                                : null}
                                            {(v.n_bambini_3 ?? 0) > 0 ?
                                                <View style={style.riga}><Text>Bambini 3-13: {v.n_bambini_3 ?? 0}</Text></View>
                                                : null}
                                            {(v.n_bambini_4 ?? 0) > 0 ?
                                                <View style={style.riga}><Text>Ragazzi 14-17: {v.n_bambini_4 ?? 0}</Text></View>
                                                : null}
                                        </View>

                                        <View style={style.colonna3}>
                                            <View style={style.riga}><Text>Tot. pagato: {valutaFormat.format((v.pagamenti).reduce((tot, pag) => tot + parseFloat(pag.importo), 0))}</Text></View>
                                            {(v.pagamenti).map(p => {
                                                return (
                                                    <View style={style.rigaPagamento}>
                                                        <Text>{p.metodo} in data {moment(p.creazione).format('DD/MM/YYYY')}</Text>
                                                        <Text>Importo: {valutaFormat.format(p.importo)}</Text>
                                                        {p.metodo === 'Bonifico' ?
                                                            <Text>Causale: R{v.id} {v.nominativo}</Text>
                                                            : null}
                                                        <Text>{p.pp_payment_id ? `Rif. ${p.pp_payment_id}` : ''}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    </View >
                                </>
                            )
                        })}
                    </View>
                </Page>
                : null}
            {pf.length > 0  && versione === "Riepilogo" ?
                <Page size="A4" orientation='portrait' style={{ padding: 20 }} key={n_pag++}>
                    <View style={style.tabellastampa}>
                        <View style={style.intestazione}>
                            <Text>V{v.id} - {titolo}</Text>
                        </View>

                        {fermate ?
                            // Se il campo "partenza" è un oggetto JSON (nuova configurazione)
                            fermate.map((f,k1) => {
                                const listaFermate = pf.filter(v => v.id_fermata === f.id)
                                if (listaFermate.length > 0) {
                                    const header = <View style={style.P2.spazioFermate}><Text style={style.P2.titoloFermata}>Ore {f.orario} - {listaFermate[0].fermata} - {listaFermate[0].n_partecipanti_fermata} {listaFermate[0].n_partecipanti_fermata > 1 ? "persone" : "persona"}</Text></View>
                                    const body = listaFermate.map((e,k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                {(k > 0 && k < listaFermate.length ? <View style={style.separatoreRiga} /> : null)}
                                                <View style={style.tabellaRichiesta} key={k}>
                                                    <View style={style.P2.colonna1}>
                                                        <View style={style.riga}><Text>Ref. {e.nominativo} (Cod. R{e.id}) x {e.n_partecipanti} {e.n_partecipanti > 1 ? "persone" : "persona"}</Text></View>
                                                    </View>
                                                    <View style={style.P2.colonna2}>
                                                        <View style={style.riga}>
                                                            <Text>{(e.lista_partecipanti).split(",").map(v => `${v}\n`)}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </React.Fragment>
                                        )
                                    })
                                    return <>{header}{body}</>
                                } else return null
                            })
                        :
                            // Se il campo "partenza" non è un oggetto JSON (vecchia configurazione)
                            pf.map((e, k) => {
                                return (
                                    <React.Fragment key={k}>
                                        {(k === 0 ? <View style={style.P2.spazioFermate} key={`pre_${k}`}><Text style={style.P2.titoloFermata}>{e.fermata} - {e.n_partecipanti_fermata} {e.n_partecipanti_fermata > 1 ? "persone" : "persona"}</Text></View> : null)}
                                        {(k > 0 && e.fermata !== pf[k - 1].fermata ? <View style={style.P2.spazioFermate} key={`pre_${k}`}><Text style={style.P2.titoloFermata}>{e.fermata} - {e.n_partecipanti_fermata} {e.n_partecipanti_fermata > 1 ? "persone" : "persona"}</Text></View> : null)}
                                        {(k > 0 && e.fermata === pf[k - 1].fermata ? <View style={style.separatoreRiga} /> : null)}
                                        <View style={style.tabellaRichiesta} key={k}>
                                            <View style={style.P2.colonna1}>
                                                <View style={style.riga}><Text>Ref. {e.nominativo} (Cod. R{e.id}) x {e.n_partecipanti} {e.n_partecipanti > 1 ? "persone" : "persona"}</Text></View>
                                            </View>
                                            <View style={style.P2.colonna2}>
                                                <View style={style.riga}>
                                                    <Text>{(e.lista_partecipanti).split(",").map(v => `${v}\n`)}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </React.Fragment>
                                )
                            })
                        }
                        
                    </View>
                </Page>
                : null}
            {r.length > 0  && versione === "Riepilogo" ?
                <Page size="A4" orientation='portrait' style={{ padding: 20 }} key={n_pag++}>
                    <View style={style.tabellastampa}>
                        <View style={style.intestazione}>
                            <Text>V{v.id} - {titolo}</Text>
                            <Text>Partecipanti: {r.reduce((sum, arr) => {
                                return sum + (arr.n_adulti ?? 0) + (arr.n_bambini_1 ?? 0) + (arr.n_bambini_3 ?? 0) + (arr.n_bambini_4 ?? 0)
                            }, 0)}
                                {/* {(v.specifica_tipo === 2 ?
                                    " - Camere:" +
                                    (totCamere.sin ? ` ${totCamere.sin} SIN,` : '') +
                                    (totCamere.dop ? ` ${totCamere.dop} DOP,` : '') +
                                    (totCamere.mat ? ` ${totCamere.mat} MAT,` : '') +
                                    (totCamere.tri ? ` ${totCamere.tri} TRI,` : '') +
                                    (totCamere.qua ? ` ${totCamere.qua} QUA,` : '')
                                    : null)} */}
                                    {v.specifica_tipo === 2 && totCamereTesto ?
                                    ` - Camere: ${totCamereTesto}`
                                    : ''}
                            </Text>
                        </View>
                        {r.map((e, k) => {
                            const iconaDati = () => {
                                switch (e.id_stato_dati) {
                                    case 1:
                                        return statusHalf;
                                    case 3:
                                        return statusKo;
                                    default:
                                        return statusOk;
                                }
                            }
                            const iconaPagamenti = () => {
                                switch (e.id_stato_pagamenti) {
                                    case 1:
                                        return statusKo;
                                    case 3:
                                        return statusHalf;
                                    default:
                                        return statusOk;
                                }
                            }
                            const camere = [
                                (e.cam_singola ? e.cam_singola + ' SIN' : null),
                                (e.cam_doppia ? e.cam_doppia + ' DOP' : null),
                                (e.cam_matrimoniale ? e.cam_matrimoniale + ' MAT' : null),
                                (e.cam_tripla ? e.cam_tripla + ' TRI' : null),
                                (e.cam_quadrupla ? e.cam_quadrupla + ' QUA' : null),
                            ];
                            const n_partecipanti = (e.n_adulti ?? 0) + (e.n_bambini_1 ?? 0) + (e.n_bambini_3 ?? 0) + (e.n_bambini_4 ?? 0);
                            return (
                                <View style={[style.tabellaRichiesta, k % 2 === 0 ? null : style.rigaBg]} key={k}>
                                    <View style={style.P3.colonna1}>
                                        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <View style={style.testo}><Image src={iconaDati} style={style.iconaStato} /></View>
                                            <View style={[style.testo, { paddingBottom: 0 }]}><Text>D</Text></View>
                                            {/* </View>
                                    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}> */}
                                            <View style={style.testo}><Image src={iconaPagamenti} style={style.iconaStato} /></View>
                                            <View style={[style.testo, { paddingBottom: 0, textAlign: 'center' }]}><Text>P</Text></View>
                                        </View>
                                    </View>
                                    <View style={style.P3.colonna2}>
                                        {/* <View style={style.riga}><Text>Fermata: {e.fermata}</Text></View> */}
                                        <View style={style.riga}><Text>Ref. {e.cognome} {e.nome} x {n_partecipanti} (Cod. R{e.id})</Text></View>
                                        <View style={style.riga}><Text>Tel. {e.telefono} - C.F. {e.cod_fisc}</Text></View>
                                        <View style={style.riga}><Text>Residenza: {e.indirizzo} - {e.comune}</Text></View>
                                        <View style={style.riga}><Text>Nascita: {moment(e.data_nascita).format('DD/MM/YYYY')} a {e.comune_nascita}</Text></View>
                                        {e.note_pvt ?
                                            <View style={{ marginTop: 10 }}>
                                                <Text style={style.note}>Note private: {e.note_pvt}</Text>
                                            </View>
                                            : null}
                                    </View>
                                    {camere.filter(n => n).length > 0 ?
                                        <View style={style.P3.colonna3}>
                                            <View style={[style.riga, { flexDirection: 'column' }]}><Text>Camere:</Text>
                                                {camere.filter(n => n).map((v, i, row) => <Text key={`camere_${i}`}>{v}</Text>)}
                                            </View>
                                        </View>
                                        : null}
                                    <View style={(v.specifica_tipo === 2 ? style.P3.colonna4Camere : style.P3.colonna4Semplice)}>
                                        {partecipanti.filter((p) => p.id_richiesta === e.id).map((p, i) => {
                                            let tipoP;
                                            switch (p.tipo) {
                                                case 'adulti':
                                                    tipoP = 'Adulto';
                                                    break;
                                                case 'bambini_1':
                                                    tipoP = '0-2 anni';
                                                    break;
                                                case 'bambini_2':
                                                    tipoP = 'Sotto 1 mt';
                                                    break;
                                                case 'bambini_3':
                                                    tipoP = '3-13 anni';
                                                    break;
                                                case 'bambini_4':
                                                    tipoP = '14-17 anni';
                                                    break;
                                                default:
                                                    break;
                                            }

                                            var alloggio = null;
                                            if (p.alloggio) {
                                                const a = (p.alloggio).split('_');
                                                switch (a[1]) {
                                                    case 'sin':
                                                        alloggio = 'Singola n.' + a[2]
                                                        break;
                                                    case 'dop':
                                                        alloggio = 'Doppia n.' + a[2]
                                                        break;
                                                    case 'mat':
                                                        alloggio = 'Matrimoniale n.' + a[2]
                                                        break;
                                                    case 'tri':
                                                        alloggio = 'Tripla n.' + a[2]
                                                        break;
                                                    case 'qua':
                                                        alloggio = 'Quadrupla n.' + a[2]
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }

                                            return (
                                                <View style={style.riga} key={`partecipante_${i}`}>
                                                    <Text style={style.rigaChild}>{p.nominativo ?? 'N.D.'} ({tipoP} {p.data_nascita ? `- ${moment(p.data_nascita).format("DD/MM/YYYY")}` : null})
                                                        {/* {(p.telefono ? ` - T. ${p.telefono}` : null)} */}
                                                        {alloggio ? ` - ${alloggio}` : null}</Text>
                                                    <View style={style.colonna5}></View>
                                                </View>
                                            )
                                        })}
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                </Page>
                : null}
            {(versione === "Finanze" && numSezioniDaStampare.finanze === 0) || (versione === "Riepilogo" && numSezioniDaStampare.riepilogo === 0) ?
                <Page size="A4" orientation='portrait' style={{ padding: 20 }} key={n_pag++}>
                    <View style={style.tabellastampa}>
                        <View style={style.intestazione}>
                            <Text>Nessun dato disponibile per la stampa</Text>
                        </View>
                    </View>
                </Page>
            : null}
        </Document>
    )
}

export function TastoGeneraPdf({ viaggio, stile, versione, ...props }) {
    const notify = useNotify();
    const [idViaggio, setIdViaggio] = useState(null)
    const [fileBlob, setFileBlob] = useState(null)
    const [isGenerating, setIsGenerating] = useState(false)
    const linkRef = useRef(null);
    const nomeFile = `V${viaggio.id}_${(viaggio.titolo).replace(/[^a-zA-Z0-9]/g, "_").toLowerCase()}_${moment(viaggio.data_inizio).format("DD_MM_YYYY")} - ${versione}.pdf`;
    const [getData, { data, isLoading, error }] = useGetViaggioPdf({ idViaggio: idViaggio })

    function handleClick() {
        setFileBlob(null)
        setIsGenerating(true)
        setIdViaggio(viaggio.id)
    }

    useEffect(() => {
        if (!error && !isLoading && data && isGenerating) {
            // console.log(data);
            // console.log("Genero il PDF");
            const blob = pdf(
                <DocPDF
                    viaggio={viaggio}
                    richieste={data.r}
                    partecipanti={data.rdp}
                    partecipanti_fermate={data.rdpf}
                    pagamenti={data.rp}
                    richieste_invalidate_pagate={data.riep}
                    versione={versione}
                />
            )
                .toBlob()
                .then((blob) => {
                    // console.log(blob);
                    var reader = new FileReader();
                    reader.onload = function (fileLoadedEvent) {
                        const file = fileLoadedEvent.target.result;
                        // console.log(file);
                        setFileBlob(file)
                        setIsGenerating(false)
                    };
                    // console.log(b);
                    reader.readAsDataURL(blob);
                })
                .catch(e => setIsGenerating(false))
        } else if (error) {
            notify('Si è verificato un errore e non è stato possibile generare il PDF', { type: 'error' })
        }
    }, [data, isLoading, error, isGenerating])

    useEffect(() => {
        if (fileBlob) {
            linkRef.current?.click()
            setTimeout(() => {
                setFileBlob(null)
            }, 200);
        }
    }, [fileBlob])

    return (
        <>
            {/* <p>{nomeFile}</p> */}
            {isGenerating ?
                <CircularProgress size={20} />
                :
                <Tooltip title={`Stampa PDF ${versione}`}>
                    {stile === 'esteso' ?
                        <Button {...props} onClick={() => handleClick()} startIcon={<PictureAsPdf />}><Typography variant="p" ml={1}>{versione}</Typography></Button>
                        :
                        <IconButton {...props} variant="iconaLista" color="secondary" onClick={() => handleClick()}><PictureAsPdf /></IconButton>}
                </Tooltip>
            }
            <a ref={linkRef} href={fileBlob} download={nomeFile} style={{ display: 'none' }}>Download</a>
        </>
    )
}