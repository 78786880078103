import { useEffect, useState } from "react";
import { useGetList, TextInput } from "react-admin";
import { Alert } from "@mui/material";
import { LoadingComponent } from "../LoadingComponent";
import { CheckTreePicker } from 'rsuite';
// import 'rsuite/dist/rsuite.min.css';
import 'rsuite/CheckTreePicker/styles/index.css';
import './rsuite-override.css';

// https://rsuitejs.com/components/check-tree-picker/

export const CategorieViaggioFiltro = ({ update, filtri }) => {
    const [alberoCategorie, setAlberoCategorie] = useState([]);
    const [vociAttive, setVociAttive] = useState([]);
    // const [altreVociSelezionabili, setAltreVociSelezionabili] = useState(true);
    const { data: categorie, isLoading, error } = useGetList(
        'tipo_viaggio',
        {
            pagination: { page: 1, perPage: 99 },
            sort: { field: 'id', order: 'ASC' },
            filter: {
                n_attivi_gt: 0
            }
        }
    );
    const { data: viaggiCategorie } = useGetList(
        'viaggio_categoria_assegnazione',
        {
            pagination: { page: 1, perPage: 999 },
            filter: (vociAttive.length > 0 ?
                { id_categoria_in: vociAttive }
                :
                { id_gt: 0 }
            )
        }
    );
    const { data: viaggi } = useGetList(
        'viaggio',
        {
            pagination: { page: 1, perPage: 999 },
            filter: (viaggiCategorie?.length > 0 ?
                { id_in: viaggiCategorie.map(v => v.id_viaggio) }
                :
                { id_gt: 0 }
            )
        }, {
        enabled: viaggiCategorie?.length > 0 ? true : false
    }
    );

    useEffect(() => {
        setVociAttive([])
    }, [])

    // useEffect(() => {
    //     console.log("vociAttive", vociAttive);
    //     if (vociAttive === 3) {
    //         setAltreVociSelezionabili(false)
    //     }
    // }, [vociAttive])

    // useEffect(() => {
    //     console.log("viaggiCategorie", viaggiCategorie);
    // }, [viaggiCategorie])

    useEffect(() => {
        const idFiltrati = viaggi?.map(v => v.id);
        // console.log("viaggi", idFiltrati);
        // update({ id_in: idFiltrati }, undefined, false);
        update({ ...filtri, id_in: idFiltrati !== undefined ? idFiltrati : [0] }, undefined, false);
    }, [viaggi])

    function handleChange(e) {
        // console.log(e.check, e.id);
        if (e.check === true) {
            setVociAttive([...vociAttive, e.id])
        } else {
            setVociAttive(vociAttive.filter(v => v !== e.id))
        }
    }

    function handleClean() {
        setVociAttive([])
    }

    useEffect(() => {
        if (categorie) {
            const categoriePrincipali = categorie.filter(v => !v.id_categoria_genitore)
            const tempArray = categoriePrincipali.map(v => {
                const subCats = categorie.filter(c => c.id_categoria_genitore === v.id)
                return subCats.length > 0 ? { ...v, sottocategorie: subCats } : v
            })
            setAlberoCategorie(tempArray)
        }
    }, [categorie])

    if (isLoading || alberoCategorie.length === 0 || vociAttive === null) { return <LoadingComponent /> }
    if (error) { return <Alert severity="error">Impossibile caricare le categorie</Alert> }
    return (
        <CheckTreePicker
            defaultExpandAll
            cascade={false}
            searchable={false}
            data={alberoCategorie}
            valueKey='id'
            labelKey='nome'
            childrenKey='sottocategorie'
            onSelect={e => handleChange(e)}
            onClean={handleClean}
            placeholder='Seleziona categoria/e'
            style={{
                height: '100%',
                width: '100%',
                maxWidth: '100%'
            }}
            data-type='filtro-categorie'
            // onSelect={e => console.log('onSelect', e)}
            // onChange={e => console.log('onChange', e)}
        />
    )
}