import { useEffect, useState } from 'react';
import { useNotify, FileInput, FileField, useEditContext, useGetList, useRefresh, Form, usePermissions } from 'react-admin';
import { Grid, AlertTitle, Alert, Typography, Button, Box, IconButton } from '@mui/material';
import { useParams } from "react-router-dom";
import { LoadingComponent } from '../LoadingComponent';
import { apiFullUrl, apiUsersFilesUrl, admB64, logger, HeaderSezione } from '../generale';
import { CloudUpload, FileUpload, Close, FilePresent } from '@mui/icons-material';
import axios from 'axios';
import { encode as base64_encode } from 'base-64';
import FsLightbox from "fslightbox-react";

export const UploadedFiles = ({ nFiles, id_richiesta }) => {
    const { permissions } = usePermissions();
    const { record: richiesta, isLoadingRecordData } = useEditContext();
    const { data: docsCaricati, isLoading, error } = useGetList(
        "uploadfile/" + id_richiesta,
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
        },
    );
    const [toggler, setToggler] = useState(true);

    // React.useEffect(() => {
    // }, [nFiles])

    if (isLoading || isLoadingRecordData) { return <LoadingComponent />; }
    if (error) { return <p>ERROR</p>; }

    if (docsCaricati.length > 0)
        return (
            <>
                <Typography variant="p" sx={{ mb: 2 }}>
                    {(docsCaricati.length > 1 ? `Ad oggi sono stati forniti ${docsCaricati.length} documenti.` : `Ad oggi è stato fornito 1 documento.`)}
                    {permissions !== base64_encode(admB64) ? 
                    ' Nel caso non fossero aggiornati o risultino incompleti puoi sempre aggiungerne di nuovi.'
                    : null }
                </Typography>
                <Button variant='contained' onClick={() => setToggler(!toggler)}>Visualizza anteprime</Button>
                <FsLightbox
                    toggler={toggler}
                    type="image"
                    sources={docsCaricati.map((v,i) => {
                        if ((v.nome).includes('.pdf'))
                        return <iframe title={v.nome} src={`${apiUsersFilesUrl}${v.nome}`} style={{ width: "80vw", height: "80vh" }} />
                        else
                        return apiUsersFilesUrl + v.nome
                    })}
                />
                {/* <ul style={{paddingLeft: 20, columnCount: 2}}>
                    {docsCaricati.map((richiesta, i) => {
                        return <li key={richiesta.id}><a href={apiUsersFilesUrl + richiesta.nome} target="_blank">Allegato {i + 1}</a></li>
                    }
                    )}
                </ul> */}
            </>
        )
    else if (
        docsCaricati.length === 0 
        // && nFiles === 0
    )
        return (
            <Alert severity="warning" sx={{ mt: 0, py: 1 }} icon={false}>
                {/* <Typography component="p" variant="p" sx={{ mb:2}}>Usa questa sezione per inviarci copia della ricevuta di pagamento (se hai deciso di pagare tramite bonifico) o il modulo apposito in caso di presenza di minorenni.</Typography> */}
                <Typography component="p" variant="p" sx={{my:0}}>Al momento non sono stati caricati documenti.</Typography>
            </Alert>
        )
}

export const GestioneDocumenti = ({id_richiesta, update, id_utente_richiesta, titolo}) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const idUtente = localStorage.getItem('auth_uid');
    const [arrayFile, setArrayFile] = useState([]);
    const [nFileDaCaricare, setNFileDaCaricare] = useState(0);
    const [abilitaTasto, setAbilitaTasto] = useState(false);

    useEffect(() => {
        // console.log(arrayFile, arrayFile.length);
        setNFileDaCaricare(arrayFile.length);
        if (arrayFile.length > 0)
        setAbilitaTasto(true)
        else
        setAbilitaTasto(false)
    }, [arrayFile]);

    function handleUpload() {
        // console.log(arrayFile);
        notify('Caricamento in corso', { type: 'info' });

        // Da cambiare con "uploadfile2" per nuova versione
        axios.post(apiFullUrl + '/uploadfile/' + id_richiesta, arrayFile, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Custom-Data': JSON.stringify({idUtente: idUtente, id_utente_richiesta: id_utente_richiesta }),
                // 'Custom-Data': idUtente,
                'X-API-Key': localStorage.getItem('auth_api_key')
            }
        })
        .then(response => {
            // console.log(response.data.message);
            if (response.data.message === "OK") {
                setArrayFile([]);
                notify('Documenti caricati', { type: 'success' });
                refresh();
                update(false);
                // Inserisce il log della modifica richiesta
                logger(9, '', null, null, id_richiesta, idUtente);
            } else {
                notify('Errore in fase di caricamento', { type: 'error' });
            }
        })
        .catch(() => {
            notify('Errore in fase di caricamento', { type: 'error' });
        });
    }

    return (
        <Box>
            <HeaderSezione icona={null} titolo={titolo} />
            <Box sx={{ overflowY: "hidden !important", p:2 }}>
                <IconButton
                    aria-label="toggle visibility"
                    onClick={() => update(false)}
                    edge="end"
                    size="small"
                    variant="contained"
                    sx={{ position: 'absolute', right: 10, top: 10, outline: "0 !important", borderWidth: 1, borderStyle: 'solid' }}
                >
                    <Close />
                </IconButton>
                <Form onSubmit={handleUpload} sx={{ p: 0 }} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <FileInput
                                source="allegati"
                                accept="image/*,application/pdf"
                                placeholder={
                                    <>
                                        <IconButton variant="contained" size='large' disabled={false} color='primary' sx={{ borderWidth: 1, borderStyle: 'solid', mb: 3 }}><CloudUpload sx={{ fontSize: 50}} /></IconButton>
                                        {(nFileDaCaricare > 0 ?
                                            // <div>Aggiungi altri file o procedi al caricamento di {nFileDaCaricare} file premi il tasto "Carica"</div>
                                            null
                                            :
                                            <div>Caricare file nei formati JPG/PNG/PDF (max 4 Mb cad.)</div>
                                        )}
                                    </>
                                }
                                maxSize={4000000}
                                multiple
                                parse={v => {
                                    setArrayFile([]);
                                    const file = v.map(o => {
                                        // console.log(typeof o, o);
                                        if (typeof o === 'object' && o.rawFile) {
                                            setArrayFile(current => [...current, o.rawFile]);
                                            return o.rawFile;
                                        } else {
                                            setArrayFile(current => [...current, o]);
                                            return o;
                                        }
                                    });
                                    return file;
                                }}
                                // onChange={(e) => {
                                    // console.log(e);
                                    // setArrayFile(e);
                                    // setArrayFile([]);
                                // }}
                            >
                                <FileField source="src" title="title" sx={{ mb: { xs: 3, lg: 2 } }} />
                            </FileInput>
                            <Box mt={3}>
                                {nFileDaCaricare > 0 ?
                                <Alert severity="info" sx={{ mb: 2 }} icon={false}>
                                    <Typography component="h3" variant="p">Aggiungi altri file o procedi all'invio di {nFileDaCaricare} file con il tasto sotto</Typography>
                                </Alert>
                                :null}
                                <Button variant='contained' size='large' type='submit' endIcon={<FileUpload />} disabled={!abilitaTasto} color='success' sx={{ width: '100%' }}>Invia</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            </Box>
        </Box>
    )
};