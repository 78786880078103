import { useEffect, useState } from 'react';
// import { useForm, useFormContext } from "react-hook-form";
import { SelectInput } from "react-admin";
import { Grid, TextField, Button, IconButton, FormGroup, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { Add, DepartureBoard, DragHandle, DeleteForever } from "@mui/icons-material";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    MeasuringStrategy,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    useSortable,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useGetFermate } from "../hooks/useGetFermate";
import { HeaderSezione } from '../generale';

const SingolaFermataOrdinabile = ({ onChangeOrario, onRemove, onChangeFacoltativa, ...props }) => {    

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        height: "auto",
        // backgroundColor: 'red',
        // color: 'green',
        transform: CSS.Translate.toString(transform),
        transition
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes}>
            <Grid container gap={1} justifyContent='center' mb={1}>
                <Grid item>
                    <IconButton aria-label="delete" sx={{ mr: 1 }} onClick={() => onRemove(props.data.id)}>
                        <DragHandle {...listeners} />
                    </IconButton>
                </Grid>
                <Grid item display='flex' alignItems='center'>
                    <TextField
                        variant="outlined"
                        className='customFermata'
                        type='text'
                        value={props.data.localita}
                        InputProps={{ readOnly: true }}
                        inputProps={{
                            style: { cursor: 'not-allowed' }
                        }}
                        sx={{
                            '&.MuiFormControl-root': { my: "0 !important" }
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        className='customFermata'
                        type='time'
                        value={props.data.orario}
                        onChange={(e) => onChangeOrario(props.data.id, e.target.value)}
                        sx={{
                            '&.MuiFormControl-root': { my: "0 !important" }
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={props.data.facoltativa}
                                    onChange={(e) => onChangeFacoltativa(props.data.id, e.target.checked)}
                                    // inputRef={refCheckFermate}
                                />
                            }
                            label="Facoltativa"
                            sx={{ "& .MuiTypography-root" : { fontSize: 12 } }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item>
                    <IconButton aria-label="delete" sx={{ ml: 1 }} onClick={() => onRemove(props.data.id)}>
                        <DeleteForever />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}

export const EditFermate = ({ config, updateLista }) => {
    const [getData, { listaFermate, isLoadingListaFermate, errorListaFermate }] = useGetFermate()
    const [items, setItems] = useState([])
    const [listaNuoveFermate, setListaNuoveFermate] = useState([])
    const [idNuovaFermata, setIdNuovaFermata] = useState(null)
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const measuringConfig = {
        droppable: {
            strategy: MeasuringStrategy.Always
        }
    };

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id && over) {
            const oldIndex = items.findIndex(item => item.id === active.id);
            const newIndex = items.findIndex(item => item.id === over.id);
            let newItems = arrayMove(items, oldIndex, newIndex);
            newItems = newItems.map((item, index) => ({
                ...item,
                ordine: (index + 1)
            }));
            setItems(newItems);
        }
    }

    function handleOrarioChange(id, newOrario) {
        setItems(prevItems =>
            prevItems.map(item => (item.id === id ? { ...item, orario: newOrario } : item))
        );
    }

    function handleFacoltativaChange(id, facoltativaVal) {
        setItems(prevItems =>
            prevItems.map(item => (item.id === id ? { ...item, facoltativa: facoltativaVal } : item))
        );
    }

    function handleAddItem() {
        const itemFermata = listaNuoveFermate.find(v => v.id === idNuovaFermata)
        delete itemFermata.dettagli
        delete itemFermata.gps
        itemFermata.ordine = (items.length + 1)
        setItems([
            ...items,
            itemFermata
        ])
        setIdNuovaFermata(null)
    }

    function handleRemoveItem(id) {
        const x = items.filter(v => v.id !== id)
        setItems(x)
    }

    useEffect(() => {
        const itemsTmp = config.map(v => {
            return {
                ...v,
                facoltativa: v.facoltativa === undefined ? false : ([true, 'true'].includes(v.facoltativa) ? true : false)
            }
        })
        setItems(itemsTmp)
    }, [])

    useEffect(() => {
        // console.log(items);
        if (items.length > 0)
        updateLista(items);
    }, [items])

    useEffect(() => {
        // console.log(items);
        if (listaFermate.length > 0 && items.length > 0) {
            const x = listaFermate.filter(function (objFromA) {
                return !items.find(function (objFromB) {
                    return objFromA.id === objFromB.id
                })
            })
            setListaNuoveFermate(x)
        } else if (listaFermate.length > 0 && items.length === 0) {
            setListaNuoveFermate(listaFermate)
        }
    }, [listaFermate, items])

    return (
        <Grid container display='flex' flexDirection='column'>
            <Grid item>
                <HeaderSezione icona={DepartureBoard} titolo="Lista fermate" dettaglio="Riordina o modifica gli orari" />
            </Grid>
            <Grid item>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    measuring={measuringConfig}
                >
                    <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                    >
                        {items.map(v => <SingolaFermataOrdinabile key={v.id} id={v.id} data={v} onChangeOrario={handleOrarioChange} onChangeFacoltativa={handleFacoltativaChange} onRemove={handleRemoveItem} />)}
                    </SortableContext>
                </DndContext>
            </Grid>
            <Grid item pt={3} display='flex' flexDirection='column' justifyContent='center'>
                <HeaderSezione icona={DepartureBoard} titolo="Aggiungi fermata" dettaglio="Aggiungi una nuova fermata" />
                <SelectInput
                    source="__fermata"
                    label="Nuova fermata"
                    placeholder="Seleziona una nuova fermata"
                    // fullWidth
                    optionText="localita"
                    optionValue="id"
                    choices={listaNuoveFermate}
                    onChange={(e) => setIdNuovaFermata(e.target.value)}
                    sx={{ mt: 1 }}
                />

                {idNuovaFermata ?
                    <Button startIcon={<Add />}
                        onClick={handleAddItem}
                    />
                    : null}
            </Grid>
        </Grid>
    );
}