import { useState, useEffect } from "react";
import { SelectInput, required } from "react-admin";
import { Grid, Box, IconButton, Dialog, DialogContent, Tooltip } from "@mui/material";
import { Assignment, ModeEdit, TimerOff, Warning } from "@mui/icons-material";
import { useGetPresetFermate } from "../hooks/useGetPresetFermate";
import { EditFermate } from "./FermateEdit";
import { InfoFermate } from "./FermateInfo";
import { HeaderSezione, isJson } from '../generale';
import { useFormContext } from "react-hook-form";

export const Fermate = () => {
    const [dettaglioFermate, setDettaglioFermate] = useState(null)
    const [modalInfo, setModalInfo] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { presetFermate, loading, error } = useGetPresetFermate()
    const { control, setValue, watch } = useFormContext();
    function handleChange(e) {
        setDettaglioFermate(presetFermate.find(pf => pf.id === e).configurazione)
    }
    const partenzaWatch = watch('partenza')

    useEffect(() => {
        // console.log(partenzaWatch);
        if (
            partenzaWatch !== undefined
            && partenzaWatch !== JSON.stringify(dettaglioFermate)
            && isJson(partenzaWatch)
        )
            setDettaglioFermate(JSON.parse(partenzaWatch))
    }, [partenzaWatch])

    useEffect(() => {
        // console.log(dettaglioFermate);
        setValue('partenza', JSON.stringify(dettaglioFermate));
    }, [dettaglioFermate])

    if (loading) return <p>Loading</p>
    if (error) return <p>errorListaFermate</p>

    return (
        <>
            <Grid container display='flex' flexDirection='row' justifyContent='center' width='100%'>
                {dettaglioFermate ?
                <Grid item xs={3} sm={2} md={3} lg={3} xl={2} display='flex' justifyContent='center' alignItems='center'>
                    <Box sx={{ mt: '-32px' }}>
                        <InfoFermate dettaglioFermate={dettaglioFermate} />
                        <IconButton variant="iconaLista" color="secondary" onClick={() => setModalEdit(true)}><ModeEdit /></IconButton>
                    </Box>
                </Grid>
                : null}
                <Grid item xs sm md lg xl>
                    <SelectInput
                        source="preset_fermate"
                        label="Seleziona il preset delle fermate"
                        fullWidth
                        optionText="nome"
                        optionValue="id"
                        choices={presetFermate}
                        onChange={(e) => handleChange(e.target.value)}
                        // validate={[required()]}
                    />
                </Grid>
            </Grid>

            {/* Modal Edit */}
            <Dialog open={modalEdit} onClose={() => setModalEdit(false)} maxWidth='xl'>
                <DialogContent>
                    <EditFermate config={dettaglioFermate} updateLista={setDettaglioFermate} />
                </DialogContent>
            </Dialog>
        </>
    )
}