import { useEffect, useState } from "react";
import { Edit, EditBase, SimpleForm, useList, ListBase, Datagrid, EditButton, DateInput, ListContextProvider, ResourceContextProvider, WithRecord, TextField, ReferenceField, ReferenceManyField, FunctionField, useGetList, usePermissions, useEditContext, useListContext, TextInput, ReferenceInput, AutocompleteInput, SimpleList, SelectInput, useRecordContext, Toolbar, SaveButton, required, maxValue, number, useNotify, useGetOne, useUpdate, useCreate, useEditController, EditContextProvider, useDelete, useChoicesContext, useRefresh, BooleanInput } from 'react-admin';
import { Grid, Typography, Card, AlertTitle, Alert, useMediaQuery, Button, IconButton, Box, Dialog, DialogContent } from '@mui/material';
import { PermIdentity, LockOpen, ModeEdit, AddCircleOutline, WarningAmber, Close, SettingsBackupRestore, BusAlert } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { LoadingComponent } from '../LoadingComponent';
import { HeaderSezione, apiFullUrl, logger, AlertFermata, admB64, AlertModuloMinorenni, myTheme } from '../generale';
import axios from 'axios';
import moment from 'moment';
import { encode as base64_encode } from 'base-64';
import { ListaFermateUtente } from "./ListaFermateUtente";

export const ListaPartecipanti = ({ specifica_tipo, aggiornamento, editabile, dettagliViaggio }) => {
    const { id: idRichiesta } = useParams();
    // console.log(idRichiesta);
    const [statoModalPartecipanti, setStatoModalPartecipanti] = useState(false);
    const [statoModifichePartecipanti, setStatoModifichePartecipanti] = useState(false);
    const [idPartecipante, setIdPartecipante] = useState(null);
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const permFilter = {
        id_richiesta: idRichiesta,
        stato: 1
    };
    const nominativiRiassegnabili = ListaRiassegnabili(idRichiesta);
    const [update, { isLoading, error }] = useUpdate();
    const stileRiga = (record, index) => ({
        backgroundColor: !record.nominativo || record.nominativo === '' ? `#fff4e5` : 'transparent',
    });

    function handleClickPartecipante(id) {
        setIdPartecipante(id);
        setStatoModalPartecipanti(true);
    }

    const GrandiRes = () => {
        const { data, total } = useListContext();
        const { permissions } = usePermissions();
        if (!data || !total) return null;

        return (
            <Datagrid bulkActionButtons={false}
                sx={{
                    flexDirection: 'column',
                    ' & td': {
                        padding: '4px',
                    },
                    ' & td:last-child': {
                        xs: {
                            maxWidth: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ' & a': {
                                minWidth: 'unset',
                                ' & span': {
                                    margin: 0
                                }
                            }
                        },
                        sm: {
                            maxWidth: 'unset'
                        },
                        md: {
                            ' & a': {
                                minWidth: 'inherit',
                                padding: '3px 10px',
                                ' & span': {
                                    marginRight: '5px'
                                }
                            }
                        }
                    }
                }}
            // style={{ tableLayout: 'fixed' }}
            // rowStyle={stileRiga}
            >
                <FunctionField sortable={false} label="Nominativo" render={record => (
                    record.nominativo ?
                        <Typography component="p" variant="p">{permissions && permissions !== base64_encode(admB64) ? `Partecipante ${data.findIndex(v => v.id === record.id) + 1} di ${total}: ` : null}<b>{record.nominativo}</b></Typography>
                        :
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item m={0} p={0}>
                                <WarningAmber color="warning" fontSize='3px' />
                            </Grid>
                            <Grid item m={0} p={0}>
                                <Typography component="p" variant="p" sx={{ fontWeight: 'bold' }}>{permissions && permissions !== base64_encode(admB64) ? `Partecipante ${data.findIndex(v => v.id === record.id) + 1} di ${total}: ` : null}Da inserire</Typography>
                            </Grid>
                        </Grid>
                )} />
                <ReferenceField source="id_fermata" reference="fermata" label="Fermata">
                    <FunctionField sortable={false} label="Fermata" textAlign="center" render={record => (record.localita ?? <Typography component="p" variant="p">-</Typography>)} />
                </ReferenceField>
                {isMobile ? null :
                    <FunctionField sortable={false} label="Tipo" textAlign="center" render={record => {
                        if (record.tipo && record.tipo !== '') {
                            var ret;
                            switch (record.tipo) {
                                case 'adulti':
                                    ret = 'Adulto';
                                    break;
                                case 'bambini_1':
                                    ret = 'Bambino 0-2 anni';
                                    break;
                                case 'bambini_3':
                                    ret = 'Bambino 3-13 anni';
                                    break;
                                case 'bambini_4':
                                    ret = 'Ragazzo 14-17 anni';
                                    break;
                                default:
                                    break;
                            }
                            return <Typography component="p" variant="p" color={permissions && permissions === base64_encode(admB64) && record.non_accompagnato === true ? 'error' : null}>{ret}</Typography>;
                        }
                        // (record.tipo ?? <Typography component="p" variant="p">Da inserire</Typography>)}
                    }} />
                }
                {specifica_tipo === 2 ?
                    <FunctionField sortable={false} label="Camera" textAlign="center" render={record => {
                        if (record.id_richiesta_alloggi) {
                            return <ReferenceField source="id_richiesta_alloggi" reference="richiesta_alloggi">
                                <FunctionField render={
                                    record => {
                                        const a = (record.alloggio).split('_');
                                        var ret;
                                        switch (a[1]) {
                                            case 'sin':
                                                ret = 'Singola n.' + a[2]
                                                break;
                                            case 'dop':
                                                ret = 'Doppia n.' + a[2]
                                                break;
                                            case 'mat':
                                                ret = 'Matrimoniale n.' + a[2]
                                                break;
                                            case 'tri':
                                                ret = 'Tripla n.' + a[2]
                                                break;
                                            case 'qua':
                                                ret = 'Quadrupla n.' + a[2]
                                                break;
                                            default:
                                                break;
                                        }
                                        return ret;
                                    }
                                } />
                                {record.id_richiesta_alloggi && editabile ?
                                    <Button variant="link" label="" size="small" sx={{ my: 0, py: 0 }} startIcon={<SettingsBackupRestore />}
                                        onClick={() => {
                                            update('richiesta_dettagli', { id: record.id, data: { id_richiesta_alloggi: null }, previousData: { id_richiesta_alloggi: record.id_richiesta_alloggi } })
                                            update('richiesta_alloggi', { id: record.id_richiesta_alloggi, data: { stato: 1 }, previousData: { stato: 2 } })
                                        }}
                                    />
                                    : null}
                            </ReferenceField>
                            // } else if (record.tipo === 'bambini_1') {
                            //     return <Icon><DoNotDisturbAlt /></Icon>
                        } else {
                            return <Typography component="p" variant="p">Da inserire</Typography>
                        }
                    }} />
                    : null}
                {isDesktop ?
                    <FunctionField sortable={false} label="Telefono" textAlign="center" render={record => (record.telefono ?? <Typography component="p" variant="p">-</Typography>)} />
                    : null}
                {editabile ?
                    <WithRecord label="" render={record => {
                        var icona;
                        // if (isDesktop) {
                        if (record.nominativo)
                            icona = <ModeEdit />;
                        else
                            icona = <AddCircleOutline />;
                        // }
                        return <Button
                            variant="outlined"
                            sx={{ px: 1, mx: 0, fontWeight: 700 }}
                            startIcon={icona}
                            size="small"
                            // href={`/#/richiesta/${idRichiesta}/partecipante/${record.id}`}
                            onClick={() => handleClickPartecipante(record.id)}
                        >
                            {record.nominativo && isDesktop ? <Typography component="p" variant="p" ml={1}>Modifica</Typography> : null}
                            {!record.nominativo && isDesktop ? <Typography component="p" variant="p" ml={1}>Inserisci</Typography> : null}
                            {/* {icona} */}
                        </Button>
                    }} />
                    : null}
            </Datagrid>
        )
    }

    const PiccoleRes = () => {
        const { data, total } = useListContext();
        const { permissions } = usePermissions();
        if (!data || !total) return null;

        return (
            <Datagrid bulkActionButtons={false}
                sx={{
                    '& .RaDatagrid-row td': { border: 0 },
                    '& .RaDatagrid-thead': { display: 'none' },
                    '& .RaDatagrid-thead th': { border: 0 },
                    // '& .RaDatagrid-rowCell': { display: 'block' },
                    // flexDirection: 'column',
                    ' & td': {
                        padding: '4px',
                    },
                    ' & td:last-child': {
                        xs: {
                            // maxWidth: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ' & a': {
                                minWidth: 'unset',
                                ' & span': {
                                    margin: 0
                                }
                            }
                        },
                        sm: {
                            maxWidth: 'unset'
                        },
                        md: {
                            ' & a': {
                                minWidth: 'inherit',
                                padding: '3px 10px',
                                // ' & span': {
                                //     marginRight: '5px'
                                // }
                            }
                        }
                    }
                }}
            // style={{ tableLayout: 'fixed' }}
            // rowStyle={stileRiga}
            >
                <Grid container mb={2} spacing={0}>
                    <WithRecord label="" render={record => (
                        record.nominativo ?
                            <>
                                <Grid item xs={10.7} px={1} display='flex' flexDirection='column'>
                                    <Typography component="p" variant="p">{permissions && permissions !== base64_encode(admB64) ? <>Partecipante {data.findIndex(v => v.id === record.id) + 1} di {total}:<br /></> : null}<b>{record.nominativo}</b></Typography>
                                    {specifica_tipo === 2 ?
                                        <FunctionField sortable={false} label="Camera" render={record => {
                                            if (record.id_richiesta_alloggi) {
                                                return <ReferenceField source="id_richiesta_alloggi" reference="richiesta_alloggi">
                                                    <FunctionField render={
                                                        record => {
                                                            const a = (record.alloggio).split('_');
                                                            var ret;
                                                            switch (a[1]) {
                                                                case 'sin':
                                                                    ret = 'Singola n.' + a[2]
                                                                    break;
                                                                case 'dop':
                                                                    ret = 'Doppia n.' + a[2]
                                                                    break;
                                                                case 'mat':
                                                                    ret = 'Matrimoniale n.' + a[2]
                                                                    break;
                                                                case 'tri':
                                                                    ret = 'Tripla n.' + a[2]
                                                                    break;
                                                                case 'qua':
                                                                    ret = 'Quadrupla n.' + a[2]
                                                                    break;
                                                                default:
                                                                    break;
                                                            }
                                                            return `Camera: ${ret}`;
                                                        }
                                                    } />

                                                    {record.id_richiesta_alloggi && editabile ?
                                                        <Button variant="link" label="" size="small" startIcon={<SettingsBackupRestore />}
                                                            onClick={() => {
                                                                update('richiesta_dettagli', { id: record.id, data: { id_richiesta_alloggi: null }, previousData: { id_richiesta_alloggi: record.id_richiesta_alloggi } })
                                                                update('richiesta_alloggi', { id: record.id_richiesta_alloggi, data: { stato: 1 }, previousData: { stato: 2 } })
                                                            }}
                                                        />
                                                        : null}
                                                </ReferenceField>
                                                // } else if (record.tipo === 'bambini_1') {
                                                //     return <Icon><DoNotDisturbAlt /></Icon>
                                            } else {
                                                return <Typography component="p" variant="p"><b>Camera:</b> Da inserire</Typography>
                                            }
                                        }} />
                                        : null}
                                    <FunctionField sortable={false} label="Tipo" textAlign="center" render={record => {
                                        if (record.tipo && record.tipo !== '') {
                                            var ret;
                                            switch (record.tipo) {
                                                case 'adulti':
                                                    ret = 'Adulto';
                                                    break;
                                                case 'bambini_1':
                                                    ret = 'Bambino 0-2 anni';
                                                    break;
                                                case 'bambini_3':
                                                    ret = 'Bambino 3-13 anni';
                                                    break;
                                                case 'bambini_4':
                                                    ret = 'Ragazzo 14-17 anni';
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return <Typography component="p" variant="p" color={permissions && permissions === base64_encode(admB64) && record.non_accompagnato === true ? 'error' : null}>{ret}</Typography>;
                                        }
                                        // (record.tipo ?? <Typography component="p" variant="p">Da inserire</Typography>)}
                                    }} />

                                    <ReferenceField source="id_fermata" reference="fermata" label="Fermata">
                                        <FunctionField sortable={false} label="Fermata" textAlign="center" render={record => (
                                            record.localita ? <Typography component="p" variant="p">Fermata: {record.localita}</Typography> : <Typography component="p" variant="p">-</Typography>
                                        )} />
                                    </ReferenceField>
                                </Grid>
                                <Grid item xs={1.3}>
                                    {editabile ?
                                        <WithRecord label="" render={record => {
                                            var icona;
                                            // if (isDesktop) {
                                            if (record.nominativo)
                                                icona = <ModeEdit />;
                                            else
                                                icona = <AddCircleOutline />;
                                            // }
                                            return <Button
                                                variant="outlined"
                                                sx={{ px: 0, mx: 0, height: '100%', width: '100%', fontWeight: 700 }}
                                                startIcon={icona}
                                                size="small"
                                                // href={`/#/richiesta/${idRichiesta}/partecipante/${record.id}`}
                                                onClick={() => handleClickPartecipante(record.id)}
                                            >
                                                {record.nominativo && isDesktop ? "Modifica" : null}
                                                {!record.nominativo && isDesktop ? "Inserisci" : null}
                                                {/* {icona} */}
                                            </Button>
                                        }} />
                                        : null}
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={1.3}>
                                    <Button
                                        variant="outlined"
                                        color="warning"
                                        sx={{ px: '0 !important', mx: 0, height: '100%', width: '100%' }}
                                        startIcon={<WarningAmber color="warning" sx={{ fontSize: 35 }} />}
                                        // size="small"
                                        // href={`/#/richiesta/${idRichiesta}/partecipante/${record.id}`}
                                        onClick={() => handleClickPartecipante(record.id)}
                                    />
                                </Grid>
                                <Grid item xs={9.4} px={1.2}>
                                    <Typography component="p" variant="p" color="warning"><b>{permissions && permissions !== base64_encode(admB64) ? <>Partecipante {data.findIndex(v => v.id === record.id) + 1} di {total}:<br /></> : null}Da inserire</b></Typography>
                                    {specifica_tipo === 2 ?
                                        <Typography component="p" variant="p" color="warning"><b>Camera:</b> Da inserire</Typography>
                                        : null}
                                </Grid>
                                <Grid item xs={1.3}>
                                    {editabile ?
                                        <WithRecord label="" render={record => {
                                            var icona;
                                            // if (isDesktop) {
                                            if (record.nominativo)
                                                icona = <ModeEdit />;
                                            else
                                                icona = <AddCircleOutline />;
                                            // }
                                            return <Button
                                                variant="outlined"
                                                sx={{ px: 0, mx: 0, height: '100%', width: '100%' }}
                                                startIcon={icona}
                                                size="small"
                                                // href={`/#/richiesta/${idRichiesta}/partecipante/${record.id}`}
                                                onClick={() => handleClickPartecipante(record.id)}
                                            >
                                            </Button>
                                        }} />
                                        : null}
                                </Grid>
                            </>
                    )} />
                </Grid>
            </Datagrid>
        )
    }

    // if (specifica_tipo === null || specifica_tipo === undefined) return <LoadingComponent />;
    if (!nominativiRiassegnabili) return null;
    // console.log(nominativiRiassegnabili);

    return (
        <>
            <ListBase resource="richiesta_dettagli" filter={permFilter} sort={{ field: 'tipo', order: 'ASC' }} perPage="99" id="partecipanti">
                {isTablet ?
                    <PiccoleRes />
                    :
                    <GrandiRes />
                }
            </ListBase>
            {idRichiesta && statoModalPartecipanti ?
            <Dialog open={statoModalPartecipanti} onClose={() => setStatoModalPartecipanti(false)} maxWidth='lg'>
                <DialogContent sx={{ p:0 }}>
                    <EditPartecipante
                        idRichiesta={idRichiesta}
                        idPartecipante={idPartecipante}
                        updateStatoModal={setStatoModalPartecipanti}
                        updateModifichePartecipanti={setStatoModifichePartecipanti}
                        dettagliViaggio={dettagliViaggio}
                    />
                </DialogContent>
            </Dialog>
            : null}
        </>
    )
}

export const ListaRiassegnabili = (idRichiesta) => {
    const { data, isLoading, error } = useGetList(
        "richiesta_dettagli",
        {
            filter: { id_richiesta: idRichiesta, stato: 0 },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'nominativo', order: 'ASC' }
        },
    );
    if (isLoading) { return null }
    if (error) { return null }
    // console.log(data);
    return data.filter((v) => {
        if (v.nominativo !== '' && v.nominativo !== null) return true
        else return false
    }).map((v) => { return v });
}

export const SelectRiassegnabili = (props) => {
    // console.log(props.tipoPartecipante);
    const nominativiRiassegnabili = ListaRiassegnabili(props.idRichiesta);
    if (!nominativiRiassegnabili || nominativiRiassegnabili.map((v) => v).filter(v => v.tipo === props.tipoPartecipante).length === 0) return null;
    // console.log(nominativiRiassegnabili.map((v) => v).filter(v => v.tipo === props.tipoPartecipante).length);
    return (
        <Grid mb={{ xs: 0, md: 2, lg: 3 }}>
            <Alert severity="info" icon={false}>
                <Typography component="h3" variant="h3" color='secondary.dark' sx={{ mb:2 }}>Recupera un nominativo</Typography>
                <Typography variant="p" component="p" sx={{ mb: 3 }}>Puoi procedere con il recupero di {nominativiRiassegnabili.length > 0 ? nominativiRiassegnabili.length === 1 ? `un partecipante che hai precedentemente inserito` : `${nominativiRiassegnabili.length} partecipanti precedentemente inseriti` : null}. In questo modo non dovrai inserire nuovamente tutti i dati ma non potrai apportare eventuali modifiche.
                </Typography>
                <SelectInput
                    source="riassegnabile"
                    label="Riassegnare a"
                    optionText="nominativo"
                    optionValue="id"
                    // sx={{ width: '100%' }}
                    {...props}
                    choices={nominativiRiassegnabili.map((v) => v).filter(v => v.tipo === props.tipoPartecipante)
                    }
                    onChange={(e) => {
                        // console.log(e.target.value)
                        return nominativiRiassegnabili.filter((v) => {
                            if (e.target.value !== '' && v.id === e.target.value) return true
                            else return false
                        }).map((v) => {
                            // console.log(v);
                            props.setup({
                                id: v.id,
                                nominativo: v.nominativo,
                                telefono: v.telefono,
                                id_fermata: v.id_fermata,
                                tipo: v.tipo,
                                data_nascita: v.data_nascita,
                            })
                            return v
                        });
                    }}
                />
            </Alert>
        </Grid>
    );
}

const ListaCamere = ({ setIdAlloggio }) => {
    // const [idAlloggio, setIdAlloggio] = useState(null);
    const [objAlloggi, setObjAlloggi] = useState([]);
    const { allChoices, isLoading, error } = useChoicesContext();

    useEffect(() => {
        // console.log(allChoices);
        if (allChoices.length > 0) {
            setObjAlloggi(allChoices.filter((ele, ind) => ind === allChoices.findIndex(elem => elem.alloggio === ele.alloggio)))
        }
    }, [allChoices]);

    // useEffect(() => {
    //     console.log(objAlloggi);
    // }, [objAlloggi]);

    const NomeAlloggio = () => {
        const record = useRecordContext();
        const a = (record.alloggio).split('_');
        var ret;
        switch (a[1]) {
            case 'sin':
                ret = 'Singola n.' + a[2]
                break;
            case 'dop':
                ret = 'Doppia n.' + a[2]
                break;
            case 'mat':
                ret = 'Matrimoniale n.' + a[2]
                break;
            case 'tri':
                ret = 'Tripla n.' + a[2]
                break;
            case 'qua':
                ret = 'Quadrupla n.' + a[2]
                break;
            default:
                break;
        }
        return <span>{ret}</span>;
    }

    // function handleSelect (e) {
    //     console.log(e.target.value);
    //     setIdAlloggio(e.target.value)
    // }

    if (isLoading) return <LoadingComponent />
    if (isLoading) return <p>Errore</p>

    return (
        <SelectInput
            sx={{ width: '100%' }}
            source="id_richiesta_alloggi"
            label="Camera"
            choices={objAlloggi}
            optionText={<NomeAlloggio />}
            optionValue="id"
            onChange={(e) => setIdAlloggio(e.target.value)}
            validate={[required()]}
        />
    )
}

export const EditPartecipante = ({ idRichiesta, idPartecipante, updateStatoModal, dettagliViaggio }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    // const { idRichiesta, idPartecipante } = useParams();
    // const redirectLink = `/richiesta/${idRichiesta}`;
    const [minoreNonAccompagnato, setMinoreNonAccompagnato] = useState(null);
    const [bloccaCampi, setBloccaCampi] = useState(false);
    const [mostraCamera, setMostraCamera] = useState(false);
    const [idAlloggio, setIdAlloggio] = useState(null);
    const [dettagliPartecipante, setDettagliPartecipante] = useState({
        id: null,
        nominativo: null,
        telefono: null,
        id_fermata: null,
        tipo: null,
        alloggio: null,
    });
    const [update, { isLoadingUpdate, errorUpdate }] = useUpdate();
    const [deleteOne, { isLoadingDelete, errorDelete }] = useDelete(
        'richiesta_dettagli',
        { id: dettagliPartecipante.id, previousData: dettagliPartecipante }
    );
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const idUtente = localStorage.getItem('auth_uid');
    // const { data: user } = useGetOne('users', { id: record.userId });

    const onSuccess = () => {
        // console.log('salvataggio');
        if (bloccaCampi) deleteOne();
        if (record.tipo !== 'bambini_1') update('richiesta_alloggi', { id: idAlloggio, data: { stato: 2 }, previousData: { stato: 1 } })
        logger(19, `ID partecipante: ${idPartecipante}`, null, null, idRichiesta, idUtente);
        notify(`Modifiche salvate`, { type: 'success' });
        updateStatoModal(false);
        refresh();
    }
    const { record, save, isLoading } = useEditController({ resource: 'richiesta_dettagli', id: idPartecipante, mutationOptions: { onSuccess }, mutationMode: 'pessimistic' });
    const editContext = useEditContext();

    // const NomeAlloggio = () => {
    //     const record = useRecordContext();
    //     const a = (record.alloggio).split('_');
    //     var ret;
    //     switch (a[1]) {
    //         case 'sin':
    //             ret = 'Singola n.' + a[2]
    //             break;
    //         case 'dop':
    //             ret = 'Doppia n.' + a[2]
    //             break;
    //         case 'mat':
    //             ret = 'Matrimoniale n.' + a[2]
    //             break;
    //         case 'tri':
    //             ret = 'Tripla n.' + a[2]
    //             break;
    //         case 'qua':
    //             ret = 'Quadrupla n.' + a[2]
    //             break;
    //         default:
    //             break;
    //     }
    //     return <span>{ret}</span>;
    // }

    useEffect(() => {
        if (record) {
            // console.log(record);
            setDettagliPartecipante(record);
            const params = { join: 'viaggio', include: 'viaggio.specifica_tipo' };
            const qs = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            axios(apiFullUrl + '/records/richiesta/' + idRichiesta + '?' + qs, {
                method: 'GET',
                headers: {
                    'X-API-Key': localStorage.getItem('auth_api_key')
                }
            })
                .then(response => {
                    if (response.data.id_viaggio.specifica_tipo === 2)
                        setMostraCamera(true);
                });
        }
    }, [record]);

    useEffect(() => {
        // console.log(dettagliPartecipante);
        if (dettagliPartecipante.id && parseInt(idPartecipante) !== parseInt(dettagliPartecipante.id)) {
            // console.log('diversi');
            setBloccaCampi(true);
        }
        if (dettagliPartecipante && dettagliPartecipante.non_accompagnato) {
            setMinoreNonAccompagnato(dettagliPartecipante.non_accompagnato);
        }

        if (dettagliPartecipante && dettagliPartecipante.id && !dettagliPartecipante.tipoTitolo) {
            var tmp;
            // if (dettagliPartecipante.tipo !== 'adulti') setMinoreNonAccompagnato(false)
            switch (dettagliPartecipante.tipo) {
                case 'adulti':
                    tmp = 'Adulto';
                    break;
                case 'bambini_1':
                    tmp = 'Bambino 0-2 anni';
                    break;
                case 'bambini_3':
                    tmp = 'Bambino 3-13 anni';
                    break;
                case 'bambini_4':
                    tmp = 'Ragazzo 14-17 anni';
                    break;
                default:
                    break;
            }
            setDettagliPartecipante({
                ...dettagliPartecipante,
                tipoTitolo: tmp
            })
        }
    }, [dettagliPartecipante]);

    return (
        <>
            <HeaderSezione icona={PermIdentity} titolo={dettagliPartecipante.tipoTitolo} dettaglio={"Inserisci i dettagli di un " + dettagliPartecipante.tipoTitolo} />
            <SimpleForm
                toolbar={
                    <Toolbar sx={{ justifyContent: 'end', backgroundColor: 'transparent', padding: '0 !important' }} >
                        <SaveButton alwaysEnable sx={{ mx: 2, fontWeight: 700 }} />
                    </Toolbar>
                }
                sanitizeEmptyValues
                record={dettagliPartecipante}
                onSubmit={save}
                sx={{ px: 2, pb: '0 !important' }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <IconButton
                            aria-label="toggle visibility"
                            onClick={() => updateStatoModal(false)}
                            edge="end"
                            size="small"
                            variant="contained"
                            sx={{ position: 'absolute', right: 10, top: 10, outline: "0 !important", borderWidth: 1, borderStyle: 'solid', backgroundColor: myTheme.palette.secondary.lighter, zIndex:999 }}
                        >
                            <Close />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <TextInput source="nominativo" label="Nome e Cognome" validate={[required()]} sx={{ width: '100%' }} disabled={bloccaCampi ? true : false} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <TextInput source="telefono" label="Cellulare" validate={[number()]} sx={{ width: '100%' }} disabled={bloccaCampi} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DateInput source="data_nascita" label="Data di nascita" validate={[required(), maxValue(moment().format('YYYY-MM-DD'), 'Inserisci una data valida')]} sx={{ width: '100%' }} disabled={bloccaCampi} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        {/* <SelectInput label="Fermata" fullWidth source="fermata" choices={listaFermate} disabled={bloccaCampi} optionText="name" optionValue="id" validate={[required()]} /> */}
                        <ListaFermateUtente
                            fermate={dettagliViaggio.partenza}
                            disabled={bloccaCampi}
                            opzioneSelezionata={dettagliPartecipante.id_fermata}
                        />
                    </Grid>
                    {mostraCamera ?
                        <Grid item xs={12} md={6} lg={4}>
                            {/*
                                <ReferenceInput
                                    source="id_richiesta_alloggi"
                                    reference="richiesta_alloggi"
                                    sort={{ field: 'alloggio', order: 'ASC' }}
                                    // filter={{stato: 1, id_richiesta: dettagliPartecipante.id_richiesta}}
                                    filter={dettagliPartecipante.tipo !== 'bambini_1' ? { stato: 1, id_richiesta: dettagliPartecipante.id_richiesta } : { id_richiesta: dettagliPartecipante.id_richiesta, stato_in: [1, 2] }}
                                >
                                    <SelectInput
                                        sx={{ width: '100%' }}
                                        source="id"
                                        label="Camera"
                                        optionText={<NomeAlloggio />}
                                        optionValue="id"
                                        onChange={(e) => setIdAlloggio(e.target.value)}
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                            */}
                            <ReferenceInput
                                source="id_richiesta_alloggi"
                                reference="richiesta_alloggi"
                                sort={{ field: 'alloggio', order: 'ASC' }}
                                filter={dettagliPartecipante.tipo !== 'bambini_1' ? { stato: 1, id_richiesta: idRichiesta } : { id_richiesta: idRichiesta, stato_in: [1, 2] }}
                            >
                                <ListaCamere setIdAlloggio={setIdAlloggio} />
                            </ReferenceInput>
                        </Grid>
                        : null}
                    <Grid item xs={12}>
                        <AlertFermata variante="partecipante" icona={BusAlert} />
                    </Grid>
                </Grid>
                {dettagliPartecipante.tipo !== 'adulti' ?
                    <Grid container spacing={2} sx={{ px: { xs: 2, lg: 4 }, pt: { xs: 2, lg: 4 }, pb: 0 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <SelectInput
                                source="non_accompagnato"
                                label="Il minore è accompagnato?"
                                optionText="name"
                                optionValue="id"
                                onChange={event => setMinoreNonAccompagnato(event.target.value)}
                                validate={[required()]}
                                sx={{ width: '100%' }}
                                choices={[
                                    // { id: null, name: '' },
                                    { id: false, name: 'Il minore è accompagnato da genitore o tutore legale' },
                                    { id: true, name: 'Il minore NON è accompagnato da genitore o tutore legale' },
                                ]}
                            />
                        </Grid>
                        {minoreNonAccompagnato ?
                            <Grid item xs={12} sm={12} lg sx={{ pt: "0 !important" }}>
                                <AlertModuloMinorenni versione={2} />
                            </Grid>
                            : null}
                    </Grid>
                    : null}
                <Grid container spacing={2}
                    // sx={{ px: { xs: 2, lg: 4 }, pt: { xs: 2, lg: 4 }, pb: 0 }}
                >
                    <Grid item xs={12} sm={12} lg>
                        {!bloccaCampi ?
                            <SelectRiassegnabili
                                idRichiesta={idRichiesta}
                                tipoPartecipante={dettagliPartecipante.tipo}
                                setup={setDettagliPartecipante}
                                sx={{ width: '100%' }}
                                disabled={bloccaCampi ? true : false}
                            />
                            :
                            <Grid mb={3}>
                                <Alert severity="warning">
                                    <AlertTitle>Stai recuperando i dati di {dettagliPartecipante.nominativo}</AlertTitle>
                                    <Typography variant="p" component="p" sx={{ mb: 2 }}>Hai deciso di recuperare i dati di <b>{dettagliPartecipante.nominativo}</b>. Tuttavia non è possibile modificare i dati precedentemente inseriti. Se hai bisogno di cambiarli (anche solo parzialmente) puoi premere il tasto <b>Annulla</b> e procedere con l'inserimento manuale senza utilizzare la procedura di.
                                    </Typography>
                                    <Button size="small" color="warning" onClick={() => {
                                        setDettagliPartecipante(record);
                                        setBloccaCampi(false);
                                    }}>Annulla</Button>
                                </Alert>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </SimpleForm>
        </>
    )
}