import { useEffect, useState, useRef, memo, useCallback, useMemo } from "react";
import { Button, useUpdate, useRefresh, useNotify, useUpdateMany, useListContext, useReference, useGetMany, useDelete, useRecordContext } from 'react-admin';
import { Box, IconButton, Modal, Grid, TextField, Typography, Tooltip, Checkbox, FormGroup, FormControlLabel, Dialog, DialogContent } from '@mui/material';
import { Email, FileCopy, Close, ContentPaste, Assignment } from '@mui/icons-material';
import { logger, admB64, HeaderSezione, MezziAssegnabili, myTheme, copyToClipboard, pasteFromClipboard, dataProvider, isJson } from '../generale';
import moment from 'moment';
import { LoadingComponent } from "../LoadingComponent";
import { AccompagnatoriListBaseCheckbox } from "../Accompagnatori/AccompagnatoriListaCheckbox";
import { DocPDF, DocPDFtest, generaPDFRichiesta } from './RichiestaPdf';
import { usePDF, pdf, BlobProvider } from '@react-pdf/renderer';
import { InfoFermate } from "../Viaggi/FermateInfo";

export function MessaggioFinale ({ idViaggio = null, titoloViaggio = null, indicazioni = '', idRichieste }) {
    const refresh = useRefresh();
    const notify = useNotify();
    const idUtente = localStorage.getItem('auth_uid');
    const [mostraModalMessaggio, setMostraModalMessaggio] = useState(false);
    const [update, { isLoading, error }] = useUpdate();
    const [updateMany, { isLoading: isLoadingUM, error: errorUM }] = useUpdateMany();
    
    // const cambioMostraModal = useCallback(() => {
    //     console.log('Richiamo useCallback');
    //     setMostraModalMessaggio((t) => t);
    // }, [mostraModalMessaggio]);

    // const FormMessaggio = memo(function Form() {
    const FormMessaggio = ({ chiudi }) => {
        const refCheckFermate = useRef()
        const [abilitaInvio, setAbilitaInvio] = useState(false);
        const [invioInCorso, setInvioInCorso] = useState(false);
        const [testoMessaggio, setTestoMessaggio] = useState(null);
        const [fermate, setFermate] = useState(null);
        const [testoAccompagnatori, setTestoAccompagnatori] = useState('');
        const [testoAccompagnatoriCopiato, setTestoAccompagnatoriCopiato] = useState('');
        const [mezzo, setMezzo] = useState(null);
        const [modalInfo, setModalInfo] = useState(false)
        const { referenceRecord: viaggio, isLoadingViaggio, errorViaggio } = useReference({
            reference: 'viaggio',
            id: idViaggio
        });
        const { data: emailFinale } = useGetMany(
            'richiesta_email_finale',
            { ids: idRichieste }
        );
        const [eliminaBlobPdf] = useDelete();

        function manageBlob(b, r) {
            var reader = new FileReader();
            reader.onload = function(fileLoadedEvent) {
                const file = fileLoadedEvent.target.result;
                // console.log(file);
                dataProvider.create('richiesta_pdf_finale', { data: { id: r, blob_pdf: file } })
                // .then((d) => {
                //     console.log(d);
                // }).catch((e) => {
                //     console.log(e);
                // })
            };
            // console.log(b);
            reader.readAsDataURL(b);
        }

        useEffect(() => {
            checkAbilitaInvio()
        }, [mezzo, testoMessaggio])

        // useEffect(() => {
        //     if (viaggio && viaggio.partenza) {
        //         var testoMod = (viaggio.partenza).replace(/(<([^>]+)>)/gmi, "");
        //         // console.log(testoMod);
        //         setTestoMessaggio(testoMod);
        //     }
        // }, [viaggio])

        useEffect(() => {
            if (viaggio && viaggio.partenza) {
                if (isJson(viaggio.partenza))
                    setFermate(JSON.parse(viaggio.partenza));
                else
                    setFermate(false)
            } else {
                setFermate(false)
            }
        }, [viaggio])

        function checkAbilitaInvio() {
            // console.log(mezzo, testoMessaggio, refCheckFermate.current?.checked);
            if (mezzo && (testoMessaggio || refCheckFermate.current.checked === true))
                setAbilitaInvio(true)
            else
                setAbilitaInvio(false)
        }

        function handleChangeCheckFermate() {
            checkAbilitaInvio()
        }

        const inviaMessaggio = () => {
            const payload = idRichieste.map(() => { return { id_mezzo_assegnato: mezzo, accompagnatore: testoAccompagnatori } });
            const messaggio = {
                fermate: (refCheckFermate.current.checked ? fermate : null),
                testo: (testoMessaggio ?? null),
            }
            
            updateMany(
                'richiesta',
                {
                    ids: idRichieste,
                    data: payload
                },
                {
                    onSuccess: (data) => {
                        idRichieste.map(req => {
                            // console.log(`Salvo il messaggio per la richiesta ${r}`);
                            const x = generaPDFRichiesta(req);
                            x.then(d => {
                                eliminaBlobPdf('richiesta_pdf_finale', { id: req });
                                const blob = pdf(
                                    <DocPDF
                                        r={d.r}
                                        v={d.v}
                                        ef={d.ef}
                                        rdp={d.rdp}
                                        acc={d.acc}
                                        ag={d.ag}
                                        f={d.f}
                                    />
                                )
                                .toBlob()
                                .then((blob) => {
                                    // console.log(blob);
                                    manageBlob(blob, req);
                                    // setInvioInCorso(false);
                                    setMostraModalMessaggio(false);
                                    notify('Operazione completata', { type: 'success' });
                                    setTestoMessaggio(null);
                                    refresh();
                                });
                            });
                            logger(17, JSON.stringify(messaggio), null, idViaggio, req, idUtente)
                                .then((res) => {
                                    if (res.data.id) {
                                        update(
                                            'viaggio',
                                            {
                                                id: idViaggio,
                                                data: {
                                                    invio_mail_finale: 1,
                                                },
                                                previousData: {
                                                    invio_mail_finale: 0,
                                                }
                                            }
                                        );
                                    } else {
                                        notify('Verificare la corretta esecuzione dell\'operazione', { type: 'warning' });
                                    }
                                })
                                .catch((error) => {
                                    notify('Errore nell\'esecuzione dell\'operazione', { type: 'error' });
                                });
                            return req;
                        })
                    }
                }
            )
        }

        return (
            <>
                {invioInCorso === true ?
                <Box margin={2} style={{ backgroundColor: '#fff' }}>
                    <Grid container>
                        <Grid item xs={12} md={12} p={5}>
                            <LoadingComponent />
                        </Grid>
                    </Grid>
                </Box>
                :
                <Box style={{ backgroundColor: '#fff' }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Box>
                                <HeaderSezione
                                    // icona={Email}
                                    titolo="Invia il messaggio finale"
                                    dettaglio={titoloViaggio}
                                    tasto={<IconButton color="primary" onClick={() => pasteFromClipboard().then(res => {
                                        let r;
                                        try {
                                            r = JSON.parse(res);
                                            setTestoMessaggio(r.dettaglio);
                                            setMezzo(r.id_mezzo);
                                            setTestoAccompagnatoriCopiato(r.accompagnatore);
                                            notify('Il messaggio finale è stato copiato', { type: 'info' });
                                        } catch (e) {
                                            notify('Non è stato possibile incollare le informazioni adeguate', { type: 'error' });
                                            return console.error(e); // error in the above string (in this case, yes)!
                                        }
                                    }).catch(err => notify(err, { type: 'error' }))}><ContentPaste /></IconButton>}
                                />
                                <TextField
                                    label='Il messaggio viene precaricato con il campo relativo alla partenza del viaggio, letto dal sito (se esistente)'
                                    defaultValue={testoMessaggio}
                                    value={testoMessaggio}
                                    fullWidth
                                    multiline
                                    rows={5}
                                    onChange={e => setTestoMessaggio(e.target.value !== '' ? e.target.value : null)}
                                    sx={{ '& textarea': { resize: 'vertical' } }}
                                />
                            </Box>
                            {typeof fermate === 'object' ? 
                                <Box mb={3} display="inline-flex" alignItems="center">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={typeof fermate === 'object' ? true : false}
                                                    inputRef={refCheckFermate}
                                                    onChange={handleChangeCheckFermate}
                                                />
                                            }
                                            label="Inserisci le fermate e gli orari automaticamente"
                                        />
                                    </FormGroup>
                                    <InfoFermate dettaglioFermate={fermate} versioneTasto="estesa" />
                                </Box>
                            : null}
                            <Box mb={3}>
                                <Button onClick={() => setTestoMessaggio((testoMessaggio ?? '') + '🅿️\n')} sx={{ fontSize: 30 }}><>🅿️</></Button>
                                <Button onClick={() => setTestoMessaggio((testoMessaggio ?? '') + '🧳\n')} sx={{ fontSize: 30, ml: 2 }}><>🧳</></Button>
                                <Button onClick={() => setTestoMessaggio((testoMessaggio ?? '') + '🛄\n')} sx={{ fontSize: 30, ml: 2 }}><>🛄</></Button>
                                <Button onClick={() => setTestoMessaggio((testoMessaggio ?? '') + '💺\n')} sx={{ fontSize: 30, ml: 2 }}><>💺</></Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} mb={5}>
                            <AccompagnatoriListBaseCheckbox updateVal={setTestoAccompagnatori} forceVal={testoAccompagnatoriCopiato ?? null} />
                        </Grid>
                        <Grid item xs={12} md={4} mb={5}>
                            <MezziAssegnabili updateVal={setMezzo} forceVal={mezzo} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12} display='flex' flexDirection='row' justifyContent='end' gap={5}>
                            <Button variant="outlined" size='medium' onClick={chiudi}>Annulla</Button>
                            <Button label='Invia messaggio' size='medium' variant='contained' disabled={!abilitaInvio} onClick={() => {
                                setInvioInCorso(true);
                                inviaMessaggio()
                            }} ></Button>
                        </Grid>
                    </Grid>
                </Box>
                }
            </>
        )
    }

    // useEffect(() => {
    //     console.log('Render MessaggioFinale', idViaggio);
    // }, [])
    
    // useEffect(() => {
    //     console.log('Render MessaggioFinale', idViaggio, titoloViaggio, idRichieste);
    // }, [])

    const handleOpen = () => setMostraModalMessaggio(true);
    const handleClose = () => setMostraModalMessaggio(false);

    return (
        <>
            <Button label='Messaggio finale' onClick={handleOpen} sx={{ mr: 3 }}></Button>
            <Dialog
                open={mostraModalMessaggio}
                onClose={handleClose}
                fullWidth={true}
                maxWidth='lg'
            >
                <DialogContent>
                    <FormMessaggio indicazioni={indicazioni} chiudi={handleClose} />
                </DialogContent>
            </Dialog>
        </>
    )
};

export const IconaMessaggioFinale = () => {
    const notify = useNotify();
    const [currentData, setCurrentData] = useState(null);
    const [rispostaInvio, setRispostaInvio] = useState(null);
    const [iconColor, setIconColor] = useState(null);
    const [mostraModal, setMostraModal] = useState(false);
    const record = useRecordContext();
    const { data, isLoading } = useListContext();
    const { data: emailFinale } = useGetMany(
        'richiesta_email_finale',
        { ids: data.map((v) => v.id) }
    );

    // const { data: elencoAccompagnatori } = useGetList(
    //     'accompagnatore',
    //     { 
    //         pagination: { page: 1, perPage: 99 },
    //     }
    // );

    const { data: accompagnatori, isLoading: isLoadingAccompagnatori, error: errorAccompagnatori } = useGetMany(
        'accompagnatore',
        { ids: (record.accompagnatore ? (record.accompagnatore).split(",") : null) }
    );

    // useEffect(() => {
    //     if (rispostaInvio) {
    //         console.log(rispostaInvio);
    //     }
    // }, [rispostaInvio]);

    // useEffect(() => {
    //     if (currentData) {
    //         console.log(currentData);
    //     }
    // }, [currentData]);

    useEffect(() => {
        if (data && record && emailFinale && emailFinale !== undefined) {
            const tmp = emailFinale.filter((v) => v.id === record.id);
            setCurrentData(tmp[0]);
        }
    }, [data, emailFinale, record]);

    useEffect(() => {
        // console.log(currentData);
        if (currentData) {
            if (currentData.invio_mail === false) {
                // Preparata ma non inviata
                setIconColor(myTheme.palette.warning[500]);
            } else if (currentData.invio_mail === true) {
                // Inviata
                setIconColor(myTheme.palette.success[500]);
            } else {
                // Non preparata
                setIconColor(null);
            }
            if (currentData.risposta) {
                const risp = JSON.parse(currentData.risposta);
                setRispostaInvio(risp[0]);
            }
        } else {
            setIconColor(null)
        }
    }, [currentData]);

    // if (!currentData) return <Email sx={{ color: iconColor }} />;
    if (!currentData || isLoadingAccompagnatori || errorAccompagnatori) return null;

    return (
        iconColor ?
            <>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Tooltip title={`Inviato il ${(currentData && currentData.data_ora ? moment(currentData.data_ora).format("DD/MM/YY HH:mm") : null)}`}>
                        <IconButton
                            // variant="iconaLista"
                            sx={{ color: iconColor, pl: 0, py: 0 }}
                            onClick={() => setMostraModal(!mostraModal)}
                        >
                            <Email />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="small" component="p">{currentData ? currentData.cod_mezzo : null}</Typography>
                </Box>
                <Modal open={mostraModal} onClose={() => setMostraModal(!mostraModal)} keepMounted variant='customModalBox'>
                    <Box margin={2} style={{ backgroundColor: '#fff' }}>
                        <IconButton
                            aria-label="toggle visibility"
                            onClick={() => setMostraModal(!mostraModal)}
                            edge="end"
                            size="small"
                            variant="contained"
                            sx={{ position: 'absolute', right: 10, top: 10, outline: "0 !important", borderWidth: 1, borderStyle: 'solid' }}
                        >
                            <Close />
                        </IconButton>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Box mx={2} mt={2}>
                                    <HeaderSezione titolo="Copia del messaggio inviato" dettaglio={currentData ? currentData.titolo : null} tasto={<IconButton color="primary" onClick={() => copyToClipboard(JSON.stringify({ dettaglio: currentData.dettaglio, id_mezzo: currentData.id_mezzo, accompagnatore: currentData.accompagnatore }) ?? '').then(res => notify('Il messaggio finale è stato copiato', { type: 'info' })).catch(err => notify(err, { type: 'error' }))}><FileCopy /></IconButton>} />
                                    <TextField
                                        defaultValue={currentData ? currentData.dettaglio : null}
                                        value={currentData ? currentData.dettaglio : null}
                                        disabled
                                        fullWidth
                                        multiline
                                        rows={5}
                                        sx={{ '& textarea': { resize: 'vertical', color: '#000' } }}
                                    />
                                </Box>
                                <Box mx={2} mb={2} display='flex' flexDirection='column'>
                                    <Typography variant="p" component="p"><b>Mezzo:</b> {(currentData.mezzo ?? null)}{(currentData.cod_mezzo ? ` (${currentData.cod_mezzo})` : null)}</Typography>
                                    <Typography variant="p" component="p"><b>Accompagnatori:</b> {accompagnatori.map(v => v.nominativo).join(', ')}</Typography>
                                    <Typography variant="p" component="p"><b>Data e ora:</b> {(currentData.data_ora ? moment(currentData.data_ora).format("DD/MM/YY HH:mm") : null)}</Typography>
                                    {(rispostaInvio && rispostaInvio.messageID ?
                                        <Box display='flex' flexDirection='row'>
                                            <Typography variant="p" component="p"><b>Email ID:</b> {rispostaInvio.messageID}</Typography>
                                            <IconButton sx={{ p: 0, ml: 1 }} color="primary" onClick={() => copyToClipboard(rispostaInvio.messageID).then(res => notify('Email ID copiato', { type: 'info' })).catch(err => notify(err, { type: 'error' }))}><FileCopy sx={{ fontSize: 16 }} /></IconButton>
                                        </Box>
                                        : null)}
                                    {(rispostaInvio && rispostaInvio.messageIDsms ?
                                        <Box display='flex' flexDirection='row'>
                                            <Typography variant="p" component="p"><b>SMS ID:</b> {rispostaInvio.messageIDsms}</Typography>
                                            <IconButton sx={{ p: 0, ml: 1 }} color="primary" onClick={() => copyToClipboard(rispostaInvio.messageIDsms).then(res => notify('SMS ID copiato', { type: 'info' })).catch(err => notify(err, { type: 'error' }))}><FileCopy sx={{ fontSize: 16 }} /></IconButton>
                                        </Box>
                                        : null)}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </>
            : null
    )
}

export const IconaRiepilogoMessaggiViaggio = () => {
    const record = useRecordContext();
    const { data, isLoading } = useListContext();
    const [currentData, setCurrentData] = useState(null);
    const [iconColor, setIconColor] = useState(null);

    useEffect(() => {
        if (data) {
            // console.log(data);
            const rec = data.filter((v) => v.id === record.id && v.n_confermati > 0);
            if (rec.length > 0)
                setCurrentData(rec[0]);
        }
    }, [data, record]);

    useEffect(() => {
        if (currentData) {
            // console.log(currentData);
            const situazioneInvio = (currentData.mail_finali_inviate === currentData.mail_finali_totali);
            if (situazioneInvio) {
                setIconColor(myTheme.palette.success[500]);
            } else {
                setIconColor(myTheme.palette.warning[500]);
            }
        }
    }, [currentData]);

    if (!currentData) return null;

    return (
        <Tooltip title={`Inviati ${currentData.mail_finali_inviate} di ${currentData.mail_finali_totali}`}>
            <Email sx={{ color: iconColor }} />
        </Tooltip>
    )
}