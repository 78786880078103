import { useEffect, useState } from "react";
import { useUpdate, useCreate, useRedirect, useNotify, useGetOne, Create, DateInput, SimpleForm, FormTab, TextInput, BooleanInput, required, SelectInput, ReferenceInput, AutocompleteInput, ImageInput, ImageField, useGetList, useRecordContext } from 'react-admin';
import { useFormContext } from "react-hook-form";
import { Grid, InputAdornment, Typography, Box, Button, Dialog, DialogTitle, DialogContent, Alert, DialogActions, IconButton } from '@mui/material';
import { Luggage, ArrowDownward, Image, CloudUpload, ListAlt, Alarm } from '@mui/icons-material';
import { logger, HeaderSezione, apiFullUrl, localeITmoment, myTheme } from '../generale';
import slugify from "slugify";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';
import axios from 'axios';
import { CategorieViaggio } from "./CategorieViaggio";
import { Fermate } from "./FermateSelect";
import { RichTextEditor } from "../RichTextEditor";

const CustomForm = () => {
    const [tipoViaggio, setTipoViaggio] = useState(null);
    const { setValue, watch } = useFormContext()
    const formValues = watch()
    moment.updateLocale('it', localeITmoment);

    const changeTipoViaggio = (id) => {
        setTipoViaggio(id)
        if (id !== 0)
            setValue("prezzo_bambini_2", '')
        if (id !== 2)
            setValue("suppl_singola", '')
    }

    // useEffect(() => {
    //     console.log(formValues);
    // }, [formValues])

    useEffect(() => {
        if (
            formValues.titolo
            && formValues.titolo !== undefined
            && formValues.data_inizio
            && formValues.data_inizio !== undefined
        ) {
            const dataFine = formValues.data_fine && formValues.data_fine !== undefined && moment(formValues.data_fine).isValid() ? moment(formValues.data_fine) : null;
            const alias = (() => {
                if (!dataFine) return moment(formValues.data_inizio).format("DD-MMM-YYYY")
                else if (moment(formValues.data_inizio).format("MM") !== moment(formValues.data_fine).format("MM")) return moment(formValues.data_inizio).format("DD-MMM") + " " + moment(formValues.data_fine).format("DD-MMM-YYYY")
                else return moment(formValues.data_inizio).format("DD") + " " + moment(formValues.data_fine).format("DD-MMM-YYYY")
            })()
            const slugViaggio = slugify(`${formValues.titolo} ${alias}` ?? '', { replacement: '-', lower: true })
            setValue("alias", slugViaggio)
        } else if (!formValues.alias || formValues.alias !== '')
            setValue("alias", '')
    }, [formValues.titolo, formValues.data_inizio, formValues.data_fine])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ButtonCopia sx={{ mb: 4 }} update={setValue} />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
                <Grid container display='flex' flexDirection='column' spacing={2}>
                    <Grid item>
                        <Fermate />
                    </Grid>
                    <Grid item>
                        <SelectInput
                            source="specifica_tipo"
                            label="Specifica tipologia"
                            fullWidth
                            choices={[
                                { id: 0, name: 'Parchi divertimento' },
                                { id: 1, name: 'Giornata singola' },
                                { id: 2, name: 'Almeno 2 giornate' }
                            ]}
                            onChange={(e) => changeTipoViaggio(e.target.value)}
                            validate={[required()]}
                        />
                    </Grid>
                    <Grid item>
                        <TextInput source="titolo" label="Titolo del viaggio" type="text" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item>
                        <TextInput source="alias" label="Alias" type="text" validate={[required()]} fullWidth readOnly />
                    </Grid>
                    <Grid item sx={{ pt: "0 !important", pb: 3 }}>
                        <CategorieViaggio
                            update={setValue}
                            id_viaggio={formValues.id_viaggio}
                        />
                    </Grid>
                    <Grid item display='flex' flexDirection='row' gap={2}>
                        <DateInput source="data_inizio" label="Data inizio" validate={[required()]} fullWidth />
                        <DateInput source="data_fine" label="Data fine" fullWidth />
                    </Grid>
                    <Grid item display='flex' flexDirection='row' gap={2}>
                        <TextInput source="descr_breve" label="Descrizione breve" type="text" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item display='flex' flexDirection='row' gap={2}>
                        <TextInput source="prezzo" label="Prezzo" type="text" validate={[required()]} fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} />
                        <TextInput source="prezzo_prec" label="Prezzo precedente" type="text" fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} defaultValue={0} />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            {tipoViaggio === 0 ?
                                <Grid item xs={12} md={6}>
                                    <TextInput source="prezzo_bambini_2" label="Prezzo bambini sotto 1 mt" type="text" fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} defaultValue={0} />
                                </Grid>
                                : null}
                            {tipoViaggio === 2 ?
                                <Grid item xs={12} md={6}>
                                    <TextInput source="suppl_singola" label="Suppl. camera singola" type="text" fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} defaultValue={0} />
                                </Grid>
                                : null}
                            <Grid item xs={12} md={6}>
                                <TextInput source="acconto" label="Acconto" type="text" fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} defaultValue={0} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextInput source="prezzo_note" label="Note prezzo" type="text" fullWidth />
                    </Grid>
                    <Grid item display='flex' flexDirection='row' gap={2}>
                        <TextInput source="partecipanti_min" label="Numero minimo partecipanti" type="text" fullWidth />
                        <ReferenceInput label="Agenzia" source="id_agenzia" reference="agenzia" filter={{ id_gt: 1 }} sort={{ field: 'nome', order: 'ASC' }}>
                            <SelectInput label="Agenzia" optionText='nome' optionValue="id" validate={[required()]} fullWidth />
                        </ReferenceInput>
                    </Grid>

                    <Grid item>
                        <ReferenceInput label="Stato" source="id_stato_viaggio" reference="stato_viaggio" sort={{ field: 'id', order: 'ASC' }}>
                            <SelectInput label="Stato" optionText='nome' optionValue="id" validate={[required()]} fullWidth defaultValue={1} />
                        </ReferenceInput>
                    </Grid>

                    <Grid item>
                        <BooleanInput source="stato" defaultValue={true} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
                <Box sx={{ mt: 2 }}>
                    <HeaderSezione icona={Image} titolo="Immagine di copertina" dettaglio="Formato 1200 x 630 pixels" />
                    <ImageUploader />
                </Box>

                {/* Programma */}
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownward />} aria-controls="programma-content" id="programma-header">
                        <Typography>Programma</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RichTextEditor campo="programma" valore={formValues.programma} update={setValue} valoreDaCopia={formValues.programma_copiato} />
                    </AccordionDetails>
                </Accordion>

                {/* La quota comprende */}
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownward />} aria-controls="quota_incl-content" id="quota_incl-header">
                        <Typography>La quota comprende</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RichTextEditor campo="quota_incl" valore={formValues.quota_incl} update={setValue} valoreDaCopia={formValues.quota_incl_copiato} />
                    </AccordionDetails>
                </Accordion>

                {/* La quota NON comprende */}
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownward />} aria-controls="quota_escl-content" id="quota_escl-header">
                        <Typography>La quota NON comprende</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RichTextEditor campo="quota_escl" valore={formValues.quota_escl} update={setValue} valoreDaCopia={formValues.quota_escl_copiato} />
                    </AccordionDetails>
                </Accordion>

                {/* Documenti richiesti */}
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownward />} aria-controls="documenti-content" id="documenti-header">
                        <Typography>Documenti richiesti</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RichTextEditor campo="documenti" valore={formValues.documenti} update={setValue} valoreDaCopia={formValues.documenti_copiato} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )
}

const ButtonCopia = ({ ...props }) => {
    const { update } = props
    const [statoDialog, setStatoDialog] = useState(false)
    const [selected, setSelected] = useState(false)
    const { data: viaggio, isLoading, error } = useGetOne('viaggio', { id: selected ?? 0 }, { enabled: selected !== false ? true : false });
    const { data: categorieViaggiAssegnati } = useGetList(
        'viaggio_categoria_assegnazione',
        {
            filter: { id_viaggio: selected ?? 0 },
            pagination: { page: 1, perPage: 20 }
        },
        { enabled: selected !== false ? true : false }
    );
    const maxSearchYears = 2;
    const maxSearchDate = moment().subtract(maxSearchYears, 'years').format("YYYY-MM-DD")

    // useEffect(() => {
    //     console.log(categorieViaggiAssegnati);
    // }, [categorieViaggiAssegnati])

    // useEffect(() => {
    //     console.log(selected);
    // }, [selected])

    // useEffect(() => {
    //     console.log(viaggio);
    // }, [viaggio])

    function handleClick() {
        if (viaggio) {
            // console.log(viaggio);
            for (const [key, value] of Object.entries(viaggio)) {
                if (["programma", "quota_incl", "quota_escl", "documenti"].includes(key)) {
                    update(`${key}_copiato`, value)
                } else if (!["id", "stato", "id_stato_viaggio", "data_inizio", "data_fine", "invio_mail_finale", "creazione", "ultima_modifica"].includes(key)) {
                    // console.log(key, value)
                    update(key, value)
                } else if (key === 'id') {
                    update('id_viaggio', value)
                }
            }
            setStatoDialog(false)
            if (categorieViaggiAssegnati) {
                update("categorie", categorieViaggiAssegnati.map(c => c.id_categoria))
            }
        }
    }

    function suggestion(filter, choice) {
        const searchTerms = slugify(filter, { replacement: ' ', lower: true }).split(" ")
        return searchTerms.every(filtroParola => {
            return choice.titolo.toLowerCase().includes(filtroParola) || choice.descr_breve.toLowerCase().includes(filtroParola);
        });
        // return choice.titolo.toLowerCase().includes(filter.toLowerCase()) || choice.descr_breve.toLowerCase().includes(filter.toLowerCase())
    }

    const SingolaOpzione = () => {
        const record = useRecordContext();
        return (
            <Box sx={{ borderBottom: `1px solid ${myTheme.palette.secondary.light}`, width: '100%', pb: 1 }}>
                <Typography component='p' variant="p" color='secondary.dark' fontWeight='bold'>{`${record.titolo} ${(moment(record.data_inizio).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM/YYYY")}` : ``) + (moment(record.data_fine).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YYYY")}` : ``) + `)`}`}</Typography>
                <Typography component='p' variant="small" mt={0.7} lineHeight={1}>{record.descr_breve}</Typography>
            </Box>
        )
    }

    return (
        <>
            <Button onClick={() => setStatoDialog(true)} {...props}>Copia da</Button>
            <Dialog
                open={statoDialog}
                onClose={() => setStatoDialog(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">Copia i contenuti da un altro viaggio</DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    <Alert severity="info" icon={false} sx={{ mb: 2 }}>
                        <Typography component='p' variant="small">Ricerca massima: {maxSearchYears} anni antecedenti ad oggi</Typography>
                    </Alert>
                    <ReferenceInput source="id_viaggio" reference="viaggio" resettable perPage={9999}>
                        <AutocompleteInput
                            label="Viaggio"
                            // optionText={d => `${d.titolo} ${(moment(d.data_inizio).isValid() ? (d.data_inizio !== d.data_fine && moment(d.data_fine).isValid() ? ` (Dal ` : ` (`) + `${moment(d.data_inizio).format("DD/MM/YYYY")}` : ``) + (moment(d.data_fine).isValid() ? (d.data_inizio !== d.data_fine && moment(d.data_fine).isValid() ? ` al ` : ``) + `${moment(d.data_fine).format("DD/MM/YYYY")}` : ``) + `)\raaa`}`}
                            filterToQuery={txt => ({
                                // titolo_cs: txt,
                                data_inizio_gt: maxSearchDate
                            })}
                            optionText={<SingolaOpzione />}
                            optionValue="id"
                            inputText={d => `${d.titolo}`}
                            matchSuggestion={suggestion}
                            suggestionLimit={999}
                            sx={{ pt: 4 }}
                            onChange={d => setSelected(d)}
                            data-variant="copiaviaggio"
                        />
                    </ReferenceInput>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatoDialog(false)}>Annulla</Button>
                    <Button onClick={handleClick} autoFocus variant="contained">Copia</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const ImageUploader = () => {
    return (
        <ImageInput
            source="img"
            label="Carica un'immagine"
            accept="image/*"
            placeholder={
                <IconButton variant="contained" size='large' disabled={false} color='primary' sx={{ borderWidth: 1, borderStyle: 'solid', mb: 3 }}><CloudUpload sx={{ fontSize: 50 }} /></IconButton>
            }
            maxSize={4000000}
        >
            <ImageField source="src" title="Copertina viaggio" sx={{ mb: { xs: 3, lg: 2 }, '& img': { width: '50% !important', height: 'auto !important' } }} />
        </ImageInput>
    )
}

export const ViaggioNuovo = () => {
    const idUtente = localStorage.getItem('auth_uid');
    const redirect = useRedirect();
    const notify = useNotify();
    const [create, { isLoading, error }] = useCreate();
    var imgToSend = null;

    const onSuccess = async (data) => {
        const { id } = data
        logger(23, null, null, id, null, idUtente);
        if (imgToSend) {
            await axios.post(`${apiFullUrl}/upload_img_viaggio/${id}`, {
                img: imgToSend
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-API-Key': localStorage.getItem('auth_api_key')
                }
            })
                .catch(function (error) {
                    notify(`Non è stato possibile caricare l'immagine`, { type: 'error' });
                })
        }
        if (data.categorie) {
            await data.categorie.map(async (v) => {
                await create('viaggio_categoria_assegnazione', {
                    data: {
                        id_viaggio: id,
                        id_categoria: v
                    }
                })
                return null
            })
        }
        redirect('list', 'viaggio');
        notify(`Viaggio creato`, { type: 'success' });
    };

    const transform = data => {
        // console.log(data.img, typeof data.img);
        // console.log(data);
        imgToSend = typeof data.img === 'object' ? data.img.rawFile : null
        const prezziDaControllare = [
            "prezzo_prec",
            "prezzo",
            "acconto",
            "prezzo_bambini_2",
            "suppl_singola",
        ]
        const prezziOk = prezziDaControllare.map(v => {
            if (typeof data[v] === 'string') {
                if (data[v].trim() === '')
                    return { campo: v, val: 0 }
                else if (data[v].includes(','))
                    return { campo: v, val: parseFloat(data[v].replace(",", ".")) }
            } else if (!data[v]) {
                return { campo: v, val: 0 }
            }
            return { campo: v, val: parseFloat(data[v]) }
        })
        return {
            ...data,
            img: !imgToSend ? data.img : null,
            img_intro: null,
            prezzo_prec: prezziOk.find(v => v.campo === "prezzo_prec").val,
            prezzo: prezziOk.find(v => v.campo === "prezzo").val,
            acconto: prezziOk.find(v => v.campo === "acconto").val,
            prezzo_bambini_2: prezziOk.find(v => v.campo === "prezzo_bambini_2").val,
            suppl_singola: prezziOk.find(v => v.campo === "suppl_singola").val,
            programma: `${data.programma ?? ''}`,
            documenti: `${data.documenti ?? ''}`,
            quota_incl: `${data.quota_incl ?? ''}`,
            quota_escl: `${data.quota_escl ?? ''}`,
            partenza: `${data.partenza ?? ''}`,
            stato: data.stato ? 1 : 0
        }
    };

    const onError = (error) => {
        notify(`${error.message}`, { type: 'error' });
    }
    // React.useEffect(() => {
    //     console.log(temp);
    // }, [temp]);

    return (
        <Create
            resource="viaggio"
            title="Nuovo viaggio"
            mutationOptions={{ onSuccess, onError }}
            transform={transform}
            sx={{ pb: 8 }}
        >
            <SimpleForm>
                <Grid container display='flex' flexDirection='row'>
                    <Grid item>
                        <HeaderSezione icona={Luggage} titolo="Crea un nuovo viaggio" />
                    </Grid>
                </Grid>
                <CustomForm />
            </SimpleForm>
        </Create>
    )
};