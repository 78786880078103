import { useEffect, useState } from "react";
import { List, Datagrid, TextField, ReferenceField, Button, CheckboxGroupInput, FunctionField, usePermissions, useListContext, useRecordContext, TextInput, ReferenceInput, SelectInput, useStore, useRedirect, useDataProvider, useTheme } from 'react-admin';
import { admB64 } from "../generale";
import { Tooltip, IconButton, Box, useMediaQuery } from '@mui/material';
import { FindInPage, Verified } from '@mui/icons-material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import moment from 'moment';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import { TastoGeneraPdf } from './ViaggiListaPdf';
import { encode as base64_encode } from 'base-64';

export const ViaggiArchivioList = () => {
    const [theme, setTheme] = useTheme();
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    // const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: localStorage.getItem('auth_uid') } : {});
    const permFilter = { stato: 0 };
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    // const [viaggioAttivo, setViaggioAttivo] = useStore('viaggio_attivo', null);
    const record = useRecordContext();
    const redirect = useRedirect();
    const link = document.createElement('a');
    link.target="_blank";
    link.rel="noreferrer";

    const filtersAdmin = [
        <TextInput source="id_sw" label="Cerca per ID" alwaysOn resettable />,
        <TextInput source="titolo_cs" label="Cerca per titolo" alwaysOn resettable />,
        <ReferenceInput source="id_stato_viaggio" reference="stato_viaggio" alwaysOn resettable>
            <SelectInput label="Stato" optionText="nome" optionValue="id" />
        </ReferenceInput>,
        <CheckboxGroupInput alwaysOn resettable source="n_confermati_ge" choices={[{ id: 1, name: 'Con partecipanti confermati' }]} />
    ];

    const handleVistaRichiesteViaggio = ({ id }) => {
        // console.log(id);
        redirect(`/richiesta/viaggio/${id}`);
    }

    return (
        <>
            <List resource="viaggio_dettagli" title='Viaggi Archiviati' filter={permFilter} filters={(permissions === base64_encode(admB64) ? filtersAdmin : null)} sort={{ field: 'id', order: 'DESC' }} perPage={50} exporter={false} empty={<EmptyPage testo='viaggi' />} disableSyncWithLocation>
                <Datagrid bulkActionButtons={false}>
                    {permissions === base64_encode(admB64) && !isSmall ?
                        <TextField source="id" label="ID" sortable={false} />
                        : null}
                    <FunctionField sortable={false} render={record => (
                        (record.titolo !== null && record.titolo !== '' ? `${record.titolo}` : ``) +
                        (moment(record.data_inizio).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM/YYYY")}` : ``) +
                        (moment(record.data_fine).isValid() ? (record.data_inizio !== record.data_fine && moment(record.data_fine).isValid() ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YYYY")}` : ``) +
                        `)`
                    )} label="Viaggio" />
                    <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Stato" link={false}>
                        <TextField source="nome" />
                    </ReferenceField>
                    <FunctionField textAlign="center" sortable={true} render={record => (
                            record.n_confermati > 0 ? record.n_confermati : '-'
                        )} 
                        // label="Partecipanti confermati"
                        color={theme.palette.success[700]}
                        label={<Tooltip title="Confermati"><Verified color="success" /></Tooltip>}
                        sx={{ fontWeight: 'bold'}}
                    />
                    {/* <TextField source="n_da_validare" />
                    <TextField source="n_validi" />
                    <TextField source="n_confermati" />
                    <TextField source="n_invalidati" /> */}
                    <FunctionField sortable={false} render={record => (
                        <Box display='flex' flexDirection='row'>
                        <Tooltip title={`Lista richieste`}>
                            <IconButton variant="iconaLista" color="secondary" onClick={() => handleVistaRichiesteViaggio({ id: record.id })}><FindInPage /></IconButton>
                        </Tooltip>
                        {permissions === base64_encode(admB64) && ( (record.n_confermati + record.n_invalidati) > 0 )
                        ?
                        <>
                            <TastoGeneraPdf viaggio={record} key={`pdf_${record.id}_f`} versione="Finanze" />
                            <TastoGeneraPdf viaggio={record} key={`pdf_${record.id}_r`} versione="Riepilogo" />
                        </>
                        : null}
                        </Box>
                    )} />


                </Datagrid>
            </List>
        </>
    )
};