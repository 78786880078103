import { useEffect, useState } from "react";
import { useGetList } from "react-admin";
import { Alert } from "@mui/material";
import { LoadingComponent } from "../LoadingComponent";
import { CheckTreePicker } from 'rsuite';
import 'rsuite/CheckTreePicker/styles/index.css';
// import 'rsuite/dist/rsuite.min.css';
import './rsuite-override.css';

// https://rsuitejs.com/components/check-tree-picker/

export const CategorieViaggio = ({ update, id_viaggio = null }) => {
    const [alberoCategorie, setAlberoCategorie] = useState([]);
    const [vociAttive, setVociAttive] = useState(null);
    const [idDaEliminare, setIdDaEliminare] = useState([]);
    const { data: categorie, isLoading, error } = useGetList('categoria_viaggio', {
        pagination: { page: 1, perPage: 99 },
        sort: { field: 'id', order: 'ASC' }
    });
    const { data: categorieAttive, isLoading: isLoadingCatAtt, error: errorCatAtt } = useGetList(
        'viaggio_categoria_assegnazione',
        {
            pagination: { page: 1, perPage: 99 },
            filter: (id_viaggio !== null ?
                { id_viaggio: id_viaggio }
                :
                { id_gt: 0 }
            )
        }, {
            enabled: id_viaggio !== null
        }
    );

    useEffect(() => {
        if (categorie) {
            const categoriePrincipali = categorie.filter(v => !v.id_categoria_genitore)
            const tempArray = categoriePrincipali.map(v => {
                const subCats = categorie.filter(c => c.id_categoria_genitore === v.id)
                return subCats.length > 0 ? { ...v, sottocategorie: subCats } : v
            })
            setAlberoCategorie(tempArray)
        }
    }, [categorie])

    function categorieSottocategorieAttive() {
        // console.log(categorie);
        // console.log(vociAttive);
        if (categorie) {
            const categorieGenitoreAttive = categorie.filter(v => vociAttive.includes(v.id)).map(v => v.id_categoria_genitore);
            const categorieSottocategorieAttive = [...vociAttive, ...categorieGenitoreAttive]
            return categorieSottocategorieAttive.filter((item, index) => categorieSottocategorieAttive.indexOf(item) === index && item);
        }
        return null
    }

    useEffect(() => {
        if (categorieAttive && categorieAttive.length > 0) {
            const id_da_eliminare = categorieAttive.map(v => v.id)
            // console.log(id_da_eliminare);
            setVociAttive(categorieAttive.map(v => v.id_categoria))
            setIdDaEliminare(id_da_eliminare)
        } else {
            setVociAttive([])
        }
    }, [categorieAttive])

    useEffect(() => {
        if (vociAttive) {
            const tutteLeCategorie = categorieSottocategorieAttive()
            // console.log("vociAttive", vociAttive);
            // console.log(tutteLeCategorie);
            update("categorie", tutteLeCategorie)
        }
    }, [vociAttive])

    function handleChange(e) {
        // console.log(e.check, e.id);
        update('id_da_eliminare', idDaEliminare)
        if (e.check === true) {
            setVociAttive([...vociAttive, e.id])
        } else {
            setVociAttive(vociAttive.filter(v => v !== e.id))
        }
    }

    if (isLoading || alberoCategorie.length === 0 || isLoadingCatAtt || vociAttive === null) { return <LoadingComponent />; }
    if (error || errorCatAtt) { return <Alert severity="error">Impossibile caricare le categorie</Alert>; }
    return (
        <CheckTreePicker
            // defaultExpandAll
            cascade={false}
            data={alberoCategorie}
            valueKey='id'
            labelKey='nome'
            childrenKey='sottocategorie'
            // onSelect={e => console.log('onSelect', e)}
            // onChange={e => console.log('onChange', e)}
            onSelect={e => handleChange(e)}
            placeholder='Seleziona categoria/e'
            value={vociAttive}
        />
    )
}