import { useEffect, useState } from "react";
import { CreateBase, SimpleForm, TextInput, Toolbar, SaveButton, required, usePermissions, useNotify, useRefresh, AutocompleteInput } from 'react-admin';
import { Grid, IconButton } from '@mui/material';
import { Close, EuroSymbol } from "@mui/icons-material";
import { LoadingComponent } from '../LoadingComponent';
import { HeaderSezione, admB64 } from "../generale";
import { useFormContext } from 'react-hook-form';
import { encode as base64_encode } from 'base-64';

const NuovoPagamentoOffline = ({id_utente, id_richiesta, update}) => {
    const { permissions } = usePermissions();
    // console.log(id_utente, id_richiesta);
    const { reset } = useFormContext();
    const notify = useNotify();
    const refresh = useRefresh();
    // const [create, { isLoading: isLoadingCreate, error }] = useCreate();
    // const [importiPagamenti, setImportiPagamenti] = useState(0);
    // const [ppResp, setPpResp] = useState(null);
    // const [titoloViaggioPP, setTitoloViaggioPP] = useState(null);
    // const record = useRecordContext();
    // const idUtente = localStorage.getItem('auth_uid');
    // const { data: dettagliPagamenti, isLoading: loadingPagamenti, error: errorPagamenti } = useGetManyReference(
    //     'richiesta_pagamento',
    //     { 
    //         target: 'id_richiesta',
    //         id: record.id,
    //         filter: { tipo_bt: [1,2] },
    //         pagination: { page: 1, perPage: 99 },
    //         sort: { field: 'id', order: 'ASC' }
    //     }
    // );
    // const { data: viaggio, isLoading: loadingViaggio, error: errorViaggio } = useGetOne( 'viaggio', { id: id_viaggio });
    // const { data: agenzia, isLoading: loadingAgenzia, error: errorAgenzia } = useGetOne( 'agenzia', { id: viaggio.id_agenzia, enabled: !loadingViaggio && viaggio.length > 0 });

    // useEffect(() => {
    //     setImportiPagamenti(0);
    //     if (dettagliPagamenti) {
    //         dettagliPagamenti.map((v,i) => {
    //             // console.log(v);
    //             setImportiPagamenti(prev => 
    //                 prev + parseFloat(v.importo)
    //             );
    //             return ;
    //         })
    //     }
    // }, [dettagliPagamenti]);

    // useEffect(() => {
    //     if (ppResp && ppResp.id !== 0) {
    //         // console.log(ppResp);
    //         dataProvider.create(
    //             'richiesta_pagamento', {
    //                 data: {
    //                     id_richiesta: record.id,
    //                     id_utente: idUtente,
    //                     metodo: 'PayPal',
    //                     importo: ppResp.value,
    //                     pp_payment_id: ppResp.id,
    //                     tipo: ppResp.tipo,
    //                 },
    //                 onSuccess: (data) => {
    //                     notify('Pagamento ricevuto', { type: 'success' });
    //                 },
    //                 onSettled: (data, error) => {
    //                     notify('Pagamento ricevuto', { type: 'warning' });
    //                 }
    //             },
    //         );
    //     } else if (ppResp && ppResp.id === 0) {
    //         dataProvider.create(
    //             'richiesta_pagamento', {
    //                 data: {
    //                     id_richiesta: record.id,
    //                     id_utente: idUtente,
    //                     metodo: 'PayPal',
    //                     tipo: 0,
    //                 }
    //             }
    //         );
    //     }
    //     setPpResp(null);
    //     refresh();
    // }, [ppResp]);

    // useEffect(() => {
    //     if (viaggio) {
    //         setTitoloViaggioPP((viaggio.titolo !== null && viaggio.titolo !== '' ? `${viaggio.titolo}` : ``) +
    //         (viaggio.data_inizio !== null && viaggio.data_inizio !== '' ? (viaggio.data_inizio !== viaggio.data_fine && viaggio.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(viaggio.data_inizio).format("DD/MM/YYYY")}` : ``) +
    //         (viaggio.data_fine !== null && viaggio.data_fine !== '' && viaggio.data_fine !== '0000-00-00' ? (viaggio.data_inizio !== viaggio.data_fine && viaggio.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(viaggio.data_fine).format("DD/MM/YYYY")}` : ``) +
    //         `)`);
    //     }
    // }, [viaggio]);

    // if (loadingPagamenti || loadingViaggio || loadingAgenzia) return <LoadingComponent />;
    // if (errorPagamenti || errorViaggio || errorAgenzia) return <p>Errore</p>;
    // return null;

    const onSuccess = () => {
        update(false);
        notify('Pagamento registrato', { type: 'success' });
        reset();
        refresh();
    };

    return (
        <>
            {/* Cambiare in permissions !== base64_encode(admB64) */}
            {permissions === base64_encode(admB64) ? 
            <>
            <CreateBase resource="richiesta_pagamento" mutationOptions={{ onSuccess }}>
                <SimpleForm
                    defaultValues={{
                        id_utente,
                        id_richiesta,
                        // metodo: 'Bonifico',
                        tipo: 1
                    }}
                    toolbar={
                        <Toolbar sx={{ justifyContent: 'end', backgroundColor: 'transparent', padding: '0 !important' }}>
                            <SaveButton color="primary" label="Salva pagamento" sx={{ width: 'auto', mx: 2 }} icon={false} />
                        </Toolbar>
                    }
                    sx={{ p: 0 }}
                >
                    <HeaderSezione icona={EuroSymbol} titolo="Inserisci un pagamento offline" />
                    <Grid container spacing={2} mt={0} px={2}>
                        <Grid item xs={12} sm={12}>
                            <IconButton
                                aria-label="toggle visibility"
                                onClick={() => update(false)}
                                edge="end"
                                size="small"
                                variant="contained"
                                sx={{ position: 'absolute', right: 10, top: 10, outline: "0 !important", borderWidth: 1, borderStyle: 'solid' }}
                            >
                                <Close />
                            </IconButton>
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg>
                                    <TextInput source="importo" label="Importo" type="text" validate={[required()]} fullWidth sx={{width: '100%'}} />
                                </Grid>
                                <Grid item xs={12} lg>
                                    <AutocompleteInput
                                        fullWidth
                                        source="metodo"
                                        label="Metodo di pagamento"
                                        optionText="nome"
                                        optionValue="id"
                                        validate={[required()]}
                                        choices={[
                                            { id: 'Bonifico', nome: 'Bonifico bancario' },
                                            { id: 'Contanti', nome: 'Contanti' },
                                            { id: 'Carta di credito', nome: 'Carta di credito' },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </CreateBase>
            
            </>
            :null}
        </>
    )
}

export default NuovoPagamentoOffline;