import { useGetList, useGetOne } from "react-admin";
import { useGetFermate } from "./useGetFermate";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { htmlDecode } from "../generale";

export const useGetFermateRichiesta = ({ idRichiesta, idViaggio }) => {
    const [fermateRichiesta, setFermateRichiesta] = useState(null)
    const [fermateRichiestaTesto, setFermateRichiestaTesto] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [getData, { listaFermate, isLoadingListaFermate, errorListaFermate }] = useGetFermate()
    const { data: viaggio, isLoading: isLoadingViaggio, error: errorViaggio } = useGetOne('viaggio', { id: idViaggio });
    const { data: listaFermateRichiesta, isLoading: isLoadingListaFermateRichiesta, error: errorListaFermateRichiesta } = useGetList(
        'richiesta_dettagli',
        {
            pagination: { page: 1, perPage: 99 },
            sort: { field: 'id', order: 'ASC' },
            filter: { id_richiesta_eq: idRichiesta }
        }
    );

    useEffect(() => {
        if (listaFermate && viaggio && listaFermateRichiesta) {
            // console.log(listaFermate, viaggio, listaFermateRichiesta);
            const idSingoleFermate = listaFermateRichiesta.map(v => v.id_fermata).filter((value, index, self) => self.indexOf(value) === index).filter(v => v);
            const fermateViaggio = JSON.parse(viaggio.partenza)
            const fermate = (() => {
                const tmp = fermateViaggio.filter(fv => idSingoleFermate.includes(fv.id))
                return tmp.map(x1 => {
                    return {
                        ...x1,
                        gps: listaFermate.find(x2 => x2.id === x1.id).gps,
                        dettagli: listaFermate.find(x2 => x2.id === x1.id).dettagli
                    }
                })
            })()
            setFermateRichiesta(fermate)
        }
    }, [listaFermate, viaggio, listaFermateRichiesta])

    useEffect(() => {
        if (isLoadingListaFermate || isLoadingViaggio || isLoadingListaFermateRichiesta)
            setIsLoading(true)
        else
            setIsLoading(false)
    },[isLoadingListaFermate, isLoadingViaggio, isLoadingListaFermateRichiesta])

    useEffect(() => {
        if (errorListaFermate || errorViaggio || errorListaFermateRichiesta)
            setError(true)
        else
            setError(false)
    },[errorListaFermate, errorViaggio, errorListaFermateRichiesta])

    useEffect(() => {
        if (fermateRichiesta && !isLoading && !error) {
            const tmp = fermateRichiesta.map(ff => {
                return (
                    <li key={ff.id} style={{ marginBottom: 10 }}>
                        <Typography component='p' variant="p" sx={{ fontWeight: 'bold' }}>{htmlDecode(`➡️ Ore ${ff.orario}: ${ff.localita}`)}</Typography>
                        {ff.dettagli !== '' ? 
                        <Typography component='p' variant="p">{htmlDecode(` (${ff.dettagli})`)}</Typography>
                        :null}
                        <Typography component='p' variant="p">
                        <a href={`https://www.google.com/maps?q=${ff.gps}`}>Guarda sulla mappa</a>
                        </Typography>
                    </li>
                )
            })
            setFermateRichiestaTesto(tmp)
        }
    }, [fermateRichiesta, isLoading, error])

    return { fermateRichiesta, fermateRichiestaTesto, isLoading, error }
}