import { useState, useEffect } from "react";
import { useGetList, useGetMany, useReference } from 'react-admin';
import { Typography, Button } from '@mui/material';
import { LoadingComponent } from '../LoadingComponent';

export const AccompagnatoriListBaseCheckbox = ({ updateVal, forceVal = null }) => {
    const { data, total, isLoading, error } = useGetList(
        'accompagnatore',
        {
            pagination: { page: 1, perPage: 99 },
            sort: { field: 'nominativo', order: 'ASC' },
            filter: { stato: 1 }
        }
    );
    const [selezionati, setSelezionati] = useState([]);

    useEffect(() => {
        if (forceVal) {
            // console.log(typeof forceVal, forceVal);
            const sel = data.filter(v => {
                if (forceVal.split(',').map( Number ).includes(parseInt(v.id)))
                return v
                return null
            });
            // console.log(sel);
            setSelezionati(sel);
        }
    }, [forceVal]);

    function handleChange(obj, index) {
        // console.log('change', obj.nominativo);
        const check = selezionati.some(el => {
            if (el.id === obj.id) {
                return true;
            } else {
                return false;
            }
        });
        // console.log(obj.nominativo, check);
        if (!check) {
            setSelezionati(current => [...current, obj]);
            data[index].active = 1;
        } else {
            setSelezionati(selezionati.filter(item => item.id !== obj.id))
            data[index].active = 0;
        }
    }

    // useEffect(() => {
    //     console.log('selezionati', selezionati);
    //     if (selezionati.length > 0) {
    //         const testo = selezionati.map((v, i) => {
    //             return `${v.nominativo} (Tel. ${v.prefisso} ${v.telefono})\n`
    //         }).join('');
    //         // console.log(testo);
    //         update(`👥 ${
    //             selezionati.length === 1 ?
    //             'CAPOGRUPPO\nPer questo viaggio il vostro capogruppo di riferimento sarà:'
    //             :
    //             'CAPIGRUPPO\nPer questo viaggio i vostri capigruppo di riferimento saranno:'
    //             }\n${testo}`);
    //     } else {
    //         update('');
    //     }
    // }, [selezionati])

    useEffect(() => {
        // console.log('selezionati', selezionati);
        if (selezionati.length > 0) {
            updateVal(selezionati.map(v => v.id).join(','));
        } else {
            updateVal(null);
        }
    }, [selezionati]);

    if (isLoading) { return <LoadingComponent />; }
    if (error) { return <p>ERROR</p>; }

    return (
        total === 0 ?
            <p>Non ci sono capogruppo disponibili</p>
            :
            <>
                <Typography component="p" variant="b" mb={1}>Seleziona i capigruppo</Typography>
                {data.map((v, i) => {
                    return (
                        <Button
                            key={i}
                            size="small"
                            sx={{ mr: 1, mb: 1 }}
                            onClick={() => handleChange(v, i)}
                            variant={ selezionati.find(({id}) => id === v.id) ? "contained" : "outlined"}
                            color={ selezionati.find(({id}) => id === v.id) ? "success" : "secondary"}
                            // variant={v.active ? "contained" : "outlined"}
                            // color={v.active ? "success" : "primary"}
                        >
                            {v.nominativo}
                        </Button>
                    )
                })}
            </>
    )
};