import { useEffect, useState } from "react";
import { IconButton, Dialog, DialogContent, Tooltip, Button } from "@mui/material";
import { Assignment, TimerOff, Warning } from "@mui/icons-material";
import { HeaderSezione } from "../generale";

export const ButtonInfoFermate = ({ action, versione = 'icona' }) => {
    if (versione === 'icona')
    return <IconButton variant="iconaLista" color="secondary" onClick={() => action(true)}><Assignment /></IconButton>
    else if (versione === 'estesa')
    return <Button variant="outlined" color="secondary" onClick={() => action(true)} startIcon={<Assignment />} sx={{ gap: 1 }}>Lista fermate</Button>
}

export const InfoFermate = ({ dettaglioFermate, versioneTasto }) => {
    const [modalInfo, setModalInfo] = useState(false)

    return (
        <>
            <ButtonInfoFermate action={setModalInfo} versione={versioneTasto} />
            <Dialog open={modalInfo} onClose={() => setModalInfo(false)} >
                <DialogContent>
                    <HeaderSezione icona={Assignment} titolo="Lista fermate abilitate" dettaglio="" />
                    <ul style={{ paddingLeft: 0 }}>
                    {dettaglioFermate?.map(v => <li key={v.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4, marginBottom: 10 }}>{v.orario !== '' ? `Ore ${v.orario} - ` : <Tooltip title={`Non è stato definito l'orario di partenza per la fermata di ${v.localita}`}><TimerOff color="error" /></Tooltip>}{v.localita}{v.facoltativa === true ? <Tooltip title="Fermata facoltativa"><Warning color="secondary" /></Tooltip> : null}</li>)}
                    </ul>
                </DialogContent>
            </Dialog>
        </>
    )
}