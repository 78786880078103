import React, { useState, useEffect, memo } from "react";
import { Create, SimpleForm, TextInput, required, CheckboxGroupInput, usePermissions, SelectInput, Link, SaveButton, AutocompleteInput, DateInput, ReferenceInput, ReferenceField, useCreate, useUpdate, useNotify, useRedirect, useReference, Toolbar, useRecordContext, useGetOne, useRefresh } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Grid, Alert, AlertTitle, Snackbar, Button, Card, CardContent, Typography, Divider, TextField, Box, InputAdornment, Link as LinkMui } from '@mui/material';
import { TextSnippet, Luggage, EuroSymbol, Bed, Bookmark, Settings, People, WhatsApp, BusAlert, Sms } from '@mui/icons-material';
import { valutaFormat, logger, HeaderSezione, admB64, myTheme, AlertWhatsapp, AlertFermata, listaFermate, apiGuestFullUrl } from '../generale';
import { LoadingComponent } from '../LoadingComponent';
import moment from 'moment';
import { useSearchParams } from "react-router-dom";
import { encode as base64_encode } from 'base-64';
import FsLightbox from "fslightbox-react";
import axios from 'axios';
// import { useFormContext } from "react-hook-form";
// import { object } from "prop-types";
import { ViaggioPreview } from '../Viaggi/ViaggioPreview';
import Preventivo from './Preventivo';
import { LoginForm } from "../../Login";
import { ListaFermateUtente } from "./ListaFermateUtente";

const StileAttesa = () => {
    import("../Richieste/attesa.css");
    return
}

// const SetupIniziale = ({ init = null }) => {
//     const record = useRecordContext();
//     if (record) {
//         if (init)
//             init(record);
//     }
// };

const RichiestaNuovaForm = () => {
    const { idViaggio } = useParams();
    // const [storing, setStoring] = useStore('viaggioAttivo', idViaggio);
    const [create, { isLoading, error }] = useCreate();
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();
    const { permissions } = usePermissions();
    const idUtente = localStorage.getItem('auth_uid');
    const [partecipanti, setPartecipanti] = useState({ adulti: 0, bambini_3: 0, bambini_4: 0 });
    const [totPartecipanti, setTotPartecipanti] = useState(0);
    const [postiLetto, setPostiLetto] = useState({ singole: 0, doppie: 0, matrimoniali: 0, triple: 0, quadruple: 0 });
    const [totPostiLetto, setTotPostiLetto] = useState(0);
    // const [totPrezzo, setTotPrezzo] = useState(0);
    // const [dettagliUtente, setDettagliUtente] = useState({ nominativo: null, telefono: null, cod_fisc: null, comune: null, indirizzo: null, data_nascita: null, comune_nascita: null });
    const [datiPreventivo, setDatiPreventivo] = useState({});
    const [choicesBambini2, setChoicesBambini2] = useState(null);
    const [erroriValidazione, setErroriValidazione] = useState({});
    const [searchParams] = useSearchParams();
    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});

    function openLightboxOnSlide(number) {
        // console.log(accettazioni[(number - 1)].link);
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		});
	}
    // const idViaggio = searchParams.get('vid') || viaggioAttivo;
    const [accettazioni, setAccettazioni] = useState([
        {
            id: 1,
            link: null,
            testoLink: null,
            sourceName: 'maggiorenne',
            testo: 'Dichiaro di essere maggiorenne',
            richiesto: true
        }, {
            id: 1,
            link: process.env.PUBLIC_URL + '/docs/informativa-privacy.pdf',
            testoLink: "Leggi",
            sourceName: 'privacy',
            testo: 'Ho preso visione dell\'informativa sul trattamento dei dati personali e ne accetto le condizioni',
            richiesto: true
        }, {
            id: 1,
            link: process.env.PUBLIC_URL + '/docs/termini-e-condizioni.pdf',
            testoLink: "Leggi",
            sourceName: 'termini1',
            testo: 'Accetto i termini e le condizioni d\'uso del servizio',
            richiesto: true
        },
    ]);
    const { data: utente, isLoading: isLoadingUtente, error: errorUtente } = useGetOne('utenti_attivi', { id: idUtente });

    // Verificare questa richiesta perchè non funziona correttamente. Basta farsi dare in risposta il campo "specifica_tipo" senza tutti gli altri
    const { referenceRecord: dettagliViaggio, isLoading: isLoadingDettagliViaggio, error: errorDettagliViaggio } = useReference({
        reference: 'viaggio', id: idViaggio
    });

    // const { referenceRecord: dettagliAgenzia, isLoading: isLoadingDettagliAgenzia, error: errorDettagliAgenzia } = useReference({
    //     reference: 'agenzia', id: dettagliViaggio.id_agenzia
    // });

    const onSuccess = async ({ id }) => {
        const idRichiesta = id;
        logger(7, '', idUtente, idViaggio, idRichiesta, null);
        notify("Richiesta inviata con successo.", { type: 'success' });
        redirect("/richiesta");
    }

    const validazioneForm = (v) => {
        // console.log(v);
        const errors = {};
        if (['', null, undefined].indexOf(v.nome) > -1)
            errors.nome = 'Verifica la correttezza del nome';
        if (['', null, undefined].indexOf(v.cognome) > -1)
            errors.cognome = 'Verifica la correttezza del cognome';
        if (['', null, undefined].indexOf(v.telefono) > -1)
            errors.telefono = 'Verifica di aver inserito un numero di telefono valido';
        if (['', null, undefined].indexOf(v.id_fermata) > -1)
            errors.id_fermata = 'Verifica di aver selezionato la fermata principale';
        if (['', null, undefined].indexOf(v.comune_nascita) > -1)
            errors.comune_nascita = 'Verifica di aver inserito il comune di nascita';
        if (['', null, undefined].indexOf(v.data_nascita) > -1)
            errors.data_nascita = 'Verifica di aver selezionato la data di nascita';
        if (partecipanti.adulti + partecipanti.bambini_4 === 0)
            errors.partecipanti = 'Verifica di aver inserito almeno un partecipante';
        // if (totPartecipanti === 0)
        //     errors.partecipanti = 'Verifica di aver inserito almeno un partecipante';

        const accettazionicheck = accettazioni.map((opt) => {
            let err = 0;
            // console.log(opt);
            // console.log(v[opt.sourceName].length);
            if (['', null, undefined, 0].indexOf(v[opt.sourceName]) > -1 || v[opt.sourceName].length <= 0) err = 1
            return err
        }).reduce((t,v) => t + v, 0)

        if (accettazionicheck > 0) errors.accettazioni = 'Verifica di aver accettato tutte le condizioni e le informative'

        if (dettagliViaggio.specifica_tipo === 2) {
            if (totPostiLetto === 0)
                errors.camere = 'Verifica di aver inserito almeno un posto letto';
            if (totPartecipanti !== totPostiLetto)
                errors.differenzaPostiLetto = 'Il numero dei partecipanti non coincide con il numero dei posti letto selezionati';
        }
        setTimeout(() => {
            setErroriValidazione(errors);
            // console.log(errors);
        }, 200);
        return errors;
    }

    useEffect(() => {
        let tot = 0,
            choichesB2 = [];
        Object.keys(partecipanti).map((k) => {
            if (k !== 'bambini_2' && k !== 'bambini_1')
                tot += partecipanti[k];
            return null;
        })
        setTotPartecipanti(tot);
        if (partecipanti['bambini_1'] > 0) {
            for (let i = 1; i <= partecipanti['bambini_1']; i++) {
                choichesB2.push( { id: i, name: `${i}` } );
            }
            setChoicesBambini2(choichesB2)
        } else {
            setChoicesBambini2(null)
        }
        // console.log(partecipanti);
    }, [partecipanti]);

    useEffect(() => {
        // console.log("Partecipanti: " + totPartecipanti);
        if (dettagliViaggio) {
            setDatiPreventivo({
                prezzo: dettagliViaggio.prezzo,
                prezzo_bambini_2: dettagliViaggio.prezzo_bambini_2,
                suppl_singola: dettagliViaggio.suppl_singola,
                n_singole: postiLetto.singole,
                acconto: dettagliViaggio.acconto,
                sconto: {
                    perc: 0,
                    amm: 0
                },
                partecipanti,
                totPartecipanti
            });
        }
    }, [partecipanti, totPartecipanti, postiLetto]);

    useEffect(() => {
        let tot = 0;
        Object.keys(postiLetto).map((k) => {
            tot += postiLetto[k];
        })
        setTotPostiLetto(tot);
    }, [postiLetto]);

    // useEffect(() => {
    //     console.log('validazioneForm', erroriValidazione, Object.keys(erroriValidazione).length);
    // }, [erroriValidazione]);

    useEffect(() => {
        if(dettagliViaggio && accettazioni && accettazioni.length < 4) {
            axios.get(apiGuestFullUrl + '/records/agenzia/' + (dettagliViaggio.id_agenzia))
            .then(res => {
                const newAccettazioni = [...accettazioni, {
                    id: 1,
                    link: (res.data.link_condizioni).includes('https://') ? res.data.link_condizioni : process.env.PUBLIC_URL + '/docs/termini-e-condizioni.pdf',
                    testoLink: "Leggi",
                    sourceName: 'termini2',
                    testo: 'Accetto le condizioni generali del contratto di vendita di pacchetti turistici',
                    richiesto: true
                }];
                setAccettazioni(newAccettazioni)
            });
        }
    }, [dettagliViaggio]);

    // useEffect(() => {
    //     console.log(accettazioni);
    // }, [accettazioni]);

    // useEffect(() => {
    //     console.log("Posti letto: " + totPostiLetto);
    // }, [totPostiLetto]);

    if (isLoadingDettagliViaggio || isLoadingUtente) return <LoadingComponent />;
    if (errorDettagliViaggio || errorUtente) return null;
    // console.log(idViaggio);
    // console.log(dettagliViaggio);
    // console.log(dettagliViaggio.prezzo_prec, dettagliViaggio.prezzo);

    // const transform = data => ({
    //     ...data,
    //     privacy: (data.chk_privacy ? data.chk_privacy[0] : 0),
    //     termini1: (data.chk_termini1 ? data.chk_termini1[0] : 0),
    //     termini2: (data.chk_termini2 ? data.chk_termini2[0] : 0)
    // });

    return (
        dettagliViaggio.stato === 1 ?
        <Create
            resource="richiesta"
            title="Invia una nuova richiesta"
            mutationOptions={{ onSuccess }}
            disableAuthentication
            sx={{
                "& .RaCreate-main > .MuiPaper-root": {
                    overflow: 'visible',
                    boxShadow: 'none !important',
                    border: 0
                },
                "& form > .MuiCardContent-root": {
                    padding: 0
                }
            }}
        >
            <SimpleForm
                defaultValues={{
                    id_utente: idUtente,
                    id_viaggio: idViaggio,
                    prezzo_prec: parseFloat(dettagliViaggio.prezzo_prec) || null,
                    prezzo: parseFloat(dettagliViaggio.prezzo),
                    prezzo_bambini_2: parseFloat(dettagliViaggio.prezzo_bambini_2)
                }}
                toolbar={
                    <Toolbar variant="big_cta" sx={{ p: "0 !important", backgroundColor: 'transparent' }}>
                        <SaveButton
                            color="primary"
                            label="Invia richiesta"
                            icon={false}
                            disabled={Object.keys(erroriValidazione).length > 0 ? true : false}
                        />
                    </Toolbar>
                }
                mode="all"
                reValidateMode="onChange"
                validate={validazioneForm}
                // validate={(dettagliViaggio.specifica_tipo === 2 ? validazioneForm : null)}
            >
                <Grid container spacing={2}>

                    {/* Dati del referente */}
                    <Grid item xs={12} sx={{ pt: "0 !important" }} variant='bloccoSingoloRichiesta'>
                        <Card>
                            <CardContent sx={{ p:0 }}>
                                <HeaderSezione icona={Bookmark} titolo="Dati del referente" />
                                <Grid container spacing={2} paddingRight={2} paddingLeft={2} paddingTop={2}>
                                    <Grid item xs={12} md={12} lg={6} xl={4}>
                                        <TextInput source="nome" label="Nome" type="text" validate={[required()]} fullWidth defaultValue={utente.nome ?? ''} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6} xl={4}>
                                        <TextInput source="cognome" label="Cognome" type="text" validate={[required()]} fullWidth defaultValue={utente.cognome ?? ''} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6} xl={4}>
                                        <TextInput source="telefono" label="Cellulare" type="text" validate={[required()]} fullWidth defaultValue={utente.telefono ?? ''} InputProps={{ startAdornment: <InputAdornment position="start">+39</InputAdornment> }} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6} xl={4}>
                                        <ListaFermateUtente fermate={dettagliViaggio.partenza} vistaAdmin={permissions === base64_encode(admB64) ? true : false} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6} xl={4}>
                                        <TextInput source="comune_nascita" label="Comune di nascita" type="text" validate={[required()]} defaultValue={utente.comune_nascita ?? ''} fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6} xl={4}>
                                        <DateInput source="data_nascita" label="Data di nascita" validate={[required()]} defaultValue={utente.data_nascita ?? ''} sx={{ width: '100%' }} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* AlertFermata e AlertWhatsapp */}
                    {permissions !== base64_encode(admB64) ?
                    <Grid item xs={12} variant='bloccoSingoloRichiesta'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} xl={6}>
                                <AlertFermata icona={BusAlert} />
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <AlertWhatsapp icona={Sms} />
                            </Grid>
                        </Grid>
                    </Grid>
                    :null}

                    {/* Partecipanti */}
                    <Grid item xs={12} variant='bloccoSingoloRichiesta'>
                        <Card>
                            <CardContent sx={{ p:0 }}>
                                <HeaderSezione icona={People} titolo="Partecipanti" />
                                <Grid container spacing={2} paddingRight={2} paddingLeft={2} paddingTop={2}>
                                    <Grid item xs={12} sm={6} md={12} lg>
                                        <AutocompleteInput
                                            source="n_adulti"
                                            label="N. adulti"
                                            choices={[
                                                { id: 1, name: '1' },
                                                { id: 2, name: '2' },
                                                { id: 3, name: '3' },
                                                { id: 4, name: '4' },
                                                { id: 5, name: '5' },
                                                { id: 6, name: '6' },
                                                { id: 7, name: '7' },
                                                { id: 8, name: '8' },
                                                { id: 9, name: '9' },
                                                { id: 10, name: '10' },
                                                { id: 11, name: '11' },
                                                { id: 12, name: '12' },
                                                { id: 13, name: '13' },
                                                { id: 14, name: '14' },
                                                { id: 15, name: '15' },
                                                { id: 16, name: '16' },
                                                { id: 17, name: '17' },
                                                { id: 18, name: '18' },
                                                { id: 19, name: '19' },
                                                { id: 20, name: '20' },
                                            ]}
                                            onChange={e => { setPartecipanti({ ...partecipanti, adulti: (e === '' ? 0 : e) }) }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12} lg>
                                        <AutocompleteInput
                                            source="n_bambini_1"
                                            label="N. bambini 0-2 anni"
                                            choices={[
                                                { id: 1, name: '1' },
                                                { id: 2, name: '2' },
                                                { id: 3, name: '3' },
                                                { id: 4, name: '4' },
                                                { id: 5, name: '5' },
                                                { id: 6, name: '6' },
                                                { id: 7, name: '7' },
                                                { id: 8, name: '8' },
                                                { id: 9, name: '9' },
                                                { id: 10, name: '10' },
                                            ]}
                                            onChange={e => { setPartecipanti({ ...partecipanti, bambini_1: (e === '' ? 0 : e) }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12} lg>
                                        <AutocompleteInput
                                            source="n_bambini_3"
                                            label="N. bambini 3-13 anni"
                                            choices={[
                                                { id: 1, name: '1' },
                                                { id: 2, name: '2' },
                                                { id: 3, name: '3' },
                                                { id: 4, name: '4' },
                                                { id: 5, name: '5' },
                                                { id: 6, name: '6' },
                                                { id: 7, name: '7' },
                                                { id: 8, name: '8' },
                                                { id: 9, name: '9' },
                                                { id: 10, name: '10' },
                                            ]}
                                            onChange={e => { setPartecipanti({ ...partecipanti, bambini_3: (e === '' ? 0 : e) }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12} lg>
                                        <AutocompleteInput
                                            source="n_bambini_4"
                                            label="N. ragazzi 14-17 anni"
                                            choices={[
                                                { id: 1, name: '1' },
                                                { id: 2, name: '2' },
                                                { id: 3, name: '3' },
                                                { id: 4, name: '4' },
                                                { id: 5, name: '5' },
                                                { id: 6, name: '6' },
                                                { id: 7, name: '7' },
                                                { id: 8, name: '8' },
                                                { id: 9, name: '9' },
                                                { id: 10, name: '10' },
                                            ]}
                                            onChange={e => { setPartecipanti({ ...partecipanti, bambini_4: (e === '' ? 0 : e) }) }}
                                        />
                                    </Grid>
                                    {dettagliViaggio.specifica_tipo === 0 ?
                                        <Grid item xs={12} sm={6} md={12} lg>
                                            <AutocompleteInput
                                                source="n_bambini_2"
                                                label="di cui sotto 1 mt di altezza"
                                                // choices={[
                                                //     { id: 1, name: '1' },
                                                //     { id: 2, name: '2' },
                                                //     { id: 3, name: '3' },
                                                //     { id: 4, name: '4' },
                                                //     { id: 5, name: '5' },
                                                //     { id: 6, name: '6' },
                                                //     { id: 7, name: '7' },
                                                //     { id: 8, name: '8' },
                                                //     { id: 9, name: '9' },
                                                //     { id: 10, name: '10' },
                                                // ]}
                                                disabled={!choicesBambini2 ? true : false}
                                                choices={choicesBambini2 ?? []}
                                                onChange={e => { setPartecipanti({ ...partecipanti, bambini_2: (e === '' ? 0 : e) }) }}
                                            />
                                        </Grid>
                                        : null}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Preventivo */}
                    {totPartecipanti > 0 ?
                    <Grid item xs={12} variant='bloccoSingoloRichiesta'>
                        <Card>
                            <CardContent sx={{ p:0 }}>
                                <HeaderSezione icona={EuroSymbol} titolo="Preventivo" />
                                <Grid mt={-2} paddingRight={2} paddingLeft={2} paddingTop={2}>
                                    <Preventivo data={datiPreventivo} tipoViaggio={dettagliViaggio.specifica_tipo} />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    : null}

                    {/* Camere */}
                    {dettagliViaggio.specifica_tipo === 2 ?
                    <Grid item xs={12} variant='bloccoSingoloRichiesta'>
                        <Card>
                            <CardContent sx={{ p:0 }}>
                                <HeaderSezione icona={Bed} titolo="Camere" dettaglio={totPostiLetto} />
                                <Grid container spacing={2} paddingRight={2} paddingLeft={2} paddingTop={2}>
                                    <Grid item xs={12} sm={6} md={12} lg={6} xl={3} xxl>
                                        <AutocompleteInput
                                            source="cam_singola"
                                            label="N. singole"
                                            choices={[
                                                { id: 1, name: '1' },
                                                { id: 2, name: '2' },
                                                { id: 3, name: '3' },
                                                { id: 4, name: '4' },
                                                { id: 5, name: '5' },
                                                { id: 6, name: '6' },
                                                { id: 7, name: '7' },
                                                { id: 8, name: '8' },
                                                { id: 9, name: '9' },
                                                { id: 10, name: '10' },
                                            ]}
                                            onChange={e => { setPostiLetto({ ...postiLetto, singole: (e === '' ? 0 : e * 1) }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12} lg={6} xl={3} xxl>
                                        <AutocompleteInput
                                            source="cam_doppia"
                                            label="N. doppie"
                                            choices={[
                                                { id: 1, name: '1' },
                                                { id: 2, name: '2' },
                                                { id: 3, name: '3' },
                                                { id: 4, name: '4' },
                                                { id: 5, name: '5' },
                                                { id: 6, name: '6' },
                                                { id: 7, name: '7' },
                                                { id: 8, name: '8' },
                                                { id: 9, name: '9' },
                                                { id: 10, name: '10' },
                                            ]}
                                            onChange={e => { setPostiLetto({ ...postiLetto, doppie: (e === '' ? 0 : e * 2) }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12} lg={6} xl={3} xxl>
                                        <AutocompleteInput
                                            source="cam_matrimoniale"
                                            label="N. matrimoniali"
                                            choices={[
                                                { id: 1, name: '1' },
                                                { id: 2, name: '2' },
                                                { id: 3, name: '3' },
                                                { id: 4, name: '4' },
                                                { id: 5, name: '5' },
                                                { id: 6, name: '6' },
                                                { id: 7, name: '7' },
                                                { id: 8, name: '8' },
                                                { id: 9, name: '9' },
                                                { id: 10, name: '10' },
                                            ]}
                                            onChange={e => { setPostiLetto({ ...postiLetto, matrimoniali: (e === '' ? 0 : e * 2) }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12} lg={6} xl={3} xxl>
                                        <AutocompleteInput
                                            source="cam_tripla"
                                            label="N. triple"
                                            choices={[
                                                { id: 1, name: '1' },
                                                { id: 2, name: '2' },
                                                { id: 3, name: '3' },
                                                { id: 4, name: '4' },
                                                { id: 5, name: '5' },
                                                { id: 6, name: '6' },
                                                { id: 7, name: '7' },
                                                { id: 8, name: '8' },
                                                { id: 9, name: '9' },
                                                { id: 10, name: '10' },
                                            ]}
                                            onChange={e => { setPostiLetto({ ...postiLetto, triple: (e === '' ? 0 : e * 3) }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12} lg={6} xl={3} xxl>
                                        <AutocompleteInput
                                            source="cam_quadrupla"
                                            label="N. quadruple"
                                            choices={[
                                                { id: 1, name: '1' },
                                                { id: 2, name: '2' },
                                                { id: 3, name: '3' },
                                                { id: 4, name: '4' },
                                                { id: 5, name: '5' },
                                                { id: 6, name: '6' },
                                                { id: 7, name: '7' },
                                                { id: 8, name: '8' },
                                                { id: 9, name: '9' },
                                                { id: 10, name: '10' },
                                            ]}
                                            onChange={e => { setPostiLetto({ ...postiLetto, quadruple: (e === '' ? 0 : e * 4) }) }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    : null}

                    {/* Note aggiuntive */}
                    <Grid item xs={12} variant='bloccoSingoloRichiesta'>
                        <Card>
                            <CardContent sx={{ p:0 }}>
                                <HeaderSezione icona={TextSnippet} titolo="Note aggiuntive" />
                                <Grid container spacing={2} paddingRight={2} paddingLeft={2} paddingTop={2}>
                                    <Grid item xs={12} mt={-3}>
                                        <Typography variant='small'>Aggiungi eventuali dettagli aggiuntivi come ad esempio intolleranze dei partecipanti, preferenze sui posti, fermate aggiuntive da richiedere</Typography>
                                        {/* <p sx={{ p: 0, m: 0 }}>Note aggiuntive (eventuali intolleranze dei partecipanti, preferenze sui posti, fermate aggiuntive da richiedere)</p> */}
                                        <TextInput multiline rows={5} source="note_pub" label="Note aggiuntive" fullWidth sx={{ p: 0, m: 0, mt:1 }} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Accettazioni */}
                    {accettazioni ?
                    <Grid item xs={12} variant='bloccoSingoloRichiesta'>
                        <Card>
                            <CardContent sx={{ p:0 }}>
                                <Grid container spacing={1} mt={2} pr={2} pl={2} pt={2}>
                                    {accettazioni.map((opt, i) => (
                                    <Grid item xs={12} key={i} mt={{ xs: 3, md: 2, xl: 0 }}>
                                        <CheckboxGroupInput
                                            fullWidth
                                            source={opt.sourceName}
                                            choices={[opt]}
                                            optionText={(v) => {
                                                return <Typography component='small' variant='small' sx={{ lineHeight: 1.2 }}>{v.testo} {(v.richiesto ? <sup>*</sup> : '')}<LinkMui onClick={() => openLightboxOnSlide((i + 1))} target='_blank' pt={0} mt={0} ml={1} color='secondary.dark' sx={{ fontWeight: 600 }}>{opt.testoLink}</LinkMui></Typography>
                                            }}
                                            // optionText={(v) => {
                                            //     return <Typography component='small' variant='small' sx={{ lineHeight: 1.2 }}>{v.testo} {(v.richiesto ? <sup>*</sup> : '')}<LinkMui href={opt.link} target='_blank' pt={0} mt={0} ml={1}>{opt.testoLink}</LinkMui></Typography>
                                            // }}
                                            validate={[required()]}
                                            sx={{ mb: 0 }}
                                            helperText={false}
                                            parse={(v) => {
                                                return (v[0] === 1 || v[0] === true ? 1 : 0)
                                            }}
                                            format={(v) => {
                                                return (v === true || v === 1 ? [1] : [])
                                            }}
                                        />
                                    </Grid>
                                    ))}
                                    <FsLightbox
                                        toggler={lightboxController.toggler}
                                        slide={lightboxController.slide}
                                        loadOnlyCurrentSource={true}
                                        disableSlideSwiping={true}
                                        sources={accettazioni.map((v,i) => <iframe title={v.testo} src={v.link} style={{ width: "80vw", height: "80vh" }} />
                                        )}
                                    />

                                    {/* Blocco Newsletter */}
                                    {/* <Grid item xs={12} mt={2}>
                                        <CheckboxGroupInput
                                            fullWidth
                                            source="optin"
                                            choices={[{ id: 1, testo: 'Voglio iscrivermi alla newsletter' }]}
                                            optionText='testo'
                                            sx={{ mb: 0 }}
                                            helperText={false}
                                            parse={(v) => {
                                                return (v[0] === 1 || v[0] === true ? 1 : 0)
                                            }}
                                            format={(v) => {
                                                return (v === true || v === 1 ? [1] : [])
                                            }}
                                        />
                                    </Grid> */}

                                    {Object.keys(erroriValidazione).length > 0 ?
                                    <Grid item xs={12} mt={2}>
                                        <Alert severity="warning" variant="outlined" sx={{ py: 0 }} icon={false}>
                                            I campi contrassegnato dal carattere * (asterisco) sono obbligatori per poter procedere.
                                        </Alert>
                                    </Grid>
                                    :null}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    :null}

                </Grid>

                <Snackbar
                    open={dettagliViaggio.specifica_tipo === 2 && totPartecipanti !== totPostiLetto}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    sx={{ pt: 4, zIndex: 99999 }}
                    // onClose={handleClose}
                    // message="Prima di procedere verifica che il numero dei partecipanti coincida con quello dei posti letto"
                    // action={action}
                >
                    <Alert severity="error">
                        <AlertTitle>Attenzione</AlertTitle>
                        Prima di procedere verifica che il numero dei partecipanti coincida con quello dei posti letto</Alert>
                </Snackbar>

            </SimpleForm >
        </Create >
        :
        <Alert severity="warning">
            <AlertTitle>Questo viaggio non è più disponibile</AlertTitle>
            <Typography component="p" variant="p">Questo viaggio non è più disponibile per l'invio di nuove richieste.</Typography>
            <Typography component="p" variant="p">Ti invitiamo a consultare la lista dei viaggi disponibili per scoprire nuove esperienze da fare.</Typography>
            <Link to="/viaggio">
                <Button sx={{mt: 2, display:'block'}}>
                    <Luggage sx={{ fontSize: '40px'}} />
                    <Typography>Vai ai <b>Viaggi</b></Typography>
                </Button>
            </Link>
        </Alert>
    )
};

const ViaggioPreviewMemo = memo(ViaggioPreview);
const LoginFormMemo = memo(LoginForm);

export const RichiestaNuova = () => {
    const { idViaggio } = useParams();
    const { permissions } = usePermissions();
    const refresh = useRefresh();

    useEffect(() => {
        // console.log(permissions);
        // console.log(idViaggio);
        // console.log(permissions, permissions !== base64_encode(admB64), sessionStorage.getItem("vid"));
        if (!permissions && permissions !== base64_encode(admB64)) {
            // console.log('vid da impostare', idViaggio);
            sessionStorage.setItem('vid', idViaggio);
            // refresh();
        } else if (permissions && sessionStorage.getItem("vid")) {
            // console.log('vid da rimuovere', idViaggio);
            sessionStorage.removeItem("vid");
            // refresh();
            window.location.reload(); 
        }
    }, [permissions]);

    return (
        <Card sx={{ mt: 1, overflow: 'unset' }}>
            <CardContent sx={{ py:{ xs: 2, md: 3, xl: 4 } }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={5} lg={6} xl={3}>
                        <Box elevation={0} sx={{ mb: 2, position:'sticky', top:'15px' }}>
                            <ViaggioPreviewMemo id={idViaggio} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={6} xl={9}>
                        <Card elevation={0} variant="">
                            {!permissions ?
                            <>
                                <StileAttesa />
                                <Box py={2}>
                                    <LoginFormMemo referrer='no_login' idViaggio={idViaggio} />
                                </Box>
                            </>
                            :
                            <RichiestaNuovaForm />
                            }
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

    )
}