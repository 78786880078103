import { useEffect, useState } from "react";
import { useGetList } from "react-admin";

export const useGetViaggioPdf = ({ idViaggio }) => {
    const [id, setId] = useState(null)
    const [data, setData] = useState(null)
    const [dataRIeP, setDataRIeP] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)

    const { data: dataR, isLoading: isLoadingR, error: errorR, total: totalR } = useGetList(
        'richiesta',
        {
            filter: { id_viaggio: idViaggio, id_stato_richiesta: 3 },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id_fermata', order: 'ASC' }
        },
        { enabled: id ? true : false }
    );
    const { data: dataRDP, isLoading: isLoadingRDP, error: errorRDP } = useGetList(
        'richiesta_dettagli_partecipanti',
        {
            filter: { id_richiesta_in: dataR?.map(richiesta => richiesta.id) },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'alloggio', order: 'ASC' }
        },
        { enabled: totalR > 0 ? true : false }
    );
    const { data: dataRDPF, isLoading: isLoadingRDPF, error: errorRDPF } = useGetList(
        'richiesta_dettagli_partecipanti_fermate',
        {
            filter: { id_viaggio: idViaggio },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id_fermata', order: 'ASC' }
        },
        { enabled: id ? true : false }
    );
    const { data: dataRIePbase, isLoading: isLoadingRIeP, error: errorRIeP, total: totalRIeP } = useGetList(
        'richiesta_invalidata_e_pagamento',
        {
            filter: { id_viaggio: idViaggio },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'ASC' }
        },
        { enabled: id ? true : false }
    );
    const { data: dataRP, isLoading: isLoadingRP, error: errorRP } = useGetList(
        'richiesta_pagamento',
        {
            filter: { id_richiesta_in: dataR?.map(v => v.id) },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'creazione', order: 'ASC' }
        },
        { enabled: totalR > 0 ? true : false }
    );

    useState(() => {
        // console.log("ERROR", errorR, errorRDP, errorRDPF, errorRIeP, errorRP);
        if (errorR || errorRDP || errorRDPF || errorRIeP || errorRP)
            setError({
                r: errorR,
                rdp: errorRDP,
                rdpf: errorRDPF,
                riep: errorRIeP,
                rp: errorRP
            })
        else
            setError(false)
    }, [errorR, errorRDP, errorRDPF, errorRIeP, errorRP])

    useState(() => {
        // console.log("LOADING", isLoadingR, isLoadingRDP, isLoadingRDPF, isLoadingRIeP, isLoadingRP);
        if (isLoadingR || isLoadingRDP || isLoadingRDPF || isLoadingRIeP || isLoadingRP)
            setIsLoading(true)
        else
            setIsLoading(false)
    }, [isLoadingR, isLoadingRDP, isLoadingRDPF, isLoadingRIeP, isLoadingRP])

    useState(() => {
        if (totalRIeP > 0) {
            const result = Object.groupBy(dataRIePbase, ({ id }) => id);
            const x = Object.keys(result).map((key, index) => {
                return {
                    ...result[key][0],
                    "pagamenti": result[key].map(v => {
                        return {
                            "metodo": v.metodo,
                            "importo": v.importo,
                            "pp_payment_id": v.pp_payment_id,
                            "data": v.creazione,
                        }
                    })
                }
            });
            setDataRIeP(x)
        }
    }, [dataRIeP, totalRIeP])

    useEffect(() => {
        // console.log("DATA", dataR, dataRDP, dataRDPF, dataRIeP, dataRP);
        if (
            dataR && dataRDP && dataRDPF && dataRIeP && dataRP
            && dataR !== undefined && dataRDP !== undefined && dataRDPF !== undefined && dataRIeP !== undefined && dataRP !== undefined
        ) {
            setIsLoading(false)
        }
    },[dataR, dataRDP, dataRDPF, dataRIeP, dataRP])

    useEffect(() => {
        if (!isLoading && !error && id) {
            // console.log("GENERALE", isLoading, error);
            setData({
                r: dataR,
                rdp: dataRDP,
                rdpf: dataRDPF,
                riep: dataRIeP,
                rp: dataRP
            })
            setId(null)
        }
    }, [isLoading, error])

    useEffect(() => {
        if (idViaggio) {
            setId(idViaggio)
            setIsLoading(true)
            // setData(null)
        } else {
            setId(null)
            setIsLoading(false)
        }
    }, [idViaggio])

    function getData() {
    }

    return [getData, { data, isLoading, error }]
}