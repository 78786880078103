import { useState, useEffect } from 'react';
import { Form, useNotify, CheckboxGroupInput, required, useAuthState, useRedirect, useUpdate, useLogin, usePermissions } from 'react-admin';
import { FormControl, Box, Paper, Grid, Select, InputLabel, MenuItem, Container, TextField, Link, Button, Typography, Divider, useMediaQuery, InputAdornment, IconButton, OutlinedInput, FormHelperText, Alert } from '@mui/material';
import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';
// import moment from 'moment';
import { apiBaseUrl, apiFullUrl, logger, LogoImg, LoginBg, titoloPagina, verificaCFpattern, verificaPWDpattern, verificaEmailPattern, hCaptcha_sitekey, AlertWhatsapp, Footer } from "./components/generale";
import { ArrowForwardIos, HowToReg, VisibilityOff, Visibility, WhatsApp, Sms } from '@mui/icons-material';
import ResetPassword from "./ResetPassword";
import { LoadingComponent } from "./components/LoadingComponent";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import TastoLoginGoogle from './LoginGoogle';
import LoginSocial from './LoginSocial';

const RegisterPage = () => {
    const [statoForm, setStatoForm] = useState(false);
    const [erroriForm, setErroriForm] = useState([]);
    const [captchaToken, setCaptchaToken] = useState(false);
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [codFisc, setCodFisc] = useState('');
    const [telefono, setTelefono] = useState('');
    const [citta, setCitta] = useState('');
    const [indirizzo, setIndirizzo] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [optin, setOptin] = useState(0);
    const [privacy_1, setPrivacy_1] = useState(0);
    const [mostraPassword, setMostraPassword] = useState(false);
    const [registerOrReset, setRegisterOrReset] = useState(true);
    const [emailEsistente, setEmailEsistente] = useState(false);
    const [loadingLogin, setLoadingLogin] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const login = useLogin();
    const [update, { isLoading: isLoadingUpdate, error: errorUpdate }] = useUpdate();
    const onlyMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const { permissions } = usePermissions();

    // const logoImageUrl = require(process.env.PUBLIC_URL+'images/logo.png');

    useEffect(() => {
        document.title = titoloPagina;
    }, []);

    useEffect(() => {
        // console.log(erroriForm)
        // console.log( captchaToken, nominativo, codFisc, telefono, citta, indirizzo, email, password );
        if (
            captchaToken === false
            || nome === ''
            || cognome === ''
            || codFisc === ''
            || telefono === ''
            || citta === ''
            || indirizzo === ''
            || email === ''
            || password === ''
            || privacy_1 === 0
        ) {
            setStatoForm(false);
        } else {
            setStatoForm(true);
        }
    }, [
        captchaToken,
        nome,
        cognome,
        codFisc,
        telefono,
        citta,
        indirizzo,
        email,
        password,
        privacy_1
    ]);

    const handleRegistrazione = e => {
        // console.log(nominativo);
        // console.log(codFisc);
        // console.log(telefono);
        // console.log(citta);
        // console.log(provincia);
        // console.log(email);
        // console.log(password);

        if (!statoForm) {
            notify('Verificare i dati inseriti e riprovare. Se il problema persiste contattaci.', { type: 'warning' });
            return false;
        }

        if (!captchaToken) return false;
        setEmailEsistente(false);
        var idUtente;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('username', email);
        bodyFormData.append('password', password);

        axios.post(apiFullUrl + '/register', bodyFormData)
            .then(async response => {
                // console.log(response);
                idUtente = parseInt(response.data.id);
                if (idUtente > 0) {
                    // logger(4, null, idUtente, null, null, idUtente);
                    const logData = {
                        id_logs_operazioni: 4,
                        id_utente: idUtente,
                    };
                    bodyFormData.append('nome', nome);
                    bodyFormData.append('cognome', cognome);
                    bodyFormData.append('cod_fisc', codFisc);
                    bodyFormData.append('telefono', telefono);
                    bodyFormData.append('comune', citta);
                    bodyFormData.append('indirizzo', indirizzo);
                    bodyFormData.append('temp', password);
                    bodyFormData.append('optin', optin);
                    bodyFormData.append('privacy_1', privacy_1);
                    bodyFormData.delete('username');
                    bodyFormData.delete('password');
                    // console.log(apiFullUrl + '/utenti/' + idUtente);
                    await axios.put(apiFullUrl + '/records/utente/' + idUtente, bodyFormData, { headers: { 'X-API-Key': response.data.api_key } })
                        .then(async response2 => {
                            await axios.post(apiFullUrl + '/records/logs/', logData, {
                                headers: { 'X-API-Key': response.data.api_key, 'Content-Type': 'application/json' }
                            });
                            notify('Registrazione completata', { type: 'success' });
                            login({ username: email, password: password })
                            // redirect('/#/login');
                        })
                }
            })
            .catch(function (error) {
                // notify(`${error.response.data.code} ${error.response.data.message}`);
                if (error.response.data.code === 1020) {
                    notify('Sembra che questo indirizzo email sia già stato utilizzato. Prosegui al recupero della password.', { type: 'warning' });
                    setEmailEsistente(true);
                } else {
                    notify('Errore nella procedura di registrazione. Verificare i dati inseriti e riprovare. Se il problema persiste contattaci.', { type: 'warning' });
                }
            });
    };

    return (
        !permissions ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', minHeight: "100vh", backgroundImage: `url(${LoginBg})`, backgroundSize: 'cover', backgroundPosition: 'center', py: 5 }}>
                <Paper sx={{ py: 3, my: 3, mx: 2, backgroundColor: '#ffffffe6' }} elevation={10}>
                    <Box
                        component="img"
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: 'auto', maxWidth: "300px", mb: 2 }}
                        src={LogoImg}
                    />
                    <Container maxWidth="sm">
                        {/* <h3 style={{'textAlign': 'center'}}>{registerOrReset === true ? "Registrati per accedere ai nostri servizi" : "Reimposta la password del tuo account"}</h3> */}

                        <Typography component='h3' variant='h3' textAlign='center' color='secondary'>
                            {registerOrReset === true ? "Registrati per accedere ai nostri servizi" : "Reimposta la password del tuo account"}
                        </Typography>

                        {/* Mostra il modulo di Registrazione */}
                        {registerOrReset === true ?
                            <>
                                <Form onSubmit={handleRegistrazione} sx={{ padding: 0, mt: 2 }}>
                                    <Grid container spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={6} xl={6}>
                                            <TextField
                                                name="nome"
                                                label="Nome"
                                                type="text"
                                                fullWidth
                                                required
                                                onChange={e => setNome(e.target.value)}
                                                error={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={6}>
                                            <TextField
                                                name="cognome"
                                                label="Cognome"
                                                type="text"
                                                fullWidth
                                                required
                                                onChange={e => setCognome(e.target.value)}
                                                error={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={6}>
                                            <TextField
                                                name="cod_fisc"
                                                label="Codice Fiscale"
                                                type="text"
                                                fullWidth
                                                required
                                                error={(erroriForm.includes('cod_fisc'))}
                                                helperText={(erroriForm.includes('cod_fisc') ? "Verifica la correttezza del dato inserito (scrivere tutto maiuscolo)" : null)}
                                                onChange={e => {
                                                    setErroriForm([]);
                                                    const cf = (e.target.value).toUpperCase();
                                                    if (verificaCFpattern.test(cf)) {
                                                        setCodFisc(cf);
                                                        setErroriForm((current) =>
                                                            current.filter((val) => val !== e.target.name)
                                                        );
                                                    } else {
                                                        setCodFisc('');
                                                        setErroriForm(current => [...current, e.target.name]);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={6}>
                                            <TextField
                                                name="telefono"
                                                label="Cellulare"
                                                type="text"
                                                fullWidth
                                                required
                                                onChange={e => setTelefono(e.target.value)}
                                                InputProps={{ startAdornment: <InputAdornment position="start">+39</InputAdornment> }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={6}>
                                            <TextField
                                                name="comune"
                                                label="Città di residenza"
                                                type="text"
                                                fullWidth
                                                required
                                                onChange={e => setCitta(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={6}>
                                            <TextField
                                                name="indirizzo"
                                                label="Indirizzo di residenza"
                                                type="text"
                                                fullWidth
                                                required
                                                onChange={e => setIndirizzo(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={6}>
                                            <TextField
                                                id="username"
                                                label="E-Mail"
                                                type="email"
                                                name="email"
                                                fullWidth
                                                required
                                                // onChange={e => setEmail(e.target.value)}
                                                helperText={(erroriForm.includes('email') ? "L'indirizzo email inserito non è formalmente corretto. Verifica e correggi gli errori per favore" : null)}
                                                error={(erroriForm.includes('email'))}
                                                onChange={e => {
                                                    if (verificaEmailPattern.test(e.target.value)) {
                                                        setErroriForm((current) =>
                                                            current.filter((val) => val !== e.target.name)
                                                        );
                                                        setEmail(e.target.value);
                                                    } else {
                                                        setEmail('');
                                                        setErroriForm(current => [...current, e.target.name]);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} xl={6}>
                                            <FormControl variant="outlined" sx={{ width: '100%' }} required>
                                                <InputLabel htmlFor="password">Password</InputLabel>
                                                <OutlinedInput
                                                    // <TextField
                                                    id="password"
                                                    name="password"
                                                    label="Password"
                                                    type={mostraPassword ? "text" : "password"}
                                                    // helperText={(erroriForm.includes('password') ? "La password deve contenere almeno 8 caratteri, di cui almeno una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&)" : "Inserisci una password con almeno una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&)")}
                                                    error={(erroriForm.includes('password'))}
                                                    fullWidth
                                                    required
                                                    onChange={e => {
                                                        if (verificaPWDpattern.test(e.target.value)) {
                                                            setErroriForm((current) =>
                                                                current.filter((val) => val !== e.target.name)
                                                            );
                                                            setPassword(e.target.value);
                                                        } else {
                                                            setPassword('');
                                                            setErroriForm(current => [...current, e.target.name]);
                                                        }
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setMostraPassword(!mostraPassword)}
                                                                // onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {mostraPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                {erroriForm.includes('password') ?
                                                    <FormHelperText error>La password deve contenere almeno 8 caratteri, di cui almeno una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&).</FormHelperText>
                                                : null }
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} pt={0} mt={0}>
                                            {password.length === 0 ? 
                                                <Alert severity="warning">Per la tua password usa almeno 8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&).</Alert>
                                            :null}
                                            {/* {(erroriForm.includes('password') ?
                                                <Alert sx={{ pt: 0 }} severity="error">La password deve contenere almeno 8 caratteri, di cui almeno una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&).</Alert>
                                                :
                                                <Alert severity="info">Per la tua password usa almeno 8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&).</Alert>
                                            )} */}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AlertWhatsapp icona={Sms} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <CheckboxGroupInput
                                                fullWidth
                                                source="optin"
                                                choices={[{ id: 1, testo: 'Voglio restare sempre aggiornato sui prossimi viaggi' }]}
                                                optionText={(v) => {
                                                    return <Typography component='small' variant='small' sx={{ lineHeight: 1.2 }}>{v.testo}</Typography>
                                                }}
                                                sx={{ mb: 0 }}
                                                helperText={false}
                                                parse={(v) => {
                                                    const parsed = (v[0] === 1 || v[0] === true ? 1 : 0);
                                                    setOptin(parsed);
                                                    return parsed
                                                }}
                                                format={(v) => {
                                                    return (v === true || v === 1 ? [1] : [])
                                                }}
                                            />
                                            <CheckboxGroupInput
                                                fullWidth
                                                sx={{ mb: 0, pb: 0, mt: 1 }}
                                                source="privacy_1"
                                                label="Accettazione Privacy"
                                                validate={required([])}
                                                error={(erroriForm.includes('privacy_1'))}
                                                choices={[{ id: 1, name: 'Confermo di aver preso visione dell\'informativa sulla tutela dei dati personali' }]}
                                                optionText={<Typography component='small' variant='small' sx={{ lineHeight: 1.2 }}>Confermo di aver preso visione e di accettare l'<Link color='secondary.dark' fontWeight='bold' href={`${process.env.PUBLIC_URL}/docs/informativa-privacy.pdf`} target='_blank'>informativa sul trattamento dei dati personali</Link> e i <Link color='secondary.dark' fontWeight='bold' href={`${process.env.PUBLIC_URL}/docs/termini-e-condizioni.pdf`} target='_blank'>termini di utilizzo del servizio</Link> <sup>*</sup></Typography>}
                                                parse={(v) => {
                                                    const parsed = (v[0] === 1 || v[0] === true ? 1 : 0);
                                                    setPrivacy_1(parsed);
                                                    return parsed
                                                }}
                                                format={(v) => {
                                                    return (v === true || v === 1 ? [1] : [])
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} display='flex' justifyContent='center'>
                                            <HCaptcha
                                                sitekey={hCaptcha_sitekey}
                                                onVerify={(token, ekey) => setCaptchaToken(token)}
                                                onError={() => setCaptchaToken(false)}
                                                onExpire={() => setCaptchaToken(false)}
                                                onChalExpired={() => setCaptchaToken(false)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} display='flex' justifyContent='center'>
                                            <Button
                                                type='submit'
                                                variant='contained'
                                                color='primary'
                                                startIcon={<HowToReg />}
                                                label='Registrati'
                                                size='large'
                                                fullWidth={onlyMobile ? true : false}
                                                // disabled={statoForm === false ? true : false}
                                            >Registrati</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                                <Divider sx={{ my: 2 }}><Typography variant='p'>oppure</Typography></Divider>
                                <Grid container>
                                    <Grid item sx={{ width: '100%' }}>
                                        {/* <TastoLoginGoogle tipo='registrazione' /> */}
                                        <LoginSocial statoLogin={setLoadingLogin} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mt: 3 }}>
                                    <Grid item xs={12} md={12} textAlign='center'>
                                        <Link color='secondary.dark' fontWeight='bold' underline="hover" size="small" onClick={() => { setRegisterOrReset(!registerOrReset) }}sx={{ cursor: 'pointer' }}><small>Hai dimenticato la password?</small></Link>
                                        <br />
                                        <Link color='secondary.dark' fontWeight='bold' underline="hover" size="small" onClick={() => { window.location.href = '/#/login' }}sx={{ cursor: 'pointer' }}><small>Sei già iscritto? Accedi ora</small></Link>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <ResetPassword pagina='registrazione' aggiornamento={setRegisterOrReset} />
                        }
                        <Footer sx={{  backgroundColor: 'transparent' }} />
                    </Container>
                </Paper>
            </Box>
            : null
    );
};

export default RegisterPage;