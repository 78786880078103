import { useEffect, useState } from "react";
import { useUpdate, useRedirect, useNotify, useGetOne, useCreate, useEditContext, useDeleteMany, useGetList, Edit, DateInput, SimpleForm, FormTab, TextInput, BooleanInput, required, SelectInput, ReferenceInput, AutocompleteInput, ImageInput, ImageField, Toolbar, SaveButton } from 'react-admin';
import { useFormContext } from "react-hook-form";
import { Grid, InputAdornment, Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Luggage, ArrowDownward, Image, CloudUpload, ListAlt } from '@mui/icons-material';
import { logger, HeaderSezione, apiBaseUrl, apiFullUrl } from '../generale';
import slugify from "slugify";
import axios from 'axios';
import { CategorieViaggio } from "./CategorieViaggio";
import { LoadingComponent } from "../LoadingComponent";
import { Fermate } from "./FermateSelect";
import { RichTextEditor } from "../RichTextEditor";

const CustomForm = () => {
    const { record, isLoading } = useEditContext();
    const [tipoViaggio, setTipoViaggio] = useState(null);
    const { setValue, getValues, watch } = useFormContext()
    const formValues = watch()

    const changeTipoViaggio = (id) => {
        setTipoViaggio(id)
        if (id !== 0)
            setValue("prezzo_bambini_2", '')
        if (id !== 2)
            setValue("suppl_singola", '')
    }

    // useEffect(() => {
    //     console.log("formValues", formValues);
    //     if (!formValues.id_categorie_da_eliminare)
    //         setValue("id_categorie_da_eliminare", [])
    // }, [formValues])

    useEffect(() => {
        const slugViaggio = slugify(formValues.titolo ?? '', { replacement: '-', lower: true })
        setValue("alias", slugViaggio)
    }, [formValues.titolo])

    if (isLoading) return <LoadingComponent />
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={5}>
                <HeaderSezione icona={Luggage} titolo="Modifica il viaggio" />
                <Grid container display='flex' flexDirection='column' spacing={2}>
                    <Grid item>
                        <Fermate />
                    </Grid>
                    <Grid item>
                        <SelectInput
                            source="specifica_tipo"
                            label="Specifica tipologia"
                            fullWidth
                            choices={[
                                { id: 0, name: 'Parchi divertimento' },
                                { id: 1, name: 'Giornata singola' },
                                { id: 2, name: 'Almeno 2 giornate' }
                            ]}
                            onChange={(e) => changeTipoViaggio(e.target.value)}
                            validate={[required()]}
                        />
                    </Grid>
                    <Grid item>
                        <TextInput source="titolo" label="Titolo del viaggio" type="text" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item>
                        <TextInput source="alias" label="Alias" type="text" validate={[required()]} fullWidth readOnly />
                    </Grid>
                    <Grid item sx={{ pt: "0 !important", pb: 3 }}>
                        <CategorieViaggio
                            update={setValue}
                            id_viaggio={record.id}
                        />
                    </Grid>
                    <Grid item display='flex' flexDirection='row' gap={2}>
                        <DateInput source="data_inizio" label="Data inizio" validate={[required()]} fullWidth />
                        <DateInput source="data_fine" label="Data fine" fullWidth />
                    </Grid>
                    <Grid item display='flex' flexDirection='row' gap={2}>
                        <TextInput source="descr_breve" label="Descrizione breve" type="text" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item display='flex' flexDirection='row' gap={2}>
                        <TextInput source="prezzo" label="Prezzo" type="text" validate={[required()]} fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} />
                        <TextInput source="prezzo_prec" label="Prezzo precedente" type="text" fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} defaultValue={0} />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            {tipoViaggio === 0 ?
                                <Grid item xs={12} md={6}>
                                    <TextInput source="prezzo_bambini_2" label="Prezzo bambini sotto 1 mt" type="text" fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} defaultValue={0} />
                                </Grid>
                                : null}
                            {tipoViaggio === 2 ?
                                <Grid item xs={12} md={6}>
                                    <TextInput source="suppl_singola" label="Suppl. camera singola" type="text" fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} defaultValue={0} />
                                </Grid>
                                : null}
                            <Grid item xs={12} md={6}>
                                <TextInput source="acconto" label="Acconto" type="text" fullWidth InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} defaultValue={0} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextInput source="prezzo_note" label="Note prezzo" type="text" fullWidth />
                    </Grid>
                    <Grid item display='flex' flexDirection='row' gap={2}>
                        <TextInput source="partecipanti_min" label="Numero minimo partecipanti" type="text" fullWidth />
                        <ReferenceInput label="Agenzia" source="id_agenzia" reference="agenzia" filter={{ id_gt: 1 }} sort={{ field: 'nome', order: 'ASC' }}>
                            <SelectInput label="Agenzia" optionText='nome' optionValue="id" validate={[required()]} fullWidth />
                        </ReferenceInput>
                    </Grid>

                    <Grid item>
                        <ReferenceInput label="Stato" source="id_stato_viaggio" reference="stato_viaggio" sort={{ field: 'id', order: 'ASC' }}>
                            <SelectInput label="Stato" optionText='nome' optionValue="id" validate={[required()]} fullWidth defaultValue={1} />
                        </ReferenceInput>
                    </Grid>

                    <Grid item>
                        <BooleanInput source="stato" defaultValue={true} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
                <Box>
                    <HeaderSezione icona={Image} titolo="Immagine di copertina" dettaglio="Formato 1200 x 630 pixels" />
                    <ImageUploader />
                </Box>

                {/* Programma */}
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownward />} aria-controls="programma-content" id="programma-header">
                        <Typography>Programma</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RichTextEditor campo="programma" valore={formValues.programma} update={setValue} />
                    </AccordionDetails>
                </Accordion>
                
                {/* Partenza */}
                {/* <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownward />} aria-controls="partenza-content" id="partenza-header">
                        <Typography>Partenza</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RichTextEditor campo="partenza" valore={formValues.partenza} update={setValue} />
                    </AccordionDetails>
                </Accordion> */}
                
                {/* La quota comprende */}
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownward />} aria-controls="quota_incl-content" id="quota_incl-header">
                        <Typography>La quota comprende</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RichTextEditor campo="quota_incl" valore={formValues.quota_incl} update={setValue} />
                    </AccordionDetails>
                </Accordion>
                
                {/* La quota NON comprende */}
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownward />} aria-controls="quota_escl-content" id="quota_escl-header">
                        <Typography>La quota NON comprende</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RichTextEditor campo="quota_escl" valore={formValues.quota_escl} update={setValue} />
                    </AccordionDetails>
                </Accordion>
                
                {/* Documenti richiesti */}
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDownward />} aria-controls="documenti-content" id="documenti-header">
                        <Typography>Documenti richiesti</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RichTextEditor campo="documenti" valore={formValues.documenti} update={setValue} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )
}

const ImageUploader = () => {
    const [imgSrc, setImgSrc] = useState(null)
    const { watch } = useFormContext()
    const img = watch("img")
    const titolo = watch("titolo")

    useEffect(() => {
        setImgSrc(typeof img === 'string' ? `${apiBaseUrl}${process.env.REACT_APP_VIAGGI_IMG_ENDPOINT}${img}` : null)
    }, [img])

    // useEffect(() => {
    //     if (imgSrc)
    //         console.log(imgSrc)
    // }, [imgSrc])

    return (
        <Grid container>
            <Grid item xs={12} md>
                <ImageInput
                    source="img"
                    label="Carica un'immagine"
                    accept="image/*"
                    placeholder={
                        <IconButton variant="contained" size='large' disabled={false} color='primary' sx={{ borderWidth: 1, borderStyle: 'solid', mb: 3 }}><CloudUpload sx={{ fontSize: 50 }} /></IconButton>
                    }
                    maxSize={4000000}
                >
                    <ImageField source="src" title="Copertina viaggio" sx={{ mb: { xs: 3, lg: 2 }, '& img': { width: '50% !important', height: 'auto !important' } }} />
                </ImageInput>
            </Grid>
            {imgSrc ?
            <Grid item xs={12} md>
                <Box sx={{
                    mb: 3,
                    backgroundColor: '#f1f1f1',
                    // width: 1200,
                    // height: 630,
                    aspectRatio: "1.9/1",
                    backgroundImage: `url(${imgSrc})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }} />
            </Grid>
            :null}
        </Grid>
    )
}

export const ViaggioModifica = () => {
    const idUtente = localStorage.getItem('auth_uid');
    const redirect = useRedirect();
    const notify = useNotify();
    const [create, { isLoading, error }] = useCreate();
    var imgToSend = null;
    const [deleteMany, { isLoading: isLoadingDelete, error: errorDelete }] = useDeleteMany();

    const onSuccess = async (data, { id }) => {
        logger(2, null, null, id, null, idUtente);
        if (imgToSend) {
            await axios.post(`${apiFullUrl}/upload_img_viaggio/${id}`, {
                img: imgToSend
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-API-Key': localStorage.getItem('auth_api_key')
                }
            })
                .catch(function (error) {
                    notify(`Non è stato possibile caricare l'immagine`, { type: 'error' });
                })
        }
        if(data.id_da_eliminare) {
            await deleteMany('viaggio_categoria_assegnazione', { ids: data.id_da_eliminare })
            await data.categorie.map(async(v) => {
                await create('viaggio_categoria_assegnazione', {
                    data: {
                        id_viaggio: id,
                        id_categoria: v
                    }
                })
                return null
            })
        }
        redirect('list', 'viaggio');
        notify(`Viaggio modificato`, { type: 'success' });
    };

    const transform = data => {
        // console.log(data.img, typeof data.img);
        // console.log(data);
        imgToSend = typeof data.img === 'object' && data?.img ? data.img.rawFile : null
        const prezziDaControllare = [
            "prezzo_prec",
            "prezzo",
            "acconto",
            "prezzo_bambini_2",
            "suppl_singola",
        ]
        const prezziOk = prezziDaControllare.map(v => {
            if (typeof data[v] === 'string') {
                if (data[v].trim() === '')
                    return { campo: v, val: 0 }
                else if (data[v].includes(','))
                    return { campo: v, val: parseFloat(data[v].replace(",", ".")) }
            } else if (!data[v]) {
                return { campo: v, val: 0 }
            }
            return { campo: v, val: parseFloat(data[v]) }
        })
        return {
            ...data,
            img: !imgToSend ? data.img : null,
            img_intro: null,
            prezzo_prec: prezziOk.find(v => v.campo === "prezzo_prec").val,
            prezzo: prezziOk.find(v => v.campo === "prezzo").val,
            acconto: prezziOk.find(v => v.campo === "acconto").val,
            prezzo_bambini_2: prezziOk.find(v => v.campo === "prezzo_bambini_2").val,
            suppl_singola: prezziOk.find(v => v.campo === "suppl_singola").val,
            programma: `${data.programma ?? ''}`,
            documenti: `${data.documenti ?? ''}`,
            quota_incl: `${data.quota_incl ?? ''}`,
            quota_escl: `${data.quota_escl ?? ''}`,
            partenza: `${data.partenza ?? ''}`,
            stato: data.stato ? 1 : 0
        }
    };

    const onError = (error) => {
        notify(`${error.message}`, { type: 'error' });
    }

    return (
        <Edit
            resource="viaggio"
            title="Modifica viaggio"
            mutationOptions={{ onSuccess, onError }}
            mutationMode="pessimistic"
            transform={transform}
            sx={{ pb: 8 }}
            actions={null}
        >
            <SimpleForm
                toolbar={<Toolbar><SaveButton type="button" alwaysEnable /></Toolbar>}
            >
                <CustomForm />
            </SimpleForm>
        </Edit>
    )
};