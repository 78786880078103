import { useEffect, useState } from "react";
import { useGetList } from "react-admin";
import { useGetFermate } from "./useGetFermate";

export const useGetPresetFermate = () => {
    const [presetFermate, setPresetFermate] = useState([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [getData, { listaFermate, isLoadingListaFermate, errorListaFermate }] = useGetFermate()
    const { data: listaPresetFermate, isLoading: isLoadingListaPresetFermate, error: errorListaPresetFermate } = useGetList(
        'preset_fermata',
        {
            pagination: { page: 1, perPage: 99 },
            sort: { field: 'nome', order: 'ASC' }
        }
    );

    useEffect(() => {
        setLoading(true)
    }, [])

    useEffect(() => {
        if (listaPresetFermate && listaFermate && presetFermate.length === 0) {
            const localitaMap = Object.fromEntries(listaFermate.map(l => [l.id, l]));
            const result = listaPresetFermate.map(p => ({
                id: p.id,
                nome: p.nome,
                configurazione: JSON.parse(p.configurazione).map(c => ({
                    ...c,
                    localita: localitaMap[c.id]?.localita || "Sconosciuta"
                }))
                .sort((a, b) => a.ordine - b.ordine)
            }));
            setPresetFermate(result);
        }
    }, [listaPresetFermate, listaFermate, presetFermate])

    useEffect(() => {
        if (presetFermate)
            setLoading(false)
    }, [presetFermate])

    return { presetFermate, loading, error }
}