import React from "react";

function IconaConfermato({ ...props }) {
  return (
    <svg
      viewBox="0 0 2253 1257"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <rect
        id="_3-confermato"
        x={1.199}
        y={0}
        width={2250}
        height={1256.25}
        style={{
          fill: "none",
        }}
      />
      <path
        d="M2084.55,909.693l-187.336,0c-16.641,-82.047 -88.786,-140.99 -172.484,-140.99c-83.725,-0 -155.843,58.943 -172.538,140.99l-851.993,0c-16.668,-82.047 -88.786,-140.99 -172.511,-140.99c-83.725,-0 -155.843,58.943 -172.484,140.99l-170.75,0c-24.287,-0.027 -44.008,-19.721 -44.036,-44.035l0,-521.714c0.083,-76.436 61.969,-138.295 138.378,-138.405l1560.66,0c40.983,0.11 80.48,14.99 111.313,41.945l-228.236,28.495l-1370.85,0c-19.446,0 -35.234,15.733 -35.234,35.179l-0,211.266c-0,19.446 15.788,35.178 35.234,35.178l1371.53,0l388.783,32.429l-0,292.212c-0.028,15.155 -12.323,27.45 -27.45,27.45Z"
        style={{
          fill: "#00bc98",
        }}
      />
      <g id="Confermato">
        <rect
          x={353.099}
          y={275.979}
          width={991.817}
          height={281.596}
          style={{
            fill: "#00bc98",
          }}
        />
      </g>
      <path
        d="M2166.57,515.355l-108.837,-239.898c-39.194,-85.266 -124.377,-140.028 -218.279,-140.303l-1560.66,-0c-115.246,0.138 -208.68,93.489 -208.79,208.79l-0,521.714c0.082,63.178 51.296,114.365 114.448,114.42l170.75,0c16.641,82.102 88.759,141.018 172.484,141.018c83.725,0 155.843,-58.916 172.511,-141.018l851.993,0c16.695,82.102 88.813,141.018 172.538,141.018c83.698,0 155.843,-58.916 172.484,-141.018l187.336,0c53.992,-0.027 97.78,-43.788 97.835,-97.835l-0,-294c0.027,-25.14 -5.391,-50.004 -15.816,-72.888m-76.298,2.338l-330.362,-27.67l-0,-147.867l237.395,-29.678l92.967,205.215Zm-1562.58,532.798c-58.338,-0 -105.619,-47.281 -105.619,-105.619c-0,-58.311 47.281,-105.619 105.619,-105.619c58.338,-0 105.619,47.308 105.619,105.619c-0,58.338 -47.281,105.619 -105.619,105.619m1197.04,-0c-58.365,-0 -105.619,-47.281 -105.619,-105.619c0,-58.311 47.254,-105.619 105.619,-105.619c58.338,-0 105.592,47.308 105.592,105.619c-0,58.338 -47.254,105.619 -105.592,105.619m359.82,-140.798l-187.336,0c-16.641,-82.047 -88.786,-140.99 -172.484,-140.99c-83.725,-0 -155.843,58.943 -172.538,140.99l-851.993,0c-16.668,-82.047 -88.786,-140.99 -172.511,-140.99c-83.725,-0 -155.843,58.943 -172.484,140.99l-170.75,0c-24.287,-0.027 -44.008,-19.721 -44.036,-44.035l0,-521.714c0.083,-76.436 61.969,-138.295 138.378,-138.405l1560.66,0c40.983,0.11 80.48,14.99 111.313,41.945l-228.236,28.495l-1370.85,0c-19.446,0 -35.234,15.733 -35.234,35.179l-0,211.266c-0,19.446 15.788,35.178 35.234,35.178l1371.53,0l388.783,32.429l-0,292.212c-0.028,15.155 -12.323,27.45 -27.45,27.45m-1697.69,-563.329l253.046,0l-0,140.826l-253.046,-0l0,-140.826Zm323.459,0l275.049,0l-0,140.826l-275.049,-0l-0,-140.826Zm345.462,0l266.247,0l0,140.826l-266.247,-0l-0,-140.826Zm336.66,0l297.081,0l-0,140.826l-297.081,-0l-0,-140.826Z"
        style={{
          fillRule: "nonzero",
        }}
      />
    </svg>
  );
}

export default IconaConfermato;