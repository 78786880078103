import { useState, useEffect } from "react";
import { SelectInput, required } from "react-admin";
import { Box, IconButton } from "@mui/material";
import { Place, Image, Warning } from '@mui/icons-material/';
import { isJson } from "../generale";
import { useGetFermate } from "../hooks/useGetFermate";
import FsLightbox from "fslightbox-react";

export const ListaFermateUtente = ({ fermate: configFermate, opzioneSelezionata, vistaAdmin = false, ...props }) => {
    
    const configFermateObj = isJson(configFermate) ? JSON.parse(configFermate) : null
    const [fermate, setFermate] = useState([])
    const [fermataSelezionata, setFermataSelezionata] = useState(null)
    const [toggler, setToggler] = useState(false);
    const [getData, { listaFermate, isLoadingListaFermate, errorListaFermate }] = useGetFermate()

    useEffect(() => {
        if (!isLoadingListaFermate && listaFermate.length > 0 && configFermateObj && fermate.length === 0) {
            const fermateConLocalita = configFermateObj.map(fermata => {
                const tmp = listaFermate.find(lf => lf.id === fermata.id)
                return {
                    ...fermata,
                    // localita: tmp.localita,
                    localita: <div style={{ display: 'flex', alignItems: 'center' }}>{tmp.localita}{fermata.facoltativa == true ? <Warning color='warning' sx={{ ml: 1.3 }} fontSize='10' /> : null}</div>,
                    gps: tmp.gps,
                    img: tmp.img
                }
            })
            setFermate(fermateConLocalita)
        }
    }, [listaFermate, configFermateObj])

    useEffect(() => {
        // console.log(fermate);
        if (opzioneSelezionata && fermate.length > 0)
            setFermataSelezionata(fermate.find(v => v.id === opzioneSelezionata));
    }, [fermate, opzioneSelezionata])

    if (fermate.length === 0) return null
    // if (opzioneSelezionata && opzioneSelezionata !== undefined) return null

    return (
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
            <SelectInput
                label="Fermata principale"
                fullWidth
                source="id_fermata"
                choices={fermate}
                optionText="localita"
                optionValue="id"
                validate={[required()]}
                onChange={e => setFermataSelezionata(fermate.find(v => v.id === e.target.value))}
                {...props}
            />
            {fermataSelezionata && !vistaAdmin ?
                <Box mt='-16px' mb='20px' display='flex' flexDirection='row' alignItems='center'>
                    <IconButton size="small" color="secondary" onClick={() => window.open(`https://www.google.com/maps?q=${fermataSelezionata.gps}`, '_blank')}>
                        <Place />
                    </IconButton>
                    {fermataSelezionata.img ?
                    <IconButton size="small" color="secondary" onClick={() => setToggler(!toggler)}>
                        <Image />
                    </IconButton>
                    :null}
                    <FsLightbox
                        // onClose={() => setToggler(false)}
                        loadOnlyCurrentSource={true}
                        type="image"
                        toggler={toggler}
                        disableSlideSwiping={true}
                        sources={[`/img/fermate/${fermataSelezionata.img}`]}
                    />
                </Box>
                : null}
        </Box>
    )
}